import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import CustomEditor from '../base/CustomEditor';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import CustomDateTimeSelect from '../base/CustomDateTimeSelect';
import { hasError, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import { CustomTitleLabel } from '../../components/earning/CustomBaseComponments';
import { TRANSACTION_RECORD_TYPE } from '../../containers/record/records/TransactionList';
import StaffNameSelectDropdown from './StaffNameSelectDropdown';
import { CreateTransactionError } from '../../models/CreateTransactionModel';
import { LanguageConfig } from '../../config/CustomEnums';
import { PermissionCodes } from '../../config/PermissionCodes';
import OfflineEventTypeSelectDropdown from './OfflineEventTypeSelectDropdown';
import StoreSelectDropdown from './StoreSelectDropdown';
import CustomerFilterableDropdown from './CustomerFilterableDropdown';

function TransactionGeneralSection({ originalEventType = '' }) {
  const dispatch = useDispatch();
  const location = useLocation();

  const { watch, getValues, setValue, formState, clearErrors, setError } = useFormContext();
  const { errors } = formState;
  const errorFields = Object.keys(errors);

  const customerField = 'customer';
  const transactionDateField = 'transactionDate';
  const storeField = 'store';
  const posInvoiceIdField = 'posInvoiceId';
  const invoiceIdField = 'invoiceId';
  const remarksField = 'remarks';
  const offlineEventTypeField = 'offlineEventType';
  const displayOfflineEventTypeField = 'displayOfflineEventType';
  const staffNameField = 'staffName';

  const transactionType = watch('transactionDisplayType');
  const selectedCustomer = watch(customerField);
  const selectedStore = watch(storeField);
  const transactionDate = watch(transactionDateField);
  const offlineEventType = watch(offlineEventTypeField);
  const offlineDisplayEventType = watch(displayOfflineEventTypeField);
  const staffName = watch(staffNameField);
  const posInvoiceId = watch(posInvoiceIdField);
  const invoiceId = watch(invoiceIdField);
  const remarks = watch(remarksField);

  const [date, setDate] = useState(
    transactionDate ? new Date(transactionDate) : new Date(),
  );

  useEffect(() => {
    if (transactionDate) {
      setDate(new Date(transactionDate));
    }
  }, [transactionDate]);

  return (
    <>
      <div className="first-section-title">GENERAL</div>
      <CustomerFilterableDropdown
        title={'Customer'}
        errorName={customerField}
        errorMessage={CreateTransactionError.customer.message}
        errorFields={errorFields}
        defaultSelectedCustomer={{
          label: selectedCustomer?.owner,
          value: selectedCustomer,
        }}
        needLevelFilter={true}
        selectCustomer={(customer) => {
          setValue(customerField, customer.value, { shouldDirty: true });
        }}
      />
      <div>
        <CustomTitleLabel title="Transaction date" />
        <CustomDateTimeSelect
          defaultTime={new Date(transactionDate)}
          error={hasError(errors, transactionDateField)}
          onTimeChange={(date) => {
            setValue(transactionDateField, date, { shouldDirty: true });
          }}
        />
        <ReactHookFormErrorMessage errors={errors} id={transactionDateField} />
      </div>
      {/* <StoreSelectDropdown
        selectedStore={selectedStore}
        selectStore={(store) => {
          setValue(storeField, store, { shouldDirty: true });
        }}
        hideAddButton={false}
        addButtonRequires={PermissionCodes.addStore}
      />
      <div>
        <CustomTitleLabel
          title={
            transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS
              ? 'Pos invoice id'
              : 'Invoice id'
          }
        />
        <div>
          {transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS
            ? (
              <input
                type="text"
                className={`text-input-field ${
                  hasError(errors, posInvoiceIdField) ? 'error-field' : ''
                }`}
                onChange={(e) => {
                  const value = e.target.value;
                  setValue(posInvoiceIdField, value, { shouldDirty: true });
                }}
                value={posInvoiceId}
              />
            )
            : null}
          {transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM
            ? (
              <input
                type="text"
                className={`text-input-field ${
                  hasError(errors, invoiceIdField) ? 'error-field' : ''
                }`}
                onChange={(e) => {
                  const value = e.target.value;
                  setValue(invoiceIdField, value, { shouldDirty: true });
                }}
                value={invoiceId}
              />
            )
            : null}
        </div>
        <div>
          <ReactHookFormErrorMessage errors={errors} id={posInvoiceIdField} />
          <ReactHookFormErrorMessage errors={errors} id={invoiceIdField} />
        </div>
      </div> */}
      {transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM
        && location.pathname.includes('create')
        ? null
        : (
        <div>
          <OfflineEventTypeSelectDropdown
            transactionType={transactionType}
            defaultEventType={offlineEventType}
            defaultDisplayEventType={offlineDisplayEventType}
            originalEventType={originalEventType}
            selectEventType={(item) => {
              setValue(offlineEventTypeField, item.value, { shouldDirty: true });
              setValue(displayOfflineEventTypeField, item.label, { shouldDirty: true });
            }}
          />
          <ReactHookFormErrorMessage errors={errors} id={offlineEventTypeField} />
        </div>
      )}
      {/* <div>
        <StaffNameSelectDropdown
          selectedStaff={staffName}
          selectStaff={(data) => {
            dispatch(
              createAction('createTransaction/updateTransactionState')({
                staffName: data,
              }),
            );
          }}
        />
        <CustomTitleLabel title="Staff name" />
        <div>
          <input
            type="text"
            className={`text-input-field ${
              hasError(errors, staffNameField)
                ? 'error-field'
                : ''
            }`}
            onChange={(e) => {
              const value = e.target.value;
              setValue(staffNameField, value, { shouldDirty: true });
            }}
            value={staffName || ''}
          />
        </div>
        <ReactHookFormErrorMessage errors={errors} id={staffNameField} />
      </div> */}
      <div>
        <CustomTitleLabel title="Remarks (optional)" />
        <CustomEditor
          initialValue={remarks}
          onValueChange={(data) => {
            setValue(remarksField, data, { shouldDirty: true });
          }}
        />
      </div>
    </>
  );
}
const mapPropsToState = (state) => ({
  originalEventType: state.createTransaction.transaction.offlineEventType,
});

export default connect(mapPropsToState)(TransactionGeneralSection);
