import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { CreatePointRecordError } from '../../models/CreatePointRecordModel';
import CustomerFilterableDropdown from '../transactions/CustomerFilterableDropdown';


function CreatePointRecordGeneral({
  customers,
  transactionType,
}) {
  // const isEdit = props.isEdit
  const dispatch = useDispatch();

  const { watch, getValues, setValue, formState, clearErrors, setError } = useFormContext();
  const { errors } = formState;
  const errorFields = Object.keys(errors);

  const selectedCustomerField = 'customer';
  const selectedCustomer = watch(selectedCustomerField);

  const ownerOptions = customers.map((customer) => {
    return {
      value: customer,
      label: customer.owner,
    };
  });

  return (
    <>
      <div className="first-section-title">GENERAL</div>

      <CustomerFilterableDropdown
        title={'Customer'}
        // customers={customers}
        defaultSelectedCustomer={{
          label: selectedCustomer?.owner,
          value: selectedCustomer,
        }}
        selectCustomer={(item) => {
          setValue(selectedCustomerField, item.value, { shouldDirty: true });
        }}
        errorFields={errorFields}
        errorName={selectedCustomerField}
        errorMessage={CreatePointRecordError.selectCustomer.message}
      />

      {/* {isEdit ? (
        <div
          style={{
            display: 'inline-grid',
          }}
        >
          <CustomTitleLabel title={'Transaction Type'} />
          <label className="custom-record-normal-font custom-timeline-time-color">
            {`${transactionType || '-'}`}
          </label>
        </div>
      ) : null} */}
    </>
  );
}

const mapPropsToState = (state) => ({
  customers: state.customerList.customerList,
  transactionType: state.createPointRecord.pointRecord.transactionType,
});

export default connect(mapPropsToState)(CreatePointRecordGeneral);
