import React from 'react';
import { Table, Image } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  LanguageConfig,
} from '../../config/CustomEnums';
import { listItem, listImage, titleForItem } from '../../components/banners/DisplayItems';
import { Viewer } from '@toast-ui/react-editor';
import CustomRichTextViewer from '../base/CustomRichTextViewer';


function MessageOverviewTableSection(props) {
  const message = props.message || {};
  const language = props.language || LanguageConfig.english;

  const name = message.translations?.[language]?.name;
  const content = message.translations?.[language]?.content;
  const photo = message.translations?.[language]?.photo?.value;

  return (
    <>
      <label className="create-section-title">{message.displayChannels?.toUpperCase()}</label>
      {listItem('Title (optional)', name)}
      {titleForItem('Content')}
      <CustomRichTextViewer initialValue={content || '-'} />
      {photo == null || photo === ''
        ? listItem('Image (optional)', '-')
        : listImage('Image (optional)', photo)
      }
    </>
  );
}

export default MessageOverviewTableSection;
