import { Viewer } from '@toast-ui/react-editor';
import React from 'react';
import './CustomRichTextViewer.scss';

function CustomRichTextViewer({ initialValue = {} }) {
  return (
    <div id="custom-richtext-viewer" key={initialValue}>
      {!initialValue ? <>-</> : <Viewer initialValue={initialValue} />}
    </div>
  );
}

export default CustomRichTextViewer;
