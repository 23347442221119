import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../utils';
import NextStepPrompt from '../../components/base/prompt/NextStepPrompt';
import { PermissionCodes } from '../../config/PermissionCodes';

function CreateMessageSuccessPrompt(props) {
  const queryString = require('query-string');
  const history = useHistory();
  const params = useParams();
  const messagePK = params.id;
  const dispatch = useDispatch();
  const isTest = props.isTest || false;
  const isDraft = props.isDraft || false;
  const defaultShow = props.show || false;
  const onClose = props.onClose || (() => {});
  const [show, setShow] = useState(false);

  const { message } = useSelector((state) => ({
    message: state.createMessage.message,
  }));

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  if (!show && defaultShow) {
    setShow(true);
  }

  const getDescription = (isDraft, isTest) => {
    const firstSection = `Message is successfully ${
      isDraft ? 'saved.' : 'sent.'
    }`;
    const partOfSecondSection = isDraft
      ? 'Come back and finish it later.'
      : 'Your customer will receive soon!';
    const secondSection = isTest
      ? 'Your testing device will receive soon!'
      : partOfSecondSection;
    return firstSection + "\n" + secondSection;
  };

  return (
    <NextStepPrompt
      show={show}
      title={
        isTest
          ? 'Successfully sent!'
          : `Successfully ${isDraft ? 'saved' : 'scheduled'}!`
      }
      description={getDescription(isDraft, isTest)}
      steps={null}
      buttons={[
        {
          text: isTest ? 'Go to message preview' : 'Back to Message list',
          action: () => {
            if (isTest) {
              handleClose();
              dispatch(
                createAction('createMessage/updateState')({
                  currentStep: 3,
                }),
              );
            } else {
              history.push({
                pathname: '/messages',
                search: queryString.stringify({
                  page: 1,
                  rank: true,
                  search: '',
                }),
              });
            }
          },
          requires: PermissionCodes.changeMessage,
        },
        {
          text: isTest ? 'Edit message' : 'View details',
          action: () => {
            if (isTest) {
              setShow(false);
              dispatch(
                createAction('createMessage/updateState')({
                  currentStep: 1,
                }),
              );
            } else {
              history.push(`/messages/${messagePK ? messagePK : message.pk}/`);
            }
          },
          requires: PermissionCodes.changeMessage,
        },
      ]}
    />
  );
}

export default CreateMessageSuccessPrompt;
