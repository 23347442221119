import React, { useState, useEffect } from 'react';
import './CreateCampaignStepTwo.scss';
import { useSelector, useDispatch, connect } from 'react-redux';
import { CampaignType, LanguageConfig } from '../../../config/CustomEnums';
import ContentSections from '../../base/ContentSections';
import GeneralSection from './GeneralSection';
import DetailSection from './DetailSection';
import InstructionSection from './InstructionSection';
import KeyVisualSection from './KeyVisualSection';
import { ContinueWithBackButtons } from '../../base/BottomStepComponent';
import {
  defaultPreviewImage,
  getPreviewLayoutImages,
  getTipsImages,
} from './CampaignStepTwoPreviewConfig';

import { ReactComponent as TestImage } from '../../../assets/images/campaign/general_without_detail_title.svg';
import BasePrompt from '../../base/prompt/BasePrompt';
import { useFormContext } from 'react-hook-form';
import { validate } from '../../../containers/engagement/campaign/CreateCampaignValidate';

let dataArray = [];
function CreateCampaignStepTwo({ languages }) {
  // const { campaignType, errorFields, campaign } = useSelector((state) => ({
  //   campaignType: state.createCampaign.campaign.campaignType,
  //   errorFields: state.createCampaign.errorFields,
  //   campaign: state.createCampaign.campaign,
  // }));
  const { getValues, watch, formState, clearErrors, setError, setValue } =
    useFormContext();
  const errors = formState.errors;
  const fieldError = Object.keys(errors || []).length;
  const [onCheck, setOnCheck] = useState({
    value: false,
    isBack: false,
  });
  const watchCampaignType = watch('campaignType');
  const selectedLanguages = watch('selectedLanguages');
  const dispatch = useDispatch();
  const defaultImage = defaultPreviewImage(watchCampaignType);
  // const isCouponRelated = isShowCouponRelated(watchCampaignType);

  const [activeSection, setActiveSection] = useState();
  const inputWithImageConfig = getPreviewLayoutImages(watchCampaignType);
  const tipsImageConfig = getTipsImages(watchCampaignType);
  // const [error, setError] = useState(false);
  // const isChecking = ({ isBack }) => {
  //   setOnCheck({
  //     value: true,
  //     isBack: isBack,
  //   });
  // };

  const stopCheck = () => {
    setOnCheck({
      value: false,
      isBack: false,
    });
  };

  // useEffect(() => {
  //   if (!onCheck.value) {
  //     dataArray = [];
  //     setError(errorFields.length > 0);
  //   } else {
  //     let formatedData = {
  //       [LanguageConfig.english]: {},
  //       [LanguageConfig.simplifiedChinese]: {},
  //       [LanguageConfig.traditionalChinese]: {},
  //     };
  //     dataArray.forEach((item, index) => {
  //       const language = item.language;
  //       const data = item.data;
  //       formatedData = {
  //         ...formatedData,
  //         [language]: {
  //           ...formatedData[language],
  //           ...data,
  //         },
  //       };
  //     });
  //     dispatch({
  //       type: 'createCampaign/stepChange',
  //       payload: {
  //         data: formatedData,
  //         isBack: onCheck.isBack,
  //         step: 1,
  //       },
  //     });
  //     setError(false);
  //     stopCheck();
  //   }
  // }, [dispatch, errorFields, onCheck.isBack, onCheck.value]);

  const submitAction = (language, data) => {
    dataArray.push({ language, data });
  };

  const getTabs = (language, setActiveSection) => {
    return [
      <GeneralSection
        onFocus={setActiveSection}
        language={language}
        // onSubmit={onCheck.value}
        // onSubmitAction={(data) => {
        //   submitAction(language, data);
        // }}
      />,
      <KeyVisualSection
        onFocus={setActiveSection}
        language={language}
        // onSubmit={onCheck.value}
        // onSubmitAction={(data) => {
        //   submitAction(language, data);
        // }}
      />,
      watchCampaignType === CampaignType.generalMessageCampaign ? null : (
        <InstructionSection
          focusChange={setActiveSection}
          language={language}
          // onSubmit={onCheck.value}
          // onSubmitAction={(data) => {
          //   submitAction(language, data);
          // }}
          titleTips={tipsImageConfig.instructionTitle}
          contentTips={tipsImageConfig.instructionContent}
          // defaultValue={campaign.translations[language]}
          // valueChangedAction={'createCampaign/setFieldToSession'}
        />
      ),
      <DetailSection
        focusChange={setActiveSection}
        language={language}
        onSubmit={onCheck.value}
        // onSubmitAction={(data) => {
        //   submitAction(language, data);
        // }}
        titleTips={tipsImageConfig.detailsTitle}
        contentTips={tipsImageConfig.detailsContent}
        // defaultValue={campaign.translations[language]}
        // valueChangedAction={'createCampaign/setFieldToSession'}
      />,
    ];
  };

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 1,
      isBack,
    });
    dispatch({
      type: 'createCampaign/stepChange',
      payload: { isBack: isBack, step: 1, isValid },
    });
  };

  return (
    <>
      <ContentSections
        fieldsError={fieldError}
        languageTabContent={{
          containers: languages?.map((language) => {
            return {
              container: getTabs(language.code, setActiveSection),
              key: language.code,
              title: language.sourceName,
            };
          }),
        }}
        inputWithImageConfig={inputWithImageConfig}
        defaultImage={defaultImage}
        activeSection={activeSection}
      />
      <ContinueWithBackButtons
        continueAction={() => {
          stepChangeAction(false);
        }}
        backAction={() => {
          stepChangeAction(true);
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  // campaignType: state.createCampaign.campaign?.campaignType,
  // errorFields: state.createCampaign.errorFields,
  // campaign: state.createCampaign.campaign,
  languages: state.language.allList,
});

export default connect(mapPropsToState)(CreateCampaignStepTwo);
