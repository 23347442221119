import React, { Component } from 'react';
import './SingleCouponOverview.scss';
import moment from 'moment';
import { connect } from 'react-redux';
import { createAction } from '../../../utils';
import BaseOverView from './BaseOverview';
import CustomerFilterableDropdown from '../../transactions/CustomerFilterableDropdown';
import { COUPON_TEMPLATE_TYPE } from '../../../config/CustomEnums';
import CustomEditor from '../../base/CustomEditor';

@connect(({ customerList, singleCoupon }) => ({
  customerList: customerList.customerList,
  singleCoupon: singleCoupon.singleCoupon,
}))
class SingleCouponOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultOwner: this.props.defaultOwner.owner,
    };
  }

  componentDidMount() {
    this.props.dispatch(createAction('customerList/getPagedCustomers')({}));
  }

  parseDateTime = (datetimeStr) => {
    return moment(datetimeStr).format('D MMM YYYY (ddd), LT');
  };
  parseCouponExpiryDate = () => {
    const effectiveDate = this.props.singleCoupon?.effectiveDate;
    const expiryDate = this.props.singleCoupon?.expiryDate;
    if (effectiveDate && expiryDate) {
      return `${this.parseDateTime(effectiveDate)} - ${this.parseDateTime(
        expiryDate,
      )}`;
    }
    if (this.props.couponSet.validPeriodType === 'ALL_TIME') {
      return 'All time valid';
    }
    if (this.props.couponSet.validPeriodType === 'ABSOLUTE') {
      return `${effectiveDate ? this.parseDateTime(effectiveDate) : ''} - ${
        expiryDate ? this.parseDateTime(expiryDate) : ''
        }`;
    }
    if (this.props.couponSet.validPeriodType === 'RELATIVE') {
      return `${this.props.couponSet.numberOfDaysToExpireAfterAcquisition} days since acquiring`;
    }
    return '-';
  };
  render() {
    console.log('@52',this.props?.errors?.remarks);
    const ownerOptions = this.props.customerList.map((customer) => {
      return {
        value: customer,
        label: customer.owner,
      };
    });
    const firstSectionTitle = 'GENERAL';
    const generalTitleList = [
      'ID',
      this.props.singleCoupon.template?.formats ===
        COUPON_TEMPLATE_TYPE.couponCodeImage
        ? 'Image'
        : 'Serial Number',
      'Brand',
      'Store',
      'Coupon barcode',
      'Import code',
      'Coupon Owner',
      'Transaction type',
      'Coupon Set',
      // 'Date of Purchase',
      // 'Date of Earning',
      // 'Date of Grant',
      'Expiry Date',
      'Create at',
      'Last Modified',
      'Status',
      this.props.singleCoupon?.owner?.firstName
        ? null
        : 'Remarks',
    ];
    const generalValueList = [
      this.props.singleCoupon?.pk,
      this.props.singleCoupon.template?.formats ===
        COUPON_TEMPLATE_TYPE.couponCodeImage
        ? this.props.singleCoupon?.displayImage
        : this.props.singleCoupon?.serialNumber,
      this.props.singleCoupon?.template?.brand?.name || '-',
      this.props.singleCoupon?.store?.name || '-',
      this.props.singleCoupon?.template?.barcodeFormat?.name || '-',
      this.props.singleCoupon?.code || '-',
      this.props.singleCoupon.owner?.firstName ? (
        this.props.singleCoupon.owner.owner
      ) : (
        <CustomerFilterableDropdown
          defaultSelectedCustomer={this.props.defaultOwner}
          selectCustomer={this.props.selectedOwner}
        />
      ),
      this.props.singleCoupon?.transactionType || '-',
      this.props.couponSet.name,
      // this.props.singleCoupon?.dateOfPurchase
      //   ? this.parseDateTime(this.props.singleCoupon?.dateOfPurchase)
      //   : '-',
      // this.props.singleCoupon?.dateOfEarning
      //   ? this.parseDateTime(this.props.singleCoupon?.dateOfEarning)
      //   : '-',
      // this.props.singleCoupon?.dateOfGrant
      //   ? this.parseDateTime(this.props.singleCoupon?.dateOfGrant)
      //   : '-',
      this.parseCouponExpiryDate(),
      this.parseDateTime(this.props.singleCoupon?.creationDate),
      this.parseDateTime(this.props.singleCoupon?.lastModifiedDate),
      this.props.singleCoupon?.status,
      this.props.singleCoupon.owner?.firstName ? (
        null
      ) : (
        <CustomEditor
          initialValue={this.props.remarks}
          onValueChange={(data) => {
            this.props.setRemarks(data)
          }}
          error={this.props?.errors?.remarks}
          errorMessage={this.props?.errors?.remarks}
          customStyle={{width: '-webkit-fill-available'}}
        />
      ),
    ];

    const secondSectionTitle = 'COUPON USAGE RECORD';
    const usageTitleList = ['Create Date', 'Staff Code'];
    const usageValueList = [
      this.parseDateTime(this.props.singleCoupon?.creationDate),
      this.props.singleCoupon?.store?.staffCode || '-',
    ];
    return (
      <div>
        <BaseOverView
          className={''}
          title={firstSectionTitle}
          itemTitleList={generalTitleList}
          itemValueList={generalValueList}
        />

        {/* <BaseOverView
          className={''}
          title={secondSectionTitle}
          itemTitleList={usageTitleList}
          itemValueList={usageValueList}
        /> */}
      </div>
    );
  }
}

export default SingleCouponOverview;
