import React from 'react';
import { Image, Table } from 'react-bootstrap';
import { useSelector, connect } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
// import { getImageUrl } from '../../../models/CreateCampaignModel';
import { getImageUrl } from '../../../utils'
import i18n from '../../../I18n';
import './ContentSection.scss';
import {
  LanguageConfig,
  LanguageTabTransLation,
} from '../../../config/CustomEnums';
import { CampaignType } from '../../../config/CustomEnums';
import ContentSections from '../../base/ContentSections';
const fieldsLanguages = [
  LanguageConfig.english,
  LanguageConfig.traditionalChinese,
  LanguageConfig.simplifiedChinese,
];

const detailPhotos = [
  'detailPhotoOne',
  'detailPhotoTwo',
  'detailPhotoThree',
  'detailPhotoFour',
];

function ContentSection({
  languages,
  data
}) {
  const { getValues } = useFormContext() || {};
  const campaign = data || getValues() || {};
  const translations = campaign.translations || {};
  // const { translations } = useSelector((state) => ({
  //   translations: state.createCampaign.campaign.translations,
  // }));
  // const showdown = require('showdown');
  // const converter = new showdown.Converter();

  // const fieldValueText = (value, index) => {
  //   return (
  //     <td
  //       key={`fieldValueText-${value}-${index}`}
  //       className="create-section-field-input"
  //       dangerouslySetInnerHTML={{
  //         __html: value ? converter.makeHtml(value) : '-',
  //       }}
  //     ></td>
  //   );
  // };

  // const fieldNameText = (value) => {
  //   return (
  //     <td className="create-section-label content-section-field-clumn">
  //       {value}
  //     </td>
  //   );
  // };

  // const fieldColumnText = (value) => {
  //   return (
  //     <th className="create-section-label content-section-value-clumn">
  //       {value}
  //     </th>
  //   );
  // };

  // const sectionValues = (valueKey) => {
  //   return (
  //     <>
  //       {fieldsLanguages.map((language, index) =>
  //         fieldValueText(translations[language][valueKey], index),
  //       )}
  //     </>
  //   );
  // };

  // const coverImage = () => {
  //   return (
  //     <>
  //       {fieldsLanguages.map((language, index) => (
  //         <td key={`coverImage-${language}-${index}`}>
  //           {translations[language].coverPhoto ? (
  //             <Image
  //               className="content-section-rectangle-image"
  //               src={translations[language].coverPhoto}
  //             />
  //           ) : (
  //             '-'
  //           )}
  //         </td>
  //       ))}
  //     </>
  //   );
  // };

  // const squareCoverImage = () => {
  //   return (
  //     <>
  //       {fieldsLanguages.map((language, index) => (
  //         <td key={`squareCoverImage-${language}-${index}`}>
  //           {translations[language].squareCoverPhoto ? (
  //             <Image
  //               className="content-section-square-image"
  //               src={translations[language].squareCoverPhoto}
  //             />
  //           ) : (
  //             '-'
  //           )}
  //         </td>
  //       ))}
  //     </>
  //   );
  // };

  const getDetailPhotosWithOneLanguage = (language) => {
    const mapResult = detailPhotos.map((detailPhoto) =>
      translations[language][detailPhoto] ? (
        <Image
          key={`detail-photo-${language}-${translations[language][detailPhoto]}`}
          className="content-section-rectangle-image content-section-detail-photo"
          src={translations[language][detailPhoto]}
        />
      ) : null,
    );
    const deleteNullItem = mapResult.filter((result) => result);
    return deleteNullItem.length === 0 ? '-' : deleteNullItem;
  };

  const images = () => {
    return (
      <>
        {fieldsLanguages.map((language, index) => (
          <td key={`fieldsLanguages-${language}-${index}`}>
            <div className="content-section-detail-photo-container">
              {getDetailPhotosWithOneLanguage(language)}
            </div>
          </td>
        ))}
      </>
    );
  };
  const getValueFromTranslations = (language, valueKeys) => {
    const data = {};
    const datas = valueKeys?.forEach(valueKey => {
      switch (valueKey) {
        case 'coverPhoto':
        case 'squareCoverPhoto': {
          const url = getImageUrl(translations?.[language]?.[valueKey])
          if (!url || url === "") {
            data[valueKey] = null;
          }
          data[valueKey] = url;
          break;
        }
        case 'detailPhotos': {
          data[valueKey] = translations?.[language]?.[valueKey]?.map((item) => {
            const url = getImageUrl(item)
            if (!url || url === "") {
              return null;
            }
            return url;
          });
          break;
        }
        default: {
          data[valueKey] = translations?.[language]?.[valueKey];
        }
      }
    });
    return data;
  }

  const renderTabForLanguage = (language) => {
    const data =getValueFromTranslations(
      language,
      [
        'generalName',
        'shortDescription',
        'coverPhoto',
        'squareCoverPhoto',
        'detailPhotos',
        'instructionSectionTitle',
        'instructionSectionContent',
        'detailSectionTitle',
        'detailSectionContent',
      ]
    )
    let fields = [
      [{
        title: i18n.t('name', { locale: language }),
        field: 'generalName',
      },],
      [{
        title: i18n.t('short_description', { locale: language }),
        field: 'shortDescription',
        isRichText: true,
      },],
      [{
        title: i18n.t('cover_photo', { locale: language }),
        field: 'coverPhoto',
      },],
      [{
        title: i18n.t('campaign.square_cover_photo', { locale: language }),
        field: 'squareCoverPhoto',
      },],
      [{
        title: i18n.t('other_photos', { locale: language }),
        field: 'detailPhotos',
      },],
    ]
    if (campaign.campaignType != CampaignType.generalMessageCampaign) {
      fields.push(
        [{
          title: i18n.t('instruction_section_title', { locale: language }),
          field: 'instructionSectionTitle',
        },],
        [{
          title: i18n.t('instruction_section_content', { locale: language }),
          field: 'instructionSectionContent',
        },],
      )
    }
    fields.push(
      [{
        title: i18n.t('detail_section_title', { locale: language }),
        field: 'detailSectionTitle',
      },],
      [{
        title: i18n.t('detail_section_content', { locale: language }),
        field: 'detailSectionContent',
      },],
    )
    return (
      <MembershipInfoCard
        title={i18n.t('content', { locale: 'en' })}
        data={data}
        fields={fields}
      />
    )
  }

  // return (
  //   <>
  //     <label className="create-section-title">CONTENT</label>
  //     <PerfectScrollbar>
  //       <Table bordered className="content-section-table-container">
  //         <thead>
  //           <tr>
  //             {fieldNameText()}
  //             {fieldColumnText(LanguageTabTransLation.english)}
  //             {fieldColumnText(LanguageTabTransLation.traditionalChinese)}
  //             {fieldColumnText(LanguageTabTransLation.simplifiedChinese)}
  //           </tr>
  //         </thead>
  //         <tbody>
  //           <tr>
  //             {fieldNameText('Title')}
  //             {sectionValues('generalName')}
  //           </tr>
  //           <tr>
  //             {fieldNameText('Short description')}
  //             {sectionValues('shortDescription')}
  //           </tr>
  //           <tr>
  //             {fieldNameText('Cover Image')}
  //             {coverImage()}
  //           </tr>

  //           <tr>
  //             {fieldNameText('Square Cover Image')}
  //             {squareCoverImage()}
  //           </tr>

  //           <tr>
  //             {fieldNameText('Image')}
  //             {images()}
  //           </tr>

  //           <tr>
  //             {fieldNameText('Instruction section title')}
  //             {sectionValues('instructionSectionTitle')}
  //           </tr>

  //           <tr>
  //             {fieldNameText('Instruction section content')}
  //             {sectionValues('instructionSectionContent')}
  //           </tr>

  //           <tr>
  //             {fieldNameText('Detail section title')}
  //             {sectionValues('detailSectionTitle')}
  //           </tr>

  //           <tr>
  //             {fieldNameText('Detail section content')}
  //             {sectionValues('detailSectionContent')}
  //           </tr>
  //         </tbody>
  //       </Table>
  //     </PerfectScrollbar>
  //   </>
  // );
  return (
    <ContentSections
      hidePreview
      languageTabContent={{
        containers: languages?.map((item) => ({
          container: [renderTabForLanguage(item.code)],
          key: item.code,
          title: item.sourceName || item.name,
        }))
      }}
      activeSection={LanguageConfig.english}
      contentStyle="campaign-detail-language-content-sections"
    />
  );
}

const mapPropsToState = (state) => ({
  languages: state.language.allList,
})

export default connect(mapPropsToState)(ContentSection);
