import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { CustomTitleWithDropDown } from '../../customer/CustomTitleWithDropDown';

function EarningRulesSelectDropdown({
  title,
  defaultValue,
  isPrompt = false,
  addButtonInfo = {},
  setValue = (() => {}),
  earningRuleList,
  errors,
  errorName,
}) {
  const dispatch = useDispatch();

  //TODO: remove save linked earning rule
  const onSelectChange = (value) => {
    setValue(value?.value);
  };

  useEffect(() => {
    dispatch({
      type: 'earningRuleList/getEarningRuleListWithTypes',
      payload: {
        reverse: true,
      }
    });
  }, [dispatch]);

  return (
    <CustomTitleWithDropDown
      title={title}
      titleStyle={isPrompt ? '' : 'create-section-label-no-top-space'}
      defaultValue={defaultValue}
      setValue={onSelectChange}
      source={earningRuleList}
      loadMoreAction={'earningRuleList/getEarningRuleListWithTypes'}
      filterAction={'earningRuleList/getEarningRuleListWithTypes'}
      errors={errors}
      errorName={errorName}
      addButtonInfo={addButtonInfo}
    />
  );
}

const mapPropsToState = (state) => ({
  // linkedEarningRules: state.createCampaign.campaign.linkedEarningRules,
  earningRuleList: state.earningRuleList.earningRuleList,
});

export default connect(mapPropsToState)(EarningRulesSelectDropdown);
