import {
  createEarningRule,
  updateEarningRule,
  getOneEarningRule,
  createOrUpdateEarningRule,
} from '../services/EarningRuleHelper';
import {
  EarningRuleType,
  EarningRuleTypeKey,
  LanguageConfig,
  CheckStatus,
  EarningRuleRewardType,
  SESSION_KEYS,
  APIStatus,
} from '../config/CustomEnums';
import { defaultStep, getNewStepConfig } from './StepBarUtil';
import { apiWithResponseHandle, loading } from './LoadingUtil';
import { convertCursorToNumber, convertNumberToCursor } from '../utils';
import {
  getFileNameFromUrl,
  saveToSessionStorage,
  removeFromSessionStorage,
  getObjectFromSessionStorage,
  createAction,
  delay,
  addDomainToImage,
  convertPKToId,
  getKeyByValue,
} from '../utils';
import { EarningRuleErrorHandleField } from '../components/earning/EarningRuleHandleError';
import { SaveAndBackWithOutTempButtons } from '../components/base/BottomStepComponent';

export const EARNING_RULES_SESSION_KEY =
  SESSION_KEYS.EARNING_RULES_SESSION_KEY;

const stepNames = ['Type', 'Content', 'Properties'];

const getInitialState = () => ({
  currentStep: 0,
  stepConfig: defaultStep(stepNames),
  earningRule: {},
  errorFields: [],
  formChanged: false,
  checked: CheckStatus.initOrNotChecked,
  hasUpdatedDefaultValues: false,
  createStatus: APIStatus.none,
});

const postTranslations = (data) => {
  if (!data) {
    return [];
  }
  const languageList = Object.keys(data).filter(
    (language) => language !== LanguageConfig.english,
  );
  const translations = languageList.map((language) => {
    const newData = {
      language,
      instructionSectionTitle: data?.[language]?.instructionSectionTitle,
      instructionSectionContent: data?.[language]?.instructionSectionContent,
      detailSectionTitle: data?.[language]?.detailSectionTitle,
      detailSectionContent: data?.[language]?.detailSectionContent,
    };
    const id = data?.[language]?.pk;
    if (id) {
      newData.id = id;
    }
    return newData;
  });
  return translations;
};

const getTranslations = (data) => {
  const translationList = data.translations?.edges || [];
  const translation = {};
  translationList.forEach((item) => {
    const language = item.node.language;
    translation[language] = {
      ...item.node,
      // name: item.node.name,
    };
  });
  translation[LanguageConfig.english] = {
    instructionSectionTitle: data.instructionSectionTitle,
    instructionSectionContent: data.instructionSectionContent,
    detailSectionTitle: data.detailSectionTitle,
    detailSectionContent: data.detailSectionContent,
    pk: data.pk,
  };
  return translation;
};

const praseEarningRule = (defaultData) => {
  if (!defaultData) {
    return {}
  }
  let formatData = defaultData;
  let commonData = {};

  if (Object.keys(defaultData).length > 0) {
    const type = EarningRuleTypeKey[defaultData.type];
    formatData = {
      id: defaultData.id,
      pk: defaultData.pk,
      type: defaultData.type,
      generalName: defaultData.name,
      minSpending: defaultData.generalPurchaseTypeMinimumSpending,
      maxSpending: defaultData.generalPurchaseTypeMaximumSpending,
      eligibleDays:
        defaultData.generalPurchaseTypeEligibleNumberOfDaysSincePurchase,
      selectedStores: defaultData.generalPurchaseTypeLimitedToStores?.edges.map(
        (item, index) => item.node,
      ),
      isExcludeDeliveryCost:
        defaultData.generalPurchaseTypeWillExcludeDeliveryCost,
      isExcludeOtherCharges:
        defaultData.generalPurchaseTypeWillExcludeOtherCharges,
      specialSkus: defaultData.generalPurchaseTypeLimitedToSkus?.edges.map(
        (item) => ({
          pk: item.node.skuId,
          name: item.node.sku,
          category: {
            pk: item.node.categoryId,
            name: item.node.categoryName,
          },
        }),
      ),

      referralType: defaultData.memberReferralTypeBeneficiary,
      maxInvitees: defaultData.memberReferralTypeLimit,

      latitude: defaultData.gpsCheckInTypeLatitude,
      longitude: defaultData.gpsCheckInTypeLongitude,
      radius: defaultData.gpsCheckInTypeRadiusInMeter,

      birthStart: defaultData.birthdayTypePeriodStartDate,
      birthEnd: defaultData.birthdayTypePeriodEndDate,

      qrCodes: defaultData.qrCodeScanningTypeQrCodes,

      fillingForm: defaultData.fillingFormTypeForm,
      definitionType: defaultData.fillingFormTypeDefinitionType,

      rewardType: defaultData[`${type}TypeRewardType`],
      quantity:
        defaultData[`${type}TypeRewardType`] ===
        EarningRuleRewardType.points
          ? defaultData[`${type}TypePointsRewardTypePoints`]
          : defaultData[`${type}TypeCouponRewardTypeQuantity`], // template pouints
      coupons: defaultData[`${type}TypeCouponRewardTypeCouponTemplate`],
      translations: getTranslations(defaultData),
    };

    switch (defaultData.type) {
      case EarningRuleType.generalPurchase:
        commonData = {
          quantity:
            defaultData.generalPurchaseTypeRewardType ===
            EarningRuleRewardType.points
              ? defaultData.generalPurchaseTypePointsRewardTypePointsPerXDollarsSpent
              : defaultData.generalPurchaseTypeCouponRewardTypeQuantity,
          rewardTypeX: defaultData.generalPurchaseTypePointsRewardTypeX,
          overallLimit: defaultData[`${type}TypeOverallLimit`],
          perHeadLimit: defaultData[`${type}TypePerHeadLimit`],
          periodicLimit: defaultData[`${type}TypePeriodicLimit`],
          periodicLimitDays:
            defaultData[`${type}TypePeriodicLimitEffectiveNumberOfDays`],
          perHeadPeriodicLimit:
            defaultData[`${type}TypePerHeadPeriodicLimit`],
          perHeadPeriodicLimitDays:
            defaultData[
              `${type}TypePerHeadPeriodicLimitEffectiveNumberOfDays`
            ],
        };
        break;
      case EarningRuleType.gpsCheckIn:
      case EarningRuleType.qrCodeScanning:
      case EarningRuleType.fillingForm:
        commonData = {
          overallLimit: defaultData[`${type}TypeOverallLimit`],
          perHeadLimit: defaultData[`${type}TypePerHeadLimit`],
          periodicLimit: defaultData[`${type}TypePeriodicLimit`],
          periodicLimitDays:
            defaultData[`${type}TypePeriodicLimitEffectiveNumberOfDays`],
          perHeadPeriodicLimit:
            defaultData[`${type}TypePerHeadPeriodicLimit`],
          perHeadPeriodicLimitDays:
            defaultData[
              `${type}TypePerHeadPeriodicLimitEffectiveNumberOfDays`
            ],
        };

        break;

      case EarningRuleType.memberReferral:
      case EarningRuleType.birthday:
        commonData = {};
        break;

      default:
        break;
    }
  }

  const earningRule = { ...formatData, ...commonData };
  return earningRule
}

export default {
  namespace: 'createEarningRules',
  state: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    stepChange(state, { payload }) {
      const isBack = payload.isBack;
      let step = payload.step;
      const isValid = payload.isValid;
      const stepConfig = getNewStepConfig(
        step,
        state.stepConfig,
        !isValid,
        isBack,
      );
      if (isValid) {
        step = isBack ? step - 1 : step + 1;
      }
      console.log(
        '@@106: ',
        step,
        '->',
        isValid,
        payload
      );
      return {
        ...state,
        currentStep: step,
        stepConfig,
        createStatus: APIStatus.none,
      }
    },

    loadEarningRuleFromCookie(state, { payload }) {
      const earningRule = getObjectFromSessionStorage(EARNING_RULES_SESSION_KEY) || {};
      // const defaultData = payload ? payload.data.earningRule : {};

      // let earningRule = defaultData;
      // if (!tempEarningRule) {
      //   earningRule = praseEarningRule(defaultData)
      // }

      // if (!defaultData.pk) {
      //   earningRule = tempEarningRule || {};
      //   delete earningRule.id;
      //   delete earningRule.pk;
      // }
      console.log("@259 earningRule", earningRule)
      // saveToSessionStorage(EARNING_RULES_SESSION_KEY, earningRule);
      return {
        ...state,
        earningRule,
        hasUpdatedDefaultValues: true,
      };
    },
    removeEarningRuleFromCookie(state, { payload }) {
      removeFromSessionStorage(EARNING_RULES_SESSION_KEY);

      return {
        ...state,
        hasUpdatedDefaultValues: false,
      };
    },
    // clearData(state, { payload }) {
    //   return { ...state, ...getInitState() };
    // },

    // saveOrRemoveEarningRuleFromCookie(state, { payload }) {
    //   let campaign = getObjectFromSessionStorage(EARNING_RULES_SESSION_KEY);
    //   if (payload.data) {
    //     campaign = { ...payload.data };
    //     saveToSessionStorage(EARNING_RULES_SESSION_KEY, campaign);
    //   } else {
    //     removeFromSessionStorage(EARNING_RULES_SESSION_KEY);
    //   }
    //   return {
    //     ...state,
    //   };
    // },
    changeVals(state, { payload }) {
      console.log('@@138: vals changed', payload);
      let tempEarningRule = getObjectFromSessionStorage(EARNING_RULES_SESSION_KEY);
      if (payload.vals) {
        let data = {};
        if (payload.language) {
          data[payload.language] = {
            ...tempEarningRule[payload.language],
            ...payload.vals,
          };
        } else {
          data = payload.vals;
        }

        tempEarningRule = { ...tempEarningRule, ...data };
        saveToSessionStorage(EARNING_RULES_SESSION_KEY, tempEarningRule);
      }

      return {
        ...state,
        formChanged: true,
      };
    },
    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
  },

  effects: {
    getOneEarningRule: [
      function* ({ payload }, { call, select, put }) {
        yield put({
          type: 'updateState',
          payload: { createStatus: APIStatus.calling },
        });
        const serviceArgs = [
          getOneEarningRule,
          convertPKToId('EarningRuleNode', payload.id),
        ];
        function* onSuccess(data) {
          console.log(data)
          const earningRule = praseEarningRule(data?.earningRule)
          //save to state.earningRule
          // removeFromSessionStorage(EARNING_RULES_SESSION_KEY);
          // yield put({
          //   type: 'loadEarningRuleFromCookie',
          //   payload: { data },
          //   hasUpdatedDefaultValues: true,
          // });
          yield put({
            type: 'updateState',
            payload: {
              earningRule: earningRule,
              createStatus: APIStatus.none,
              hasUpdatedDefaultValues: true,
            },
          });
          const action = payload?.afterAction || (() => {});
          yield action(earningRule);
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    createOrUpdateEarningRule: [
      function* ({ payload }, { call, all, put, select }) {
        // const tempEarningRule = getObjectFromSessionStorage(EARNING_RULES_SESSION_KEY);
        const tempEarningRule = payload.data
        console.log("@327", tempEarningRule, payload)
        if (!tempEarningRule?.type) {
          return;
        }
        const oldEarningRule = yield select(
          (state) => state.createEarningRules.earningRule,
        );

        const key = EarningRuleTypeKey[tempEarningRule.type];

        let data = {
          name: tempEarningRule.generalName,
          type: tempEarningRule.type,
          instructionSectionTitle: tempEarningRule.translations?.en?.instructionSectionTitle,
          instructionSectionContent:
            tempEarningRule.translations?.en?.instructionSectionContent,
          detailSectionTitle: tempEarningRule.translations?.en?.detailSectionTitle,
          detailSectionContent: tempEarningRule.translations?.en?.detailSectionContent,
          translations: postTranslations(tempEarningRule.translations)
        };
        data[`${key}TypeRewardType`] = tempEarningRule.rewardType;
        let rewardData = {};
        if (tempEarningRule.rewardType === EarningRuleRewardType.points) {
          rewardData[`${key}TypePointsRewardTypePoints`] = parseInt(
            tempEarningRule.quantity,
          );
        } else {
          rewardData[`${key}TypeCouponRewardTypeCouponTemplate`] =
            tempEarningRule.coupons.pk;
          rewardData[`${key}TypeCouponRewardTypeQuantity`] = parseInt(
            tempEarningRule.quantity,
          );
        }

        let values = {};
        switch (tempEarningRule.type) {
          case EarningRuleType.generalPurchase:
            values = {
              generalPurchaseTypeMinimumSpending: parseInt(
                tempEarningRule.minSpending,
              ),
              generalPurchaseTypeMaximumSpending:
                (tempEarningRule.maxSpending &&
                  parseInt(tempEarningRule.maxSpending)) ||
                null,
              generalPurchaseTypeEligibleNumberOfDaysSincePurchase:
                (tempEarningRule.eligibleDays &&
                  parseInt(tempEarningRule.eligibleDays)) ||
                null,
              generalPurchaseTypeWillExcludeDeliveryCost:
                tempEarningRule.isExcludeDeliveryCost || false,
              generalPurchaseTypeWillExcludeOtherCharges:
                tempEarningRule.isExcludeOtherCharges || false,
              generalPurchaseTypeLimitedToStores: tempEarningRule.selectedStores
                ? tempEarningRule.selectedStores.map((item, index) => item.pk)
                : [],
              generalPurchaseTypeRewardType: tempEarningRule.rewardType,
              // generalPurchaseTypeLimitedSkus: tempEarningRule.specialSkus,
              generalPurchaseTypeLimitedSkus: tempEarningRule.specialSkus?.map(
                (item) => ({
                  skuId: item.pk,
                  sku: item.name,
                  categoryName: item.category.name,
                  categoryId: item.category.pk,
                }),
              ),
              generalPurchaseTypeOverallLimit:
                tempEarningRule.overallLimit &&
                parseInt(tempEarningRule.overallLimit),
              generalPurchaseTypePerHeadLimit:
                tempEarningRule.perHeadLimit &&
                parseInt(tempEarningRule.perHeadLimit),
              generalPurchaseTypePeriodicLimit:
                tempEarningRule.periodicLimit &&
                parseInt(tempEarningRule.periodicLimit),
              generalPurchaseTypePeriodicLimitEffectiveNumberOfDays:
                tempEarningRule.periodicLimitDays &&
                parseInt(tempEarningRule.periodicLimitDays),
              generalPurchaseTypePerHeadPeriodicLimit:
                tempEarningRule.perHeadPeriodicLimit &&
                parseInt(tempEarningRule.periodicLimitDays),
              generalPurchaseTypePerHeadPeriodicLimitEffectiveNumberOfDays:
                tempEarningRule.perHeadPeriodicLimitDays &&
                parseInt(tempEarningRule.perHeadPeriodicLimitDays),
            };
            if (tempEarningRule.rewardType === EarningRuleRewardType.coupons) {
              values['generalPurchaseTypeCouponRewardTypeCouponTemplate'] =
                tempEarningRule.coupons.pk;
              values['generalPurchaseTypeCouponRewardTypeQuantity'] = parseInt(
                tempEarningRule.quantity,
              );
            } else {
              values[
                'generalPurchaseTypePointsRewardTypePointsPerXDollarsSpent'
              ] = parseInt(tempEarningRule.quantity);
              values['generalPurchaseTypePointsRewardTypeX'] = parseInt(
                tempEarningRule.rewardTypeX,
              );
            }
            break;
          case EarningRuleType.gpsCheckIn:
            values = {
              gpsCheckInTypeLatitude:
                tempEarningRule.latitude &&
                parseFloat(tempEarningRule.latitude),
              gpsCheckInTypeLongitude: parseFloat(tempEarningRule.longitude),
              gpsCheckInTypeRadiusInMeter: parseInt(tempEarningRule.radius),
              gpsCheckInTypeOverallLimit: parseInt(
                tempEarningRule.overallLimit,
              ),
              gpsCheckInTypePerHeadLimit: parseInt(
                tempEarningRule.perHeadLimit,
              ),
              gpsCheckInTypePeriodicLimit: parseInt(
                tempEarningRule.periodicLimit,
              ),
              gpsCheckInTypePeriodicLimitEffectiveNumberOfDays: parseInt(
                tempEarningRule.periodicLimitDays,
              ),
              gpsCheckInTypePerHeadPeriodicLimit: parseInt(
                tempEarningRule.perHeadPeriodicLimit,
              ),
              gpsCheckInTypePerHeadPeriodicLimitEffectiveNumberOfDays: parseInt(
                tempEarningRule.perHeadPeriodicLimitDays,
              ),
              ...rewardData,
            };
            break;
          case EarningRuleType.memberReferral:
            values = {
              memberReferralTypeBeneficiary:
                tempEarningRule.referralType.toUpperCase(),
              memberReferralTypeLimit:
                parseInt(tempEarningRule.maxInvitees) || null,
              ...rewardData,
            };
            break;
          case EarningRuleType.birthday:
            values = {
              birthdayTypePeriodStartDate: tempEarningRule.birthStart,
              birthdayTypePeriodEndDate: tempEarningRule.birthEnd,
              ...rewardData,
            };
            break;
          case EarningRuleType.qrCodeScanning:
            values = {
              qrCodeScanningTypeQrCodes: tempEarningRule.qrCodes,
              qrCodeScanningTypeOverallLimit:
                tempEarningRule.overallLimit &&
                parseInt(tempEarningRule.overallLimit),
              qrCodeScanningTypePerHeadLimit:
                tempEarningRule.perHeadLimit &&
                parseInt(tempEarningRule.perHeadLimit),
              qrCodeScanningTypePeriodicLimit:
                tempEarningRule.periodicLimit &&
                parseInt(tempEarningRule.periodicLimit),
              qrCodeScanningTypePeriodicLimitEffectiveNumberOfDays:
                tempEarningRule.periodicLimitDays &&
                parseInt(tempEarningRule.periodicLimitDays),
              qrCodeScanningTypePerHeadPeriodicLimit:
                tempEarningRule.perHeadPeriodicLimit &&
                parseInt(tempEarningRule.perHeadPeriodicLimit),
              qrCodeScanningTypePerHeadPeriodicLimitEffectiveNumberOfDays:
                tempEarningRule.perHeadPeriodicLimitDays &&
                parseInt(tempEarningRule.perHeadPeriodicLimitDays),
              ...rewardData,
            };
            break;
          case EarningRuleType.fillingForm:
            values = {
              fillingFormTypeForm: tempEarningRule.fillingForm.pk,
              fillingFormTypeDefinitionType: tempEarningRule.definitionType,
              fillingFormTypeOverallLimit: parseInt(
                tempEarningRule.overallLimit,
              ),
              fillingFormTypePerHeadLimit: parseInt(
                tempEarningRule.perHeadLimit,
              ),
              fillingFormTypePeriodicLimit: parseInt(
                tempEarningRule.periodicLimit,
              ),
              fillingFormTypePeriodicLimitEffectiveNumberOfDays: parseInt(
                tempEarningRule.periodicLimitDays,
              ),
              fillingFormTypePerHeadPeriodicLimit: parseInt(
                tempEarningRule.perHeadPeriodicLimit,
              ),
              fillingFormTypePerHeadPeriodicLimitEffectiveNumberOfDays:
                parseInt(tempEarningRule.perHeadPeriodicLimitDays),
              ...rewardData,
            };
            break;
          default:
            values = {
              ...rewardData,
            };
            break;
        }

        // let serviceArgs = [createEarningRule, { ...data, ...values }];
        // if (tempEarningRule.pk) {
        //   serviceArgs = [
        //     updateEarningRule,
        //     { id: tempEarningRule.pk, ...data, ...values },
        //   ];
        // }
        if (tempEarningRule.pk) {
          data['id'] = tempEarningRule.pk
        }
        const serviceArgs = [createOrUpdateEarningRule, { ...data, ...values }]
        console.log('@@493: ', { ...data, ...values });
        const afterAction = payload.afterAction || (() => {});
        function* onSuccess(data) {
          console.log('@@267', data);
          if (
            ('createEarningRule' in data && data.createEarningRule.errors) ||
            ('updateEarningRule' in data && data.updateEarningRule.errors)
          ) {
            yield put({
              type: 'updateState',
              payload: {
                createStatus: APIStatus.failed,
                formChanged: false,
                checked: CheckStatus.initOrNotChecked,
              },
            });
          } else {
            yield put({
              type: 'updateState',
              payload: {
                createStatus: APIStatus.success,
                formChanged: false,
                earningRule: { pk: data.createEarningRule?.node?.pk },
                checked: CheckStatus.initOrNotChecked,
              },
            });
          }

          yield put({ type: 'removeEarningRuleFromCookie' });
          afterAction();
        }

        function* onFail(errors) {
          console.log('@275:', errors);

          yield put({
            type: 'updateState',
            payload: {
              createStatus: APIStatus.failed,
              formChanged: false,
              checked: CheckStatus.initOrNotChecked,
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFail);
      },
      { type: 'takeLatest' },
    ],
  },
};
