import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Collapse, Image } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import queryString from 'query-string';
import './CreateCampaignStepOne.scss';
import Loading from '../../../components/base/Loading';
import BasePrompt from '../../../components/base/prompt/BasePrompt';
import ContentSections from '../../../components/base/ContentSections';
import { OnlyContinueButton } from '../../../components/base/BottomStepComponent';
import { ReactHookFormErrorMessage } from '../../../components/base/ErrorFieldMessage';
import ItemTypeSet, {
  MIN_WIDTH,
  SPACE_BETWEEN,
} from '../../../components/base/ItemTypeSet';
import { validate } from '../../../containers/engagement/campaign/CreateCampaignValidate';
import CouponSelectDropdown from './CouponSelectDropdown';
import EarningRulesSelectDropdown from './EarningRulesSelectDropdown';
import CampaignLinkPrompt from './CampaignLinkPrompt';
import {
  getCampaignTypeSelectPromptConfig,
  CampaignTypesConfig,
} from './CreateCampaignStepOneConfigs';
import { ContinueCreate } from '../CampaignUrlConfig';
import {
  getScrollbarWidth,
  useMainContentWidth,
} from '../../../utils/ScreenUtil';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { CampaignType, APIStatus } from '../../../config/CustomEnums';
import addImage from '../../../assets/images/addx1.png';
import {
  isShowEarningRuleRelated,
  isShowCouponRelated,
  fieldTransfer,
} from '../../../models/CreateCampaignModel';
import { useCompare } from '../../../utils';

function CreateCampaignStepOne({
  status,
  promptShowed,
  isPublished,
  linkedCoupon,
  campaign,
}) {
  const mainContentWidth = useMainContentWidth();
  const fullContainerWith = mainContentWidth - 80 - 60 - getScrollbarWidth();

  const [showCreateNewLinkPrompt, setShowCreateNewLinkPrompt] = useState(false);
  const [showLinkPrompt, setShowLinkPrompt] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    getValues,
    watch,
    setValue,
    control,
    formState,
    clearErrors,
    setError,
  } = useFormContext();
  const { errors } = formState;

  const watchCampaignType = watch('campaignType');
  const watchCoupon = watch('linkedCoupon') || {};
  const watchEarningRules = watch('linkedEarningRules');
  const isCouponRelated = isShowCouponRelated(watchCampaignType);
  const isEarningRuleRelated = isShowEarningRuleRelated(watchCampaignType);

  const selectRef = useRef('linkDropdown');
  // const [createNewLinkTitle, setCreateNewLinkTitle] = useState();

  const promptconfig = getCampaignTypeSelectPromptConfig(watchCampaignType);
  const search = queryString.parse(history.location.search);

  const [couponHasChanged, setCouponHasChanged] = useState(false);
  const [earningRuleHasChanged, setEarningRuleHasChanged] = useState(false);

  useEffect(() => {
    if (history.location.search && !watchCampaignType) {
      const type = search.type;
      const id = search.id;
      const actionName =
        type === CampaignType.couponsCampaign
          ? 'getAndLinkCouponDetail'
          : 'getAndLinkEarningRuleDetail';
      console.log('@90');
      dispatch({
        type: `createCampaign/${actionName}`,
        payload: {
          couponId: id,
          earningRuleId: id,
          afterAction: (data) => {
            console.log('@93', data);
            setValue('campaignType', type);
            if (data.linkedCoupon) {
              setValue('linkedCoupon', data.linkedCoupon);
              setLinkedTranslationData(data, 'linkedCoupon');
            } else {
              setValue('linkedEarningRules', data.linkedEarningRules);
              setLinkedTranslationData(data, 'linkedEarningRules');
            }

            goToNextStep(true);
          },
        },
      });
    }
    // else if (watchCoupon) {
    //   dispatch({
    //     type: `createCampaign/getAndLinkCouponDetail`,
    //     payload: {
    //       couponId: watchCoupon.pk,
    //       afterAction: (data) => {
    //         if (data.linkedCoupon?.stock !== watchCoupon.stock) {
    //           setValue('linkedCoupon', data.linkedCoupon);
    //         }
    //       },
    //     },
    //   });
    // }
  }, []);

  const setLinkedTranslationData = (data, dataKey) => {
    const sourceTranslations = { ...getValues().translations };
    (Object.keys(data[dataKey]?.translations) || []).forEach((lang) => {
      const translation = data[dataKey]?.translations[lang];

      (Object.keys(translation) || []).forEach((key) => {
        // if (sourceTranslations[lang] && key in sourceTranslations[lang]) {
        if (
          Object.keys(sourceTranslations).indexOf(lang) >= 0 &&
          ['id', 'pk'].indexOf(key) < 0
        ) {
          setValue(`translations.${lang}.${key}`, translation[key], {
            shouldDirty: true,
          });
        } else if (!sourceTranslations[lang] && ['id', 'pk'].indexOf(key) < 0) {
          setValue(`translations.${lang}.${key}`, translation[key], {
            shouldDirty: true,
          });
        }
      });
    });
  };

  const fetchLinked = ({ actionName, idKey, idValue, dataKey }) => {
    dispatch({
      type: actionName,
      payload: {
        [idKey]: idValue,
        afterAction: (data) => {
          console.log('@@111: ', data);
          setLinkedTranslationData(data, dataKey);

          // if (data.linkedCoupon?.stock !== watchCoupon.stock) {
          //   setValue('linkedCoupon', data.linkedCoupon);
          // }
        },
      },
    });
  };

  // const setCoupon = (value) => {
  //   dispatch(
  //     createAction('createCampaign/getAndLinkCouponDetail')({
  //       couponId: value?.pk,
  //       name: value.name,
  //     }),
  //   );
  // };
  // console.log('@@169');
  useEffect(() => {
    if (watchCoupon?.pk && (couponHasChanged || showLinkPrompt)) {
      // dispatch(
      //   createAction('createCampaign/getAndLinkCouponDetail')({
      //     couponId: watchCoupon.pk,
      //     couponGetted: (coupon) => setValue('linkedCoupon', coupon),
      //   }),
      // );

      fetchLinked({
        actionName: 'createCampaign/getAndLinkCouponDetail',
        idKey: 'couponId',
        idValue: watchCoupon.pk,
        dataKey: 'linkedCoupon',
      });
    }
  }, [watchCoupon?.pk, couponHasChanged]);

  useEffect(() => {
    if (watchEarningRules?.pk && (earningRuleHasChanged || showLinkPrompt)) {
      // dispatch(
      //   createAction('createCampaign/getAndLinkCouponDetail')({
      //     couponId: watchCoupon.pk,
      //     couponGetted: (coupon) => setValue('linkedCoupon', coupon),
      //   }),
      // );
      fetchLinked({
        actionName: 'createCampaign/getAndLinkEarningRuleDetail',
        idKey: 'earningRuleId',
        idValue: watchEarningRules.pk,
        dataKey: 'linkedEarningRules',
      });
    }
  }, [watchEarningRules?.pk, earningRuleHasChanged]);

  const couponLinkSet = (
    <Controller
      control={control}
      name="linkedCoupon"
      render={() => (
        <>
          <div className="step-link-coupon-area-select">
            <CouponSelectDropdown
              title={'Link to coupon set'}
              defaultValue={{
                value: watchCoupon,
                label: watchCoupon?.name,
              }}
              setValue={(item) => {
                console.log('couponChange:', item);
                const coupon = item || {};
                setValue('linkedCoupon', coupon, { shouldDirty: true });
                // fetchLinkedCoupon(coupon.pk);
                setCouponHasChanged(true);
              }}
              // addButtonAction={() => setShowCreateNewLinkPrompt(true)}
              addButtonInfo={{
                requires: PermissionCodes.changeCouponTemplate,
                children: <Image src={addImage} />,
                action: () => setShowCreateNewLinkPrompt(true),
                customClass: 'earning-rule-add-new-link-button',
              }}
            />
          </div>
          <ReactHookFormErrorMessage errors={errors} id="linkedCoupon" />
        </>
      )}
    />
  );

  const earningRuleLinkSet = (
    <Controller
      control={control}
      name="linkedEarningRules"
      render={() => (
        <>
          <div className="step-link-coupon-area-select">
            <EarningRulesSelectDropdown
              title={'Link to earning rule set'}
              defaultValue={{
                value: watchEarningRules,
                label: watchEarningRules?.name,
              }}
              setValue={(item) => {
                console.log('earning rule Change:', item);
                const coupon = item || {};
                setValue('linkedEarningRules', coupon, { shouldDirty: true });

                setEarningRuleHasChanged(true);
              }}
              // addButtonAction={() => setShowCreateNewLinkPrompt(true)}
              addButtonInfo={{
                requires: PermissionCodes.changeEarningRule,
                children: <Image src={addImage} />,
                action: () => setShowCreateNewLinkPrompt(true),
                customClass: 'earning-rule-add-new-link-button',
              }}
            />
          </div>
          <ReactHookFormErrorMessage errors={errors} id="linkedEarningRules" />
        </>
      )}
    />
  );

  // const linkSet = () => {
  //   const linkFiledShow =
  //     !campaignType || campaignType === CampaignType.generalMessageCampaign;
  //   return (
  //     <Collapse in={!linkFiledShow}>
  //       <div className="step-link-coupon-area-select" ref={selectRef}>
  //         {campaignType === CampaignType.couponsCampaign ? (
  //           <CouponSelectDropdown
  //             title={'Link to coupon set'}
  //             defaultValue={{
  //               value: linkedCoupon,
  //               label: linkedCoupon?.name,
  //             }}
  //             setValue={setCoupon}
  //             // addButtonAction={() => setShowCreateNewLinkPrompt(true)}
  //             addButtonInfo={{
  //               requires: PermissionCodes.changeCouponTemplate,
  //               children: <Image src={addImage} />,
  //               action: () => setShowCreateNewLinkPrompt(true),
  //               customClass: 'earning-rule-add-new-link-button',
  //             }}
  //           />
  //         ) : (
  //           <EarningRulesSelectDropdown
  //             title={'Link to earning rule set'}
  //             defaultValue={{
  //               value: linkedEarningRules,
  //               label: linkedEarningRules?.name,
  //             }}
  //             // addButtonAction={() => setShowCreateNewLinkPrompt(true)}
  //             addButtonInfo={{
  //               requires: PermissionCodes.changeEarningRule,
  //               children: <Image src={addImage} />,
  //               action: () => setShowCreateNewLinkPrompt(true),
  //               customClass: 'earning-rule-add-new-link-button',
  //             }}
  //           />
  //         )}
  //       </div>
  //     </Collapse>
  //   );
  // };

  const showPrompt = () => {
    if (promptShowed) {
      return;
    }
    if (!showLinkPrompt) {
      return;
    }
    return (
      <CampaignLinkPrompt
        handleContinue={() => {
          dispatch({
            type: 'createCampaign/updateState',
            payload: { promptShowed: true },
          });
          dispatch({
            type: 'createCampaign/stepChange',
            payload: {
              step: 0,
              isValid: true,
            },
          });
        }}
        closePrompt={() => {
          dispatch({
            type: 'createCampaign/updateState',
            payload: { promptShowed: true },
          });
          if (!watchCoupon && !watchEarningRules) {
            window.scrollTo(0, selectRef.current.offsetTop);
          }
        }}
        campaignType={watchCampaignType}
        linkedEarningRules={watchEarningRules}
        linkedCoupon={watchCoupon}
      />
    );
  };

  // const campaignTypeSelectArea = () => {
  //   return (
  //     <div
  //       className="step-type-area campaign-step-one-bottom"
  //       style={{
  //         justifyContent:
  //           fullContainerWith < (MIN_WIDTH + SPACE_BETWEEN) * 2
  //             ? 'center'
  //             : 'flex-start',
  //       }}
  //     >
  //       {CampaignTypesConfig.map((typeItem) => (
  //         <ItemTypeSet
  //           key={`${typeItem.id}-${typeItem.description}`}
  //           item={typeItem}
  //           selected={campaignType}
  //           onClick={(id) => {
  //             dispatch({
  //               type: 'createCampaign/typeChange',
  //               payload: { campaignType: id },
  //             });
  //             if (id !== CampaignType.generalMessageCampaign) {
  //               if (!promptShowed) {
  //                 setShowLinkPrompt(true);
  //               }
  //             }
  //           }}
  //         />
  //       ))}
  //     </div>
  //   );
  // };

  const campaignTypeSelectArea = (
    <Controller
      control={control}
      name="campaignType"
      render={() => (
        <div
          className="step-type-area campaign-step-one-bottom"
          style={{
            justifyContent:
              fullContainerWith < (MIN_WIDTH + SPACE_BETWEEN) * 2
                ? 'center'
                : 'flex-start',
          }}
        >
          {CampaignTypesConfig.map((typeItem) => (
            <ItemTypeSet
              key={`${typeItem.id}-${typeItem.description}`}
              item={typeItem}
              selected={watchCampaignType}
              disable={isPublished}
              onClick={(id) => {
                if (id === watchCampaignType) {
                  return;
                }
                clearErrors();
                setValue('campaignType', id, { shouldDirty: true });
                dispatch({
                  type: 'createCampaign/typeChange',
                  payload: { campaignType: id },
                });
                if (id !== CampaignType.generalMessageCampaign) {
                  if (!promptShowed) {
                    setShowLinkPrompt(true);
                  }
                }
              }}
            />
          ))}
        </div>
      )}
    />
  );

  const sections = [
    <>
      {status === APIStatus.calling ? (
        <Loading />
      ) : (
        <div>
          <label className="step-one-title">Campaign Type</label>
          {campaignTypeSelectArea}
          {isCouponRelated ? couponLinkSet : null}
          {isEarningRuleRelated ? earningRuleLinkSet : null}
          {showPrompt()}
          <BasePrompt
            show={showCreateNewLinkPrompt}
            closeAction={() => setShowCreateNewLinkPrompt(false)}
            rightButton={{
              text: promptconfig.button,
              action: () => {
                const pathname =
                  watchCampaignType === CampaignType.couponsCampaign
                    ? '/coupons/create'
                    : '/earns/create';
                history.push({
                  pathname: pathname,
                  state: {
                    from: {
                      ...history.location,
                      state: {
                        ...history.location?.state,
                        resume: true,
                      },
                    },
                    ...ContinueCreate,
                  },
                });
              },
            }}
            title={promptconfig.title}
            description={promptconfig.message}
          />
        </div>
      )}
    </>,
  ];

  const continueAction = () =>
    dispatch({ type: 'createCampaign/stepChange', payload: { step: 0 } });

  const goToNextStep = (assignRemain) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 0,
      isBack: false,
    });
    console.log('isValid:', isValid, errors);
    setValue('assignRemain', assignRemain);
    console.log('@357 values', getValues);
    // if (isCouponRelated || isEarningRuleRelated) {
    //   fieldTransfer({ setValue, getValues });
    // }
    dispatch({
      type: 'createCampaign/stepChange',
      payload: {
        step: 0,
        isValid,
      },
    });
  };

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <OnlyContinueButton
        continueAction={async () => {
          goToNextStep(true);
        }}
        disabledContinue={
          !watchCampaignType ||
          (watchCampaignType === CampaignType.couponsCampaign &&
            !watchCoupon?.pk) ||
          (watchCampaignType === CampaignType.earningRulesCampaign &&
            !watchEarningRules?.pk)
        }
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  campaignType: state.createCampaign.campaign.campaignType,
  linkedCoupon: state.createCampaign.campaign.linkedCoupon,
  linkedEarningRules: state.createCampaign.campaign.linkedEarningRules,
  status: state.createCampaign.createStatus,
  promptShowed: state.createCampaign.promptShowed,
  isPublished: state.createCampaign.campaign.isPublished,
  campaign: state.createCampaign.campaign,
});

export default connect(mapPropsToState)(CreateCampaignStepOne);
