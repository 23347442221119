import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import queryString from 'query-string';
import BaseListContainer from '../../base/BaseListContainer';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import Filter from '../../../components/customer/Filter';
import DeletePrompt from '../../../components/base/DeletePrompt';
import DateRangeCompare from '../../../components/dashboard/DateRangeCompare';
import DateRangeSelection from '../../../components/dashboard/DateRangeSelection';
import CustomerOverview from '../../../components/customer/CustomerOverview';
import AuthButton from '../../../components/base/AuthButton';
import CustomFileUploader from '../../../components/base/CustomFileUploader';
import ListButtonsGroup from '../../../components/base/ListButtonsGroup';
import BasePrompt from '../../../components/base/prompt/BasePrompt';
import { ImportResource } from '../../../models/DownloadImportModel';
// import BaseTest from '../../base/BaseTest';
import {
  DELETE_RELATED_SECTIONS,
  LanguageTransLation,
} from '../../../config/CustomEnums';
import { NavBarNames } from '../../../config/NavBarNameList';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { getHashKeyString } from '../../../utils';
import './CustomerList.scss';

const TAB_OVERVIEW = 'Overview';
const TAB_LIST_OF_CUSTOMER = 'List of Customers';


function CustomerList({
  listFields,
  dataList,
  pageInfo,
  totalPage,
  totalCount,
  checkedList,
  filters,
  importedFile,
  isSuperuser,
  userPermissions,
  importError,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const activeTab = getHashKeyString(location.hash) || TAB_OVERVIEW;

  const hiddenFileInput = React.useRef(null);

  const handleImportClick = (event) => {
    hiddenFileInput.current.click();
    console.log('@@71');
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    // props.handleFile(fileUploaded);
    console.log('@@77');
    dispatch({
      type: 'uploadFiles/uploadFile',
      payload: {
        file: fileUploaded,
        source: event,
      }
    });

    event.target.value = null;
  };
  const exportCSVAction = () => {
    dispatch({
      type: 'downloadAndImport/createDownloadTask',
      payload: {
        from: ImportResource.customer,
      }
    });
  };

  const buttons = [
    <ListButtonsGroup
      hideExtraButtonWidth={500}
      extraButtons={[
        <AuthButton
          customClass="btn-back-button-common btn-download"
          title="Export .csv"
          action={exportCSVAction}
          requires={PermissionCodes.addExportjob}
        />,
        <AuthButton
          title={'Import .csv'}
          action={handleImportClick}
          requires={PermissionCodes.addImportjob}
        />,
      ]}
      extraPopContent={[
        {
          requires: PermissionCodes.addExportjob,
          action: exportCSVAction,
          content: 'Export .csv',
        },
        {
          requires: PermissionCodes.addImportjob,
          action: handleImportClick,
          content: 'Import .csv',
        },
      ]}
    />,
    <input
      type="file"
      ref={hiddenFileInput}
      onChange={handleChange}
      style={{ display: 'none' }}
      accept=".csv"
    />,
  ];

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        // setShowPrompt(true);
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.deleteCustomer,
    },
  ];

  const tabs = [
    {
      name: TAB_OVERVIEW,
      content: <CustomerOverview />,
    },
    isSuperuser ||
    userPermissions?.filter(
      (val) => val.split('_')[1] === PermissionCodes.customer,
    ).length > 0
      ? {
          name: TAB_LIST_OF_CUSTOMER,
          content: (
            <BaseTabListContainer
              hideTab={true}
              tabs={[]}
              groupActions={groupActions}
              // defaultTabKey={levels}
              pageInfo={pageInfo}
              totalCount={totalCount}
              filter={{
                hasFilter: true,
                componment: Filter,
              }}
              listContent={
                <BaseListContainer
                  fields={listFields}
                  dataList={dataList}
                  totalPage={totalPage}
                  model={'customerList'}
                  permissionGroup={PermissionCodes.customer}
                  deleteInfo={{
                    data: [],
                    title: 'customers',
                    relatedName: '',
                    relatedSections: DELETE_RELATED_SECTIONS.CUSTOMER,
                    onComfirm: {},
                  }}
                  hasOtherActions={true}
                  customActions={{
                    deactive: (item) => {
                      console.log('@@157: ', item);
                      dispatch({
                        type: 'customerList/updateCustomerActive',
                        payload: {
                          data: {
                            isForcedInactive: item.status === 'Deactive',
                            id: item.pk,
                          },
                          afterAction: () => history.push(location),
                        }
                      });
                    },
                  }}
                />
              }
            />
          ),
        }
      : {},
  ];

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  // useEffect(() => {
  //   // dispatch(createAction('levels/getLevelList')({}));
  //   // dispatch(
  //   //   createAction('customerList/getPagedCustomers')({
  //   //     page: 0,
  //   //     reverse: true,
  //   //     search: '',
  //   //   }),
  //   // );
  // }, [dispatch]);

  useEffect(() => {
    // console.log('@@202: ', filters);
    if (activeTab === TAB_LIST_OF_CUSTOMER) {
      const parsedSearch = queryString.parse(location.search);

      const searchKey = parsedSearch['search'] || '';
      const stringRank = parsedSearch['rank'] || 'true';
      const stringPage = parsedSearch['page'] || 1;
      const rank = stringRank === 'true';
      const page = parseInt(stringPage);
      console.log('@@202: ', parsedSearch);
      dispatch({
        type: 'customerList/getPagedCustomers',
        payload: {
          ...parsedSearch,
          page,
          reverse: rank,
          search: searchKey,
        }
      });
    }
  }, [dispatch, location, activeTab]);

  useEffect(() => {
    if (importedFile) {
      dispatch({
        type: 'downloadAndImport/importFile',
        payload: {
          from: ImportResource.customer,
          file: importedFile,
        }
      });
    }
  }, [dispatch, importedFile]);

  const [showError, setShowError] = useState(false);

  useEffect(() => {
    console.log("@256", importError)
    setShowError(importError ? Object.keys(importError).length > 0 : false);
  }, [importError]);

  return (
    <>
      <CustomListComponent
        caution={{
          detail:
            'All the customers are listed here, and you can view their details and records. You can learn about the user usage of the app here.',
          title: NavBarNames.customer,
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={false}
        onTabChange={(key) => {
          // setActiveTab(key);
          history.push({ pathname: location.pathname, hash: key });
        }}
        renderCompare={() => {
          return activeTab === TAB_OVERVIEW ? <DateRangeCompare /> : null;
        }}
        renderTabsRight={() => {
          return activeTab === TAB_OVERVIEW ? (
            <DateRangeSelection
              onTab
              applyDateRangeEvent={(startDate, endDate) => {
                dispatch({
                  type: 'overview/getCustomersData',
                  payload: {
                    startDate: startDate.format('YYYY-MM-DD'),
                    endDate: endDate.format('YYYY-MM-DD'),
                  }
                });
              }}
              clearDataEvent={() => {
                dispatch({
                  type: 'overview/clearState',
                  payload: {}
                });
              }}
            />
          ) : null;
        }}
      />

      <DeletePrompt
        data={checkedList}
        title={'customers'}
        relatedName={''}
        relatedSections={DELETE_RELATED_SECTIONS.CUSTOMER}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch({
            type: 'customerList/delete',
            payload: {
              afterAction: () => {
                history.push(location);
              },
            }
          });
        }}
      />

      <BasePrompt
        show={showError}
        title={importError?.title}
        description={importError?.content}
        closeAction={() => {
          setShowError(false);
          dispatch({
            type: 'downloadAndImport/updateState',
            payload: { error: {} },
          });
        }}
        leftButton={{
          text: 'Cancel',
          action: () => {
            setShowError(false);
            dispatch({
              type: 'downloadAndImport/updateState',
              payload: { error: {} },
            });
          },
        }}
        rightButton={{
          text: 'Import again',
          action: () => {
            handleImportClick();
            setShowError(false);
            dispatch({
              type: 'downloadAndImport/updateState',
              payload: { error: {} },
            });
          },
          requires: PermissionCodes.addImportjob,
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  listFields: state.customerList.listDisplayFields,
  dataList: state.customerList.pagedCustomerList,
  pageInfo: state.customerList.pageInfo,
  totalPage: state.customerList.totalPage,
  totalCount: state.customerList.listTotalCount,
  checkedList: state.customerList.checkedList,
  filters: state.customerList.filters,
  importedFile: state.uploadFiles.importedFile,
  isSuperuser: state.users.isSuperuser,
  userPermissions: state.users.userPermissions,
  importError: state.downloadAndImport.error,
});

export default connect(mapPropsToState)(CustomerList);
