import React, { useEffect, useState } from 'react';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ContentSections from '../../../components/base/ContentSections';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
import './CouponTransactionOverview.scss';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../../utils';
import { OnlyBackButton, SaveAndBackWithOutTempButtons } from '../../../components/base/BottomStepComponent';
import { couponRecordQueryKey } from '../../../components/pointRecord/RecordsUrlConfig';
import { useFormContext } from 'react-hook-form';
import { PermissionCodes } from '../../../config/PermissionCodes';
import CustomEditor from '../../../components/base/CustomEditor';

function CouponTransactionOverview() {
  const queryString = require('query-string');
  const dispatch = useDispatch();
  const history = useHistory();
  const couponTransactionPK = useParams().id;

  useEffect(() => {
    dispatch(
      createAction('couponTransactions/getSingleCouponTransactionRecord')({
        couponTransactionPK,
      }),
    );
    return () => {
      dispatch({ type: 'couponTransactions/clearData' });
    };
  }, [couponTransactionPK, dispatch]);

  const { record } = useSelector((state) => ({
    record: state.couponTransactions.selectedCouponTransaction,
  }));

  const [remarks, setRemarks] = useState(record?.remarks);
  const [hasRemarks, setHasRemarks] = useState(true);

  useEffect(() => {
    console.log('@41', record);
    record?.remarks && setRemarks(record?.remarks)
  }, [record])

  const dataWithType = (couponTransactionType) => {
    let differentItems = [];
    switch (couponTransactionType) {
      case 'Customer earned':
        differentItems = [
          [{ title: 'Earning rule ID and name', field: 'displayEarningRule' }],
        ];
        break;
      case 'Customer acquired':
        differentItems = [
          [{ title: 'Points spent', field: 'pointsSpent' }],
          [{ title: 'Campaign ID and name', field: 'displayCampaign' }],
        ];
        break;
      case 'Admin added':
      case 'Admin removed':
        break;
      case 'Customer use':
        differentItems = [[{ title: 'Used date', field: 'usedDate' }]];
        break;
      case 'Coupon expired':
        if (record.earningRuleName) {
          differentItems = [
            [{ title: 'Earning rule ID and name', field: 'displayEarningRule' }],
          ];
        } else if (record.pointsSpent) {
          differentItems = [
            [{ title: 'Points spent', field: 'pointsSpent' }],
            [{ title: 'Campaign ID and name', field: 'displayCampaign' }],
          ];
        } else if (record.usedDate) {
          differentItems = [[{ title: 'Used date', field: 'usedDate' }]];
        }
        break;
      default:
        break;
    }
    let data = [
      [{ title: 'Name (preferred name)', field: 'name' }],
      [{ title: 'Membership ID', field: 'membershipId' }],
      [{ title: 'Transaction type', field: 'displayType' }],
      ...differentItems,
      [{ title: 'Coupon set ID and name', field: 'displayCoupon' }],
      [{ title: 'Expiry date', field: 'couponExpiryDate' }],
      [{ title: 'Record create date', field: 'creationDate' }],
    ];
    if (record.administratorName) {
      data = [
        ...data,
        [({ title: 'Administrator', field: 'administratorName' })]
      ]
    }
    data = [
      ...data,
      [({
        title: 'Remarks', field: 'remarks',
        component: <CustomEditor
          initialValue={record?.remarks}
          onValueChange={(value) => {
            setRemarks(value);
          }}
          error={!hasRemarks}
          errorMessage={'Please provide a remarks.'} />
      })]
    ]
    return data;
  };

  const tabs = [
    {
      name: '',
      content: (
        <div className="scroll-container-common">
          <ContentSections
            sections={[
              <MembershipInfoCard
                title={''}
                data={record}
                fields={dataWithType(record.displayType)}
              />,
            ]}
            hidePreview={true}
            sectionStyle="coupon-transaction-record-detail-section"
          />
        </div>
      ),
    },
  ];

  const backAction = () => {
    history.push({
      pathname: '/coupon_records',
      search: queryString.stringify({
        [couponRecordQueryKey.searchKey]: '',
        [couponRecordQueryKey.page]: 1,
        [couponRecordQueryKey.rank]: true,
        [couponRecordQueryKey.tab]: 'all',
      }),
    });
  }

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '', //view coupon records
          title: 'View Coupon records',
        }}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
      />
      <SaveAndBackWithOutTempButtons
        backAction={backAction}
        saveText={'Save'}
        actionText={'Save'}
        saveAction={() => {
          if (!remarks) {
            setHasRemarks(false)
          } else {
            dispatch(
              createAction('couponTransactions/updateCouponTransactionRecord')({
                data: {
                  ...record,
                  remarks: remarks
                },
              }),
              backAction()
            );
          }
        }}
        saveRequires={PermissionCodes.changeTransaction}
      />
    </>
  );
}

export default CouponTransactionOverview;
