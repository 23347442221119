import { defaultStep, getNewStepConfig } from './StepBarUtil';
import { TRANSACTION_RECORD_TYPE } from '../containers/record/records/TransactionList';
import {
  createTransaction,
  updateTransaction,
} from '../services/TransactionRecordsAPIHelper';
import { loading } from './LoadingUtil';
import {
  createAction,
  getFileNameFromUrl,
  saveToSessionStorage,
  removeFromSessionStorage,
  getObjectFromSessionStorage,
} from '../utils';
import { formatDate, TimeFormater } from '../utils/TimeFormatUtil';

export const sessionDataKey = {
  objectKey: 'createTransaction',
  stepEndKey: 'createTransactionStepEnd',
  origionalData: 'createTransactionOriginalData',
};

export const CreateTransactionError = {
  customer: {
    name: 'customer',
    message: 'Please provide a customer.',
  },
  transactionDate: {
    name: 'transactionDate',
    message: 'Please provide a transaction date.',
  },
  store: {
    name: 'stote', //optional when type is POS
    message: 'Please provide a store.',
  },
  posInvoiceId: {
    name: 'posInvoiceID',
    message: 'Please provide a pos invoice id.',
  },
  invoiceId: {
    name: 'invoiceID',
    message: 'Please provide a invoicd id.',
  },
  offlineEventType: {
    name: 'offlineEventType',
    message: 'Please provide a offline event type.',
  },
  staffName: {
    name: 'staffName',
    message: 'Please provide a staff name.',
  },
  totalValue: {
    name: 'totalValue',
    message: 'Please provide a total value.',
  },
  totalValueFormat: {
    name: 'totalValueFormat',
    messages: 'Please enter total value in digits.',
  },
  purchasedItemName: {
    name: 'purchasedItemName',
    message: 'Please provide a product name.',
  },
  purchasedItemQuantity: {
    name: 'purchasedItemQuanitity',
    message: 'Please provide a quantity.',
  },
  purchasedItemQuantityFormat: {
    name: 'purchasedItemQuantityFormat',
    message: 'Please enter quantity in digits.',
  },
  purchasedItemValue: {
    name: 'purchasedItemValue',
    message: 'Please provide a value.',
  },
  purchasedItemValueFormat: {
    name: 'purchasedItemValueFormat',
    message: 'Please enter value in digits.',
  },
  receiptImage: {
    name: 'receiptImage',
    message: 'Please provide a receipt image.',
  },
  creditCardSlipImage: {
    name: 'creditCardSlipImage',
    message: 'Please provide a credit card slip image.',
  },
};

function getTransactionInit() {
  return {
    pk: null,
    id: null,
    ssoUid: null,
    name: null,
    transactionType: null,
    transactionDisplayType: null,
    storeName: null,
    creationDate: null,
    transactionDate: new Date(),
    transactionDetailDisplayDate: null,
    transactionDetailCreationDate: null,
    totalValue: null,
    onlineEventType: null,
    offlineEventType: null,
    displayOfflineEventType: null,
    staffName: null,
    shippingFee: null,
    otherCharge: null,
    remarks: null,
    posInvoiceId: null,
    invoiceId: null,
    receiptImage: null,
    creditCardSlipImage: null,
    purchasedItems: [
      // {
      //   productName: null,
      //   sku: null,
      //   category: null,
      //   brand: null,
      //   quantity: null,
      //   value: null,
      // },
    ],
    customer: {},
    store: {},
  };
}

function getInitState() {
  const stepNameList = ['Type', 'Content'];
  return {
    transaction: getTransactionInit(),
    errorFields: [],
    stepConfig: defaultStep(stepNameList),
    currentStep: 0,
    selectedType: null,
    transactionStatusChanged: false,
    formHasSubmitted: false,
    hasUpdatedDefaultValues: false,
  };
}

function parsePhotoUrlForAPI(image) {
  let imageUrl = image;
  if (image && image.value) {
    imageUrl = image.value;
  }
  return getFileNameFromUrl(imageUrl);
}

function parseCreateTransactionInputBody(data,user) {
  const receiptImage = data.receiptImage;
  const creditCardSlipImage = data.creditCardSlipImage;
  const transactionDisplayType = data.transactionDisplayType;
  let inputBody = {
    date: data.transactionDate,
    // date: formatDate(data.transactionDate, TimeFormater.dayMonthYearWeekTimeA),
    offlineEventType: data.offlineEventType,
    staffName: data.staffName,
    customer: data.customer.pk,
    totalValue: parseFloat(data.totalValue),
    store: data.store.storePK,
    remarks: data.remarks,
    lastUpdatedByAdmin: user.username,
  };
  if (transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS) {
    inputBody = {
      ...inputBody,
      posInvoiceId: data.posInvoiceId,
    };
  } else if (transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM) {
    inputBody = {
      ...inputBody,
      invoiceId: data.invoiceId,
      receiptImage: parsePhotoUrlForAPI(receiptImage),
      creditCardSlipImage: parsePhotoUrlForAPI(creditCardSlipImage),
    };
  }
  console.log('@@288: ', data, inputBody);
  return inputBody;
}

function parsePurchasedItemInputBody(data) {
  const inputBody = {
    name: data.productName,
    sku: data.sku,
    category: data.category,
    brand: data.brand,
    quantity: data.quantity,
    value: data.value,
  };
  return inputBody;
}

export default {
  namespace: 'createTransaction',
  state: getInitState(),

  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    updateTransactionState(state, { payload }) {
      const transaction = { ...state.transaction, ...payload };
      console.log('@@433: ', transaction);
      saveToSessionStorage(sessionDataKey.objectKey, transaction);
      return {
        ...state,
        transaction: transaction,
      };
    },
    loadTransactionFromCookie(state, { payload }) {
      const transaction = getObjectFromSessionStorage(sessionDataKey.objectKey);
      if (!transaction) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        transaction: transaction,
        hasUpdatedDefaultValues: true,
      };
    },
    saveOrRemoveTransactionFromCookie(state, { payload }) {
      if (payload.save) {
        if (payload.values) {
          saveToSessionStorage(sessionDataKey.objectKey, payload.values);
        }
      } else {
        removeFromSessionStorage(sessionDataKey.objectKey);
      }
      saveToSessionStorage(sessionDataKey.stepEndKey, true);
      return {
        ...state,
      };
    },
    addPurchasedItem(state, { payload }) {
      return {
        ...state,
        transaction: {
          ...state.transaction,
          purchasedItems: [...state.transaction.purchasedItems, { ...payload }],
        },
      };
    },
    updatePruchasedItems(state, { payload }) {
      const { index, data } = payload;
      return {
        ...state,
        transaction: {
          ...state.transaction,
          purchasedItems: [
            ...state.transaction.purchasedItems.slice(0, index),
            {
              ...state.transaction.purchasedItems[index],
              ...data,
            },
            ...state.transaction.purchasedItems.slice(index + 1),
          ],
        },
      };
    },
    stepChange(state, { payload }) {
      const isBack = payload.isBack;
      let step = payload.step;
      const isValid = payload.isValid;
      const stepConfig = getNewStepConfig(
        step,
        state.stepConfig,
        !isValid,
        isBack,
      );
      if (isValid) {
        step = isBack ? step - 1 : step + 1;
      }
      return {
        ...state,
        stepConfig,
        currentStep: step,
      };
    },
    clearData(state, { payload }) {
      return { ...state, ...getInitState() };
    },
  },

  effects: {
    *setFieldToSession({ payload }, { select }) {
      const oldTransaction = yield select(
        (state) => state.createTransaction.transaction,
      );
      const transaction = { ...oldTransaction, ...payload };

      saveToSessionStorage(sessionDataKey.objectKey, transaction);
    },
    createTransaction: [
      function* ({ payload }, { call, select, put }) {
        const { values } = payload;
        const user = yield select((state) => state.users)
        const transactionDisplayType = values.transactionDisplayType;
        const data = parseCreateTransactionInputBody(values,user);
        if (transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_ONLINE) {
          data.transactionType = 'ONLINE';
        } else if (transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS) {
          data.transactionType = 'OFFLINE_POS';
        } else if (transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM) {
          data.transactionType = 'OFFLINE_REWARD_CLAIM';
        }
        if (values.transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS) {
          const purchasedItems = values.purchasedItems;
          data.purchasedItemsInput = purchasedItems.map((item) => {
            return parsePurchasedItemInputBody(item);
          });
        }

        const serviceArgs = [createTransaction, data];

        saveToSessionStorage(sessionDataKey.stepEndKey, true);

        function* onSuccess(data) {
          const transactionData = data.createTransaction.node;
          yield put({
            type: 'updateTransactionState',
            payload: {
              pk: transactionData?.pk,
              id: transactionData?.id,
            }
          });
          yield put({
            type: 'updateState',
            payload: {
              transactionStatusChanged: true,
              formHasSubmitted: true,
            },
          });
          removeFromSessionStorage(sessionDataKey.objectKey);
        }

        function* onFailed(data) {
          console.log('@@512: ', data);
          yield put({
            type: 'updateState',
            payload: {
              formHasSubmitted: true,
            },
          });
        }

        function onArgumentsError(data) {
          console.log('@@512: ', data);
        }
        yield loading(serviceArgs, onSuccess, onFailed, onArgumentsError);
      },
      { type: 'takeLatest' },
    ],

    updateTransaction: [
      function* ({ payload }, { put, select }) {
        const { values } = payload;
        const user = yield select((state) => state.users)
        let parsedInputBody = parseCreateTransactionInputBody(values,user);
        parsedInputBody.id = values.pk;

        if (values.transactionDisplayType ===TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS) {
          const purchasedItems = values.purchasedItems;
          parsedInputBody.purchasedItemsInput = purchasedItems.map((item) => {
            const inputBody = parsePurchasedItemInputBody(item);
            if (item.pk) {
              inputBody.id = item.pk;
            }
            return inputBody;
          });
        }

        saveToSessionStorage(sessionDataKey.stepEndKey, true);
        const serviceArgs = [updateTransaction, parsedInputBody];
        function* onSuccess() {
          removeFromSessionStorage(sessionDataKey.objectKey);
          yield put({
            type: 'updateState',
            payload: {
              transactionStatusChanged: true,
              formHasSubmitted: true,
            },
          });
        }
        function* onFailed(data) {
          console.log('@@512: ', data);
          yield put({
            type: 'updateState',
            payload: {
              formHasSubmitted: true,
            },
          });
        }
        yield loading(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
  },
};
