import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import i18n from '../../I18n';
import CustomEditor from '../base/CustomEditor';
import CustomTitleWithInput from '../base/CustomTitleWithInput';
import { ReactHookFormMarkDown } from '../base/CustomMarkDownArea';
import { hasError, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';


function CreateCustomerGroupGeneral() {
  const { watch, onFocus, setValue, setError, formState, clearErrors } = useFormContext();
  const { errors } = formState;

  const nameField = 'name';
  const descriptionField = 'description';
  const customerGroupName = watch(nameField);
  console.log('@18',customerGroupName);

  return (
    <>
      <div className="first-section-title">
        {i18n.t('general')}
      </div>
      <CustomTitleWithInput
        title={i18n.t('name')}
        error={{ error: hasError(errors, nameField) }}
        type={'text'}
        customClass={`text-input-field date-picker `}
        defaultValue={customerGroupName}
        setValue={(value) => {
          setValue(nameField, value, { shouldDirty: true, });
        }}
      />
      <ReactHookFormErrorMessage errors={errors} id={nameField} />
      <ReactHookFormMarkDown
        content={{
          label: i18n.t('description_optional'),
          key: descriptionField,
          focus: () => {},
        }}
      />
    </>
  );
}

export default CreateCustomerGroupGeneral;
