import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import { formatDate } from '../../utils/TimeFormatUtil';
import { PermissionCodes } from '../../config/PermissionCodes';
import CustomDateTimeSelect from '../base/CustomDateTimeSelect';
import BaseMultipleSelectorV2 from '../base/BaseMultipleSelectorV2';
import { hasError, errorMessage, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import BasePrompt from '../base/prompt/BasePrompt';

const MessageNewPromptType = {
  segments: 'segments',
  customerGroup: 'customer groups',
  none: 'none',
};

function MessageSettingSection({
  customerGroupList,
  segmentList,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [createNewType, setCreateNewType] = useState(MessageNewPromptType.none);

  const { watch, setValue, control, formState, setError, clearErrors } = useFormContext();
  const { errors } = formState;

  const targetCustomerGroup = watch('targetCustomerGroup') || [];
  const targetedSegments = watch('targetedSegments') || [];
  const scheduledDate = watch('scheduledDate');

  useEffect(() => {
    dispatch({
      type: 'customerGroup/getAllList',
      payload: { isAll: true },
    });
    dispatch({
      type: 'segments/getAllList',
      payload: {},
    });
  }, [dispatch]);

  const getCreateNewPromptContent = () => {
    let title = 'segment';
    let button = 'Segment';
    let pathname = '/segments/create/';
    if (createNewType === MessageNewPromptType.customerGroup) {
      title = 'customer group';
      button = 'Customer Group';
      pathname = '/customer_group/create';
    }
    return {
      title: `Go to create ${title} ?`,
      description: `You will leave message creation process.`,
      button: `Go to Create ${button}`,
      action: () => {
        history.push({
          pathname: pathname,
          state: {
            from: history.location,
            title: 'Continue to Create Message',
            content: 'You can continue to create the Message.',
          },
        });
      },
    };
  };

  return (
    <>
      <label className="create-section-title">SETTING</label>

      <BaseMultipleSelectorV2
        title={'Target customer groups'}
        data={{
          sourceData: customerGroupList,
          targetData: targetCustomerGroup,
          targetChange: (options) => {
            setValue('targetCustomerGroup', options, { shouldDirty: true });
          },
        }}
        addButton={{
          title: 'Add Customer Group',
          action: () => setCreateNewType(MessageNewPromptType.customerGroup),
        }}
        requires={PermissionCodes.addCustomerGroup}
        loadMoreAction={'customerGroup/getCurrentPageList'}
      />

      <BaseMultipleSelectorV2
        title={'Target segments'}
        data={{
          sourceData: segmentList,
          targetData: targetedSegments,
          targetChange: (options) => {
            setValue('targetedSegments', options, { shouldDirty: true });
          },
        }}
        addButton={{
          title: 'Add Segments',
          action: () => setCreateNewType(MessageNewPromptType.segments),
        }}
        requires={PermissionCodes.addSegment}
        loadMoreAction={'segments/getSegmentsList'}
      />

      <CustomTitleLabel title="Schedule time" />
      <CustomDateTimeSelect
        defaultTime={
          scheduledDate ? new Date(scheduledDate) : new Date()
        }
        onTimeChange={(datetime) => {
          setValue('scheduledDate', datetime, { shouldDirty: true });
        }}
        error={hasError(errors, 'scheduledDate')}
      />
      <ReactHookFormErrorMessage errors={errors} id='scheduledDate' />

      <BasePrompt
        show={createNewType !== MessageNewPromptType.none}
        closeAction={() => setCreateNewType(MessageNewPromptType.none)}
        rightButton={{
          text: getCreateNewPromptContent().button,
          action: getCreateNewPromptContent().action,
        }}
        title={getCreateNewPromptContent().title}
        description={getCreateNewPromptContent().description}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  customerGroupList: state.customerGroup.notPagedAllList,
  segmentList: state.segments.notPagedAllList,
})

export default connect(mapPropsToState)(MessageSettingSection);
