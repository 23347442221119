import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { createAction } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { NavBarNames } from '../../../config/NavBarNameList';
import DeletePrompt from '../../../components/base/DeletePrompt';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { DELETE_RELATED_SECTIONS } from '../../../config/CustomEnums';

function FeaturedCampaignList() {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    totalPage,
    totalCount,
    checkedList,
    listFields,
    dataList,
    pageInfo,
  } = useSelector((state) => ({
    pageInfo: state.campaignList.pageInfo,
    totalPage: state.campaignList.totalPage,
    totalCount: state.campaignList.totalCount,
    listFields: state.featuredCampaignList.listDisplayFields,
    dataList: state.campaignList.pagedList,
    checkedList: state.campaignList.checkedList,
  }));

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  useEffect(() => {
    return function () {
      dispatch({ type: 'campaignList/clearData' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const querySearch = queryString.parse(history.location.search);
    dispatch(
      createAction('campaignList/getCampaignList')({
        ...querySearch,
        searchKey: querySearch.search,
        is_featured: true,
      }),
    );
  }, [dispatch, history.location]);

  const updateFeaturedCampaignOrder = (pk, order) => {
    const data = {};
    if (!pk) {
      return;
    }
    data.id = pk;
    if (order) {
      data.displayPriority = order;
    }
    dispatch(
      createAction('featuredCampaignList/updateFeaturedCampaign')({
        data,
        afterAction: () => history.push(history.location),
      }),
    );
  };

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.deleteCampaign,
    },
  ];

  const tabs = [
    {
      name: 'featuredCampaign',
      content: (
        <BaseTabListContainer
          hideTab={true}
          tabs={[]}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          listContent={
            <BaseListContainer
              fields={listFields}
              dataList={dataList}
              totalPage={totalPage}
              model={'campaignList'}
              permissionGroup={PermissionCodes.campaign}
              deleteInfo={{
                data: [],
                title: 'campaign',
                relatedName: '',
                onComfirm: {},
                relatedSections: DELETE_RELATED_SECTIONS.CAMPAIGN,
              }}
              sortable
              sortDisabled={false}
              rankDisabled
              onSortEnd={(oldIndex, newIndex) => {
                if (oldIndex === newIndex) return;
                const newOrder = dataList[newIndex].order;
                const movedItem = dataList[oldIndex];
                updateFeaturedCampaignOrder(
                  movedItem.pk,
                  newOrder,
                );
              }}
              onOrderChangedByEdit={(pk, newOrder) => {
                updateFeaturedCampaignOrder(pk, newOrder);
              }}
              actions={['Edit']}
              hasOtherActions
              customActions={{
                moveToFirst: (item) => {
                  updateFeaturedCampaignOrder(item.pk, dataList[0].order);
                },
                moveToLast: (item) => {
                  updateFeaturedCampaignOrder(item.pk, dataList[totalCount-1].order);
                },
              }}
            />
          }
        />
      ),
    },
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail:
            'These are your promoting campaigns. You can drag and drop, or edit the order value to reorder the items.',
          title: NavBarNames.featuredCampaign,
        }}
        defaultActiveKey={tabs[0].name}
        tabs={tabs}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
      />
      <DeletePrompt
        data={checkedList}
        title={'campaign'}
        relatedName={''}
        relatedSections={DELETE_RELATED_SECTIONS.CAMPAIGN}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch(
            createAction('campaignList/delete')({
              afterAction: () => {
                history.push(history.location);
              },
            }),
          );
        }}
      />
    </>
  );
}
export default FeaturedCampaignList;
