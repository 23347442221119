import React, { useEffect, useState } from 'react'
import { DropDownWithDefaultSelect } from '../base/ActionsDropdown';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import { useCompare } from '../../utils';
import { useDispatch } from 'react-redux';
import { Image } from 'react-bootstrap';
import BranchLine from '../../assets/images/branchLinex3.png';

function LookupWithMultipleNumberAndMutipleDropDown({
  defaultPeriod = 'Please select',
  defaultRange = 'Please select',
  defaultLookup = 'Please select',
  defaultMin,
  defaultMax,
  rangeOptions = ['All the time', 'Within'],
  title,
  dropDownName = `${title}-lookup`,
  hasPeriodDropdown = true,
  groupName,
  conditionIndex,
}) {
  const lookups = [
    'Equal to',
    'Not equal to',
    'Is empty',
    'Is not empty',
    'Between',
    'Less than',
    'Greater than',
  ];
  const minName = dropDownName || `${title}-minNum`;
  const maxName = dropDownName || `${title}-maxNum`;
  const [lookup, setLookup] = useState(defaultLookup);

  const getInputNumberCount = (lookup) => {
    let showInputCount = 1;
    if (lookup === 'Please select' ||
      lookup.toLowerCase().indexOf('empty') >= 0) {
      showInputCount = 0;
    } else if (lookup.toLowerCase() === 'between') {
      showInputCount = 2;
    }
    return showInputCount;
  };

  const [showInputNumber, setShowInputNumber] = useState(
    getInputNumberCount(lookup),
  );
  const [minNum, setMinNum] = useState(defaultMin);
  const [maxNum, setMaxNum] = useState(defaultMax);

  const rangePeriodOptions = ['1 day', '7 days', '1 month', '3 months', '6 months', '1 year'];
  const [periodRange, setPeriodRange] = useState(
    rangeOptions.length === 1 ? rangeOptions[0] : defaultRange,
  );
  const [periodDays, setPeriodDays] = useState(defaultPeriod);
  const [showPeriodDays, setShowPeriodDays] = useState(
    defaultRange === 'Within',
  );
  
  const headDropDownOptions = lookups.map((lookup) => ({
    name: lookup,
    action: () => {
      setLookup(lookup);
      setShowInputNumber(getInputNumberCount(lookup));
    },
  }));

  const tailDropDownOptions = (values, setAction) => {
    return values.map((value) => ({
      name: value,
      action: () => {
        setAction(value);
        values === rangeOptions && setShowPeriodDays(value === 'Within');
      },
    }));
  };

  const dispatch = useDispatch();
  const vals = { lookup, minNum, maxNum, periodRange, periodDays };
  const hasValsChanged = useCompare(vals);
  useEffect(() => {
    if (hasValsChanged) {
      dispatch({
        type: 'segments/changeVals',
        payload: {
          vals,
          groupName,
          conditionIndex,
        },
      });
    }
  });

  return (
    <div>
      {title ? <CustomTitleLabel title={title} /> : null}
      <div className='d-flex'>
        <DropDownWithDefaultSelect
          name={dropDownName}
          actions={headDropDownOptions}
          defaultValue={lookup}
        />

        <input
          name={minName}
          type="number"
          onChange={({ target }) => setMinNum(target.value)}
          className="custom-number-input-short"
          value={minNum}
          onFocus={() => { }}
          hidden={showInputNumber < 1}
        />
        <label
          className="help-message margin-left-10-percent margin-right-10-percent"
          hidden={showInputNumber < 2}
        >
          and
        </label>
        <input
          name={maxName}
          type="number"
          onChange={({ target }) => setMaxNum(target.value)}
          className="custom-number-input-short"
          value={maxNum}
          onFocus={() => { }}
          hidden={showInputNumber < 2}
        />
      </div>

      <div className="display-flex-area">
        <Image src={BranchLine} className="content-campaign-branchLine" />
        <div className="d-flex flex-column">
          <CustomTitleLabel title={'Time period'} />
          <div className='d-flex'>
            {rangeOptions.length === 1 ? (
              <label className="help-message margin-right-10-percent">
                {rangeOptions[0]}
              </label>
            ) : (
              <DropDownWithDefaultSelect
                actions={tailDropDownOptions(rangeOptions, setPeriodRange)}
                defaultValue={periodRange}
              />
            )}
            {showPeriodDays && hasPeriodDropdown ? (
              <DropDownWithDefaultSelect
                actions={tailDropDownOptions(rangePeriodOptions, setPeriodDays)}
                defaultValue={periodDays}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LookupWithMultipleNumberAndMutipleDropDown