import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import BaseEditSection from '../../containers/base/BaseEditSection';
import BaseMultipleSelectorV2 from '../base/BaseMultipleSelectorV2';
import './TargetCustomerSection.scss';
import { Button, Image, Spinner } from 'react-bootstrap';
import deleteImage from '../../assets/images/earning/deletex1.png';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';

function TargetCustomerSection({
  customerList,
  importedFile,
}) {
  const dispatch = useDispatch();
  const { watch, getValues, setValue, setError, formState, clearErrors } =
    useFormContext();

  const hiddenFileInput = useRef();
  const [fileName, setFileName] = useState();
  const [loading, setLoading] = useState(false);

  const customersField = 'customers';
  const selectedCustomerList = watch(customersField);

  const handleImportClick = (event) => {
    hiddenFileInput.current.click();
    console.log('@@22');
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setLoading(true);
    setFileName(fileUploaded.name);
    // console.log('@@37',fileUploaded.name);
    dispatch({
      type: 'uploadFiles/uploadFile',
      payload: {
        file: fileUploaded,
        source: event,
      }
    });
    dispatch({
      type: 'uploadFiles/updateState',
      payload: {
        importFileOriginName: fileUploaded.name,
      }
    });
    
    event.target.value = null;
  };

  useEffect(() => {
    setLoading(false);
  }, [importedFile])

  const optionList = (list) => {
    if (!list) {
      return [];
    }
    return list.map((item) => {
      const currentLevel = item.pointAccount?.currentLevel;
      return {
        ...item,
        pk: item.pk,
        name: item.name,
        tagKey: {
          pk: currentLevel?.pk,
          id: currentLevel?.id,
          name: currentLevel?.levelName,
          levelName: currentLevel?.levelName,
        },
      };
    });
  };

  useEffect(() => {
    dispatch({
      type: 'customerList/getAllCustomers',
      payload: {},
    });
    dispatch({
      type: 'uploadFiles/updateState',
      payload: { importedFile: null },
    });
  }, [dispatch]);

  return (
    <div className="create-customer-group-target">
      <BaseEditSection
        title="TARGET CUSTOMER"
        fields={[
          <div className='d-flex'>
            <Button
              variant="primary"
              disabled={loading}
              onClick={handleImportClick}
              className='mt-4 btn-further'
            >
              {loading ?
                <Spinner
                  animation="border"
                  size="sm"
                /> :
                'Import .csv'}
            </Button>
            {importedFile ?
              <>
                <CustomTitleLabel
                  className='ml-5'
                  title={fileName}
                />
                <button
                  className="custom-image-button"
                  onClick={() => {
                    setFileName();
                    dispatch({
                      type: 'uploadFiles/updateState',
                      payload: { importedFile: null },
                    });
                  }}
                >
                  <Image style={{transform: 'translate(0,50%)'}} src={deleteImage} />
                </button>
              </>
              : null
            }
          </div>
          ,
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleChange}
            className='d-none'
            accept=".csv" />,
          <BaseMultipleSelectorV2
            title={'Customer'}
            namespace="customerList"
            data={{
              sourceData: optionList(customerList),
              targetData: optionList(selectedCustomerList),
              targetChange: (options) => {
                setValue(customersField, options, { shouldDirty: true });
              },
            }}
            groupKey="tagKey" />,
        ]}
      />

      {/* <BaseMutipleSelectorV2
          title={"TARGET CUSTOMER"}
          namespace={"customerList"}
          data={{
            sourceData: optionList(customerList),
            targetData: optionList(selectedCustomerList),
            targetChange: (value) => {
              setValue(customersField, options, { shouldDirty: true, });
            },
          }}
          addButton={config.addButton}
          error={{
            error: hasError(errors, config.errorIdKey),
            id: config.errorIdKey,
            message: errorMessage(errors, config.errorIdKey),
          }}
          requires={config.requires}
          groupKey={config.groupKey}
          custom={config.custom}
          searchPlaceholder={config.searchPlaceholder}
        /> */}
    </div>
  );
}

const mapPropsToState = (state) => ({
  customerList: state.customerList.customerList,
  importedFile: state.uploadFiles.importedFile,
});

export default connect(mapPropsToState)(TargetCustomerSection);
