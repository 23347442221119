import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import BaseForm from '../../../components/base/v2/BaseForm';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CreateTransactionStepOne from '../../../components/transactions/CreateTransactionStepOne';
import CreateTransactionStepTwo from '../../../components/transactions/CreateTransactionStepTwo';
import CreateTransactionStepThree from '../../../components/transactions/CreateTransactionStepThree';

function CreateTransaction({
  stepConfig,
  currentStep,
  transaction,
  transactionStatusChanged,
  hasUpdatedDefaultValues,
  formHasSubmitted,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const history = useHistory();
  // console.log('@@26: currentStep', currentStep);

  useEffect(() => {
    dispatch({
      type: 'createTransaction/clearData',
      payload: {},
    });
    if (location.pathname.includes('edit')) {
      const transactionPK = params.id;
      dispatch({
        type: 'transactions/getTransaction',
        payload: {
          transactionPK: transactionPK,
          afterActions: () =>
            dispatch({
              type: 'createTransaction/stepChange',
              payload: {
                step: 0,
                isValid: true,
              },
            }),
        },
      });
    } else {
      dispatch({
        type: 'createTransaction/loadTransactionFromCookie',
        payload: {},
      });
    }
  }, [dispatch, location.pathname, params.id]);

  const toTransactionList = () => {
    history.push('/transactions');
  };

  useEffect(() => {
    return function () {
      dispatch({
        type: 'createTransaction/clearData',
        payload: {},
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (transactionStatusChanged && location.pathname.includes('edit')) {
      toTransactionList();
    }
    console.log('@@53: ', transactionStatusChanged);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, transactionStatusChanged]);

  useEffect(() => {
    return () => {
      dispatch({
        type: 'createTransaction/clearData',
        payload: {},
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stepContent = [
    <CreateTransactionStepOne />,
    <CreateTransactionStepTwo />,
  ];
  const caution = {
    detail: '', //TODO: change this wording Create Transaction
    title: `${params.id ? 'Edit Transaction' : 'Create Transaction'}`,
  };
  return (
    <div className="customer">
      <BaseForm
        defaultValues={transaction}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={formHasSubmitted}
        tempSave={(save, getValues) => {
          dispatch({
            type: 'createTransaction/saveOrRemoveTransactionFromCookie',
            payload: {
              save: save,
              values: getValues(),
            },
          });
        }}
        showFinishPop={transactionStatusChanged}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Transaction is successfully created.',
          steps: null,
          buttons: [
            {
              text: 'Back to transaction list',
              action: toTransactionList,
            },
          ],
        }}
        content={stepContent}
        currentStep={currentStep}
        stepsConfig={stepConfig}
        breadcrumb={<CustomBreadcrumb />}
        caution={caution}
      />
    </div>
  );
}

const mapPropsToState = (state) => ({
  stepConfig: state.createTransaction.stepConfig,
  currentStep: state.createTransaction.currentStep,
  transaction: state.createTransaction.transaction,
  transactionStatusChanged: state.createTransaction.transactionStatusChanged,
  hasUpdatedDefaultValues: state.createTransaction.hasUpdatedDefaultValues,
  formHasSubmitted: state.createTransaction.formHasSubmitted,
});

export default connect(mapPropsToState)(CreateTransaction);
