import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import '../campaign/campaignCreation/PropertiesGeneralSection.scss';
import './RewardSection.scss';
import CustomSwitchButton from '../base/CustomSwitchButton';
import BasePrompt from '../base/prompt/BasePrompt';
import {
  CustomTitleLabel,
  CustomNumberInputWithUnit,
} from './CustomBaseComponments';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { EarningRuleErrorHandleField } from './EarningRuleHandleError';
import {
  isShowError,
  firstError,
} from '../campaign/campaignCreation/CreateCampaignHandleError';
import { createAction, useCompare } from '../../utils';
import { PermissionCodes } from '../../config/PermissionCodes';
import CustomAddRemoveMutipleSelector from '../customer/CustomAddRemoveMutipleSelector';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import InputFieldControl from '../base/InputFieldControl';
import BaseMultipleSelectorV2 from '../base/BaseMultipleSelectorV2';
import LimitSection from './LimitSection';
const options = (source) => {
  if (!source) {
    return [];
  }
  return source.map((item) => {
    return {
      name: item.storeName,
      value: item.storePK,
    };
  });
};

function GeneralPurchaseSection({ storeList, skuFakeData }) {
  const { watch, formState, setValue } = useFormContext();
  const errors = formState.errors;

  const minSpending = watch('minSpending');
  const maxSpending = watch('maxSpending');
  const selectedStores = watch('selectedStores');
  const specialSkus = watch('specialSkus');
  const eligibleDays = watch('eligibleDays');
  const isExcludeDeliveryCost = watch('isExcludeDeliveryCost') || false;
  const isExcludeOtherCharges = watch('isExcludeOtherCharges') || false;

  const dispatch = useDispatch();
  const [showCreateStore, setShowCreateStore] = useState(false);
  const history = useHistory();

  useEffect(() => {
    dispatch(createAction('storeModel/getPagedStoreList')());
    // dispatch(createAction('bannerList/getProductList')());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      <label className="create-section-title">GENERAL PURCHASE</label>
      <InputFieldControl
        name={'minSpending'}
        title={'Minimum spending'}
        value={minSpending}
        setValue={(value) => {
          setValue('minSpending', value, { shouldDirty: true });
        }}
        type="number"
        per="hours"
        className="coupon-campaign-section-input"
      />
      <InputFieldControl
        name={'maxSpending'}
        title={'Maximum spending (optional)'}
        value={maxSpending}
        setValue={(value) => {
          setValue('maxSpending', value, { shouldDirty: true });
        }}
        type="number"
        per="hours"
        className="coupon-campaign-section-input"
      />
      {/* <BaseMultipleSelectorV2
        title={'Limited to store (optional)'}
        namespace="storeModel"
        data={{
          sourceData: storeList,
          targetData: selectedStores,
          targetChange: (value) => {
            setValue('selectedStores', value, { shouldDirty: true });
          },
        }}
        addButton={{
          title: 'Add Store',
          action: () => {
            setShowCreateStore(true);
          },
          requires: PermissionCodes.addStore,
        }}
        loadMoreAction={'storeModel/getPagedStoreList'}
        requires={PermissionCodes.addStore}
      /> */}
      {/* <BaseMultipleSelectorV2 
        title={'Special SKU (optional)'}
        data={{
          sourceData: skuFakeData,
          targetData: specialSkus,
          targetChange: (value) => {
            setValue('specialSkus', value, {shouldDirty: true})
          },
        }}
        groupKey="category"
      /> */}
      <InputFieldControl
        name={'eligibleDays'}
        title={'Eligible number of days since purchase (optional)'}
        value={eligibleDays}
        setValue={(value) => {
          setValue('eligibleDays', value, { shouldDirty: true });
        }}
        type="number"
        unit="days"
        className="coupon-campaign-section-input"
      />

      {/* <CustomAddRemoveMutipleSelector
        title={'Special SKU (optional)'}
        data={skuFakeData}
        selectedList={specialSkus}
        // selectAllTitle={'All subcategories'}
        // sourceReminderEnabelSelectAll

        setValue={setSpecialSkus}
        sourceReminderText={'All item(s)'}
        amidReminderText={'Selectes item(s)'}
        // loadMoreAction={'storeCategoryList/getAllStoreSubcategoryList'}
        groupKey="category"
      />

      <CustomNumberInputWithUnit
        title="Eligible number of days since purchase (optional)"
        setValue={setEligibleDays}
        unit="days"
        defaultValue={eligibleDays}
      /> */}

      <CustomTitleLabel title="Will exclude delivery cost" />
      <CustomSwitchButton
        defaultChecked={isExcludeDeliveryCost}
        onChange={(isChecked) => {
          setValue('isExcludeDeliveryCost', isChecked, { shouldDirty: true });
        }}
      />

      <CustomTitleLabel title="Will exclude other charges" />
      <CustomSwitchButton
        defaultChecked={isExcludeOtherCharges}
        onChange={(isChecked) => {
          setValue('isExcludeOtherCharges', isChecked, { shouldDirty: true });
        }}
      />
      <LimitSection
        tips={{
          overallLimit: 'Maximum number of times to do purchase per campaign.',
          periodicLimit: 'Maximum number of times to do purchase per period.',
          perHeadLimit: 'Maximum number of times to do purchase per customer.',
          perHeadPeriodicLimit:
            'Maximum number of times to do purchase per customer per period.',
        }}
      />
      <BasePrompt
        show={showCreateStore}
        closeAction={() => setShowCreateStore(false)}
        rightButton={{
          text: 'create store',
          action: () =>
            history.push({
              pathname: '/stores/create',
              state: {
                from: history.location,
                title: 'Continue to Create Earning Rule',
                content: 'You can continue to create the earning rule.',
              },
            }),
        }}
        title={'Go to create store?'}
        description={'Go to create store'}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  storeList: state.storeModel.pagedStoreList,
  skuFakeData: state.bannerList.skusWithCategoryList,
});

export default connect(mapPropsToState)(GeneralPurchaseSection);
