export const PermissionCodes = {
  administratorgroup: 'administratorgroup',
  administrator: 'administrator',

  brand: 'brand',
  store: 'store',
  storecategory: 'storecategory',

  campaign: 'campaign',
  campaigncategory: 'campaigncategory',
  coupontemplate: 'coupontemplate',
  coupon: 'coupon',
  earningrule: 'earningrule',
  message: 'message',

  customer: 'customer',
  customergroup: 'customergroup',
  segment: 'segment',

  pointtransaction: 'pointtransaction',
  transaction: 'transaction',
  coupontransaction: 'coupontransaction',

  addAdministratorGroup: 'add_administratorgroup',
  changeAdministratorGroup: 'change_administratorgroup',
  deleteAdministratorGroup: 'delete_administratorgroup',
  // view_administratorgroup
  addAdministrator: 'add_administrator',
  changeAdministrator: 'change_administrator',
  deleteAdministrator: 'delete_administrator',
  viewAdministrator: 'view_administrator',

  //   addCustomer: 'add_customer',
  changeCustomer: 'change_customer',
  deleteCustomer: 'delete_customer',
  viewCustomer: 'view_customer',

  addSegment: 'add_segment',
  changeSegment: 'change_segment',
  deleteSegment: 'delete_segment',
  viewSegment: 'view_segment',

  addCustomerGroup: 'add_customergroup',
  changeCustomerGroup: 'change_customergroup',
  deleteCustomerGroup: 'delete_customergroup',
  // viewCustomerGroup: 'view_customergroup',

  addCampaign: 'add_campaign',
  changeCampaign: 'change_campaign',
  deleteCampaign: 'delete_campaign',
  viewCampaign: 'view_campaign',

  addCampaignCategory: 'add_campaigncategory',
  changeCampaignCategory: 'change_campaigncategory',
  deleteCampaignCategory: 'delete_campaigncategory',
  viewCampaignCategory: 'view_campaigncategory',

  addEarningRule: 'add_earningrule',
  changeEarningRule: 'change_earningrule',
  deleteEarningRule: 'delete_earningrule',
  // viewEarningRule: 'view_earningrule',

  addMessage: 'add_message',
  changeMessage: 'change_message',
  deleteMessage: 'delete_message',
  viewMessage: 'view_message',

  addCouponTemplate: 'add_coupontemplate',
  changeCouponTemplate: 'change_coupontemplate',
  deleteCouponTemplate: 'delete_coupontemplate',
  viewCouponTemplate: 'view_coupontemplate',
  addCoupon: 'add_coupon',
  changeCoupon: 'change_coupon',
  deleteCoupon: 'delete_coupon',
  viewCoupon: 'view_coupon',

  addBrand: 'add_brand',
  changeBrand: 'change_brand',
  deleteBrand: 'delete_brand',
  //   viewBrand: 'view_brand',

  addStore: 'add_store',
  changeStore: 'change_store',
  deleteStore: 'delete_store',
  viewStore: 'view_store',

  addStorecategory: 'add_storecategory',
  changeStorecategory: 'change_storecategory',
  deleteStorecategory: 'delete_storecategory',
  viewSorecategory: 'view_storecategory',

  addTransaction: 'add_transaction',
  changeTransaction: 'change_transaction',
  deleteTransaction: 'delete_transaction',
  viewTransaction: 'view_transaction',

  //   addCouponTransaction: 'add_coupontransaction',
  //   changeCouponTransaction: 'change_coupontransaction',
  //   deleteCouponTransaction: 'delete_coupontransaction',
  viewCouponTransaction: 'view_coupontransaction',

  addPointtransaction: 'add_pointtransaction',
  viewPointtransaction: 'view_pointtransaction',
  changePointtransaction: 'change_pointtransaction',

  publishCampaign: 'publish_campaign',
  unPublishCampaign: 'unpublish_campaign',

  addExportjob: 'add_exportjob',
  addImportjob: 'add_importjob',

  // addCustomerPoints: 'add_customer_points',
  // removeCustomerPoints: 'remove_customer_points',
  addCustomerPoints: 'add_points',
  removeCustomerPoints: 'remove_points',
  testMessage: 'test_message',

  // home manage

  banners: 'homepagebanner',
  viewBanners: 'view_homepagebanner',
  changeBanner: 'change_homepagebanner',

  language: 'language',
  viewLanguage: 'view_language',
  changeLanguage: 'change_language',
};
