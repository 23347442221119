import React, { useState, useEffect } from 'react';
import { Button, Image, Dropdown } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import '../../utils/BtnCommonTheme.scss';
import './Segment.scss';
import DropdownToMutipleSelector from './DropdownToMutipleSelector';
import LookupWithMutipleNumber from './LookupWithMutipleNumber';
import { getObjectFromSessionStorage, useCompare } from '../../utils';
import {
  CustomNumberInput,
  CustomTitleLabel,
  CustomNumberInputWithUnit,
} from '../earning/CustomBaseComponments';
import NarrowUp from '../../assets/images/drop_down_not_show_narrow.svg';
import Search from '../../assets/images/drop_down_filter.svg';
import DeleteIcon from '../../assets/images/deleteIcon.png';
import {
  GenderOptions,
  MonthOptions,
  CountryCodeOptions,
} from '../../config/CustomEnums';
import InputWithMutipleDropDown from './InputWithMutipleDropDown';
import { isShowError } from '../../utils';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import CustomSwitchButton from '../base/CustomSwitchButton';
import CampaignBehavioralSection from './CampaignBehavioralSection';
import ProductSelector from './ProductSelector';
import BasePrompt from '../base/prompt/BasePrompt';
import LookupWithMultipleNumberAndMutipleDropDown from './LookupWithMultipleNumberAndMutipleDropDown';

const contentEnum = {
  gender: 'Gender',
  birthday: 'Birthday',
  age: 'Age',
  countryCode: 'Country code',
  signupAnniversary: 'Signup anniversary (in year)',
  level: 'Level',
  lastPurchase: 'Last purchase',
  numberOfTransaction: 'Number of transaction',
  totalSpending: 'Total spending',
  averageTransaction: 'Average transaction value',
  newMember: 'Sign up member',
  newSignupWithReferral: 'Sign up member via referral',
  numberOfLogin: 'Number of login',
  lastLogin: 'Last login',
  campaignViewed: 'Campaign viewed',
  buyCoupon: 'Buy coupon',
  earnReward: 'Earn reward by campaign',
  // products: 'Special product',
  numberOfAccumulatedPoints: 'Number of accumulated points',

};

const contentGroupTitleEnum = {
  genderCharacteristic: 'Gender',
  monthOfBirthCharacteristic: 'Birthday',
  ageCharacteristic: 'Age',
  countryCodeCharacteristic: 'Country code',
  signUpAnniversaryCharacteristic: 'Signup anniversary (in year)',
  levelCharacteristic: 'Level',
  lastPurchase: 'Last purchase',
  numberOfTransactionsCharacteristic: 'Number of transaction',
  totalSpendingCharacteristic: 'Total spending',
  averageTransactionValueCharacteristic: 'Average transaction value',
  signUpMemberCharacteristic: 'Sign up member',
  isSignupReferral: 'Sign up member via referral',
  numberOfLogin: 'Number of login',
  lastLoginCharacteristic: 'Last login',
  viewCampaignCharacteristic: 'Campaign viewed',
  couponCampaignCharacteristic: 'Buy coupon',
  earningCampaignCharacteristic: 'Earn reward by campaign',
// productCharacteristic: 'Special product',
  numberOfAccumulatedPointsCharacteristic: 'Number of accumulated points',
};

const contentGroupEnum = {
  Gender: 'genderCharacteristic',
  Birthday: 'monthOfBirthCharacteristic',
  Age: 'ageCharacteristic',
  'Country code': 'countryCodeCharacteristic',
  'Signup anniversary (in year)': 'signUpAnniversaryCharacteristic',
  Level: 'levelCharacteristic',
  'Last purchase': 'hasRecentPurchaseCharacteristic',
  'Number of transaction': 'numberOfTransactionsCharacteristic',
  'Total spending': 'totalSpendingCharacteristic',
  'Average transaction value': 'averageTransactionValueCharacteristic',
  'Sign up member': 'signUpMemberCharacteristic',
  'Sign up member via referral': 'signUpReferralCharacteristic',
  'Number of login': 'loginTimeCharacteristic',
  'Last login': 'lastLoginCharacteristic',
  'Campaign viewed': 'viewCampaignCharacteristic',
  'Buy coupon': 'couponCampaignCharacteristic',
  'Earn reward by campaign': 'earningCampaignCharacteristic',
  // 'Special product': 'productCharacteristic',
  'Number of accumulated points': 'numberOfAccumulatedPointsCharacteristic',
};

const contentSections = {
  Demographic: [
    contentEnum.gender,
    contentEnum.birthday,
    contentEnum.age,
    contentEnum.countryCode,
    contentEnum.signupAnniversary,
  ],
  Behavioral: [
    contentEnum.level,
    contentEnum.lastPurchase,
    contentEnum.numberOfTransaction,
    contentEnum.totalSpending,
    contentEnum.averageTransaction,
    contentEnum.newMember,
    contentEnum.newSignupWithReferral,
    contentEnum.numberOfLogin,
    contentEnum.lastLogin,
    contentEnum.campaignViewed,
    contentEnum.buyCoupon,
    contentEnum.earnReward,
    // contentEnum.products,
    contentEnum.numberOfAccumulatedPoints,
  ],
};

function ContentFilterDropDown(props) {
  const sectionSelectAction = props.addContent || (() => {});
  const settedParams = props.settedParams || [];

  const [isOpen, setIsOpen] = useState({
    Demographic: false,
    Behavioral: false,
  });
  const openNarrow = (section) => {
    setIsOpen({ ...isOpen, [section]: !isOpen[section] });
  };
  const [contentSelections, setContentSelections] = useState(contentSections);
  const [filterKey, setFilterKey] = useState('');
  const [selectedSections, setSelectedSections] = useState(settedParams);

  useEffect(() => {
    setSelectedSections(props.settedParams);
  }, [props.settedParams]);

  useEffect(() => {
    setFilterKey('');
    setContentSelections(contentSections);
  }, [props.show]);

  return (
    <div className="content-add-button-selection-area">
      {/* <input className="content-add-button-selection-filter" /> */}
      <div className="filterable-search-bar content-add-button-selection-filter">
        <Image
          src={Search}
          className="filterable-search-bar-icon search_bar_icon"
        />
        <input
          autoFocus
          className="filterable-search-bar-input"
          placeholder="Search for..."
          onChange={(e) => {
            const value = e.target.value;
            setFilterKey(value);

            let filteredResult = {};
            Object.keys(contentSections).map(
              (key) =>
              (filteredResult[key] = contentSections[key].filter(
                (val) => val.toLowerCase().indexOf(value.toLowerCase()) >= 0,
              )),
            );
            setContentSelections(filteredResult);
          }}
          value={filterKey}
        />
      </div>
      <div className="content-add-button-selection-section-area">
        {Object.keys(contentSelections).map((section) => {
          return (
            <>
              <ul className="content-add-button-selection-section">
                <label>{section}</label>
                <button
                  className="reset-button margin-left-auto"
                  onClick={() => openNarrow(section)}
                >
                  <Image
                    src={NarrowUp}
                    className={`filterable-toogle-area-indicator ${
                      isOpen[section] ? 'rtoate180' : ''
                      }`}
                  />
                </button>
              </ul>

              {isOpen[section]
                ? contentSelections[section].map((val, index) => {
                  return selectedSections.includes(val) ? null : (
                    <Dropdown.Item
                      onClick={() => {
                        sectionSelectAction(val);
                        setSelectedSections([...selectedSections, val]);
                      }}
                    >
                      <div className="display-flex-area">
                        <div className="li-icon" />
                        <label className="li-label">{val}</label>
                      </div>
                    </Dropdown.Item>
                    // <div
                    //   className="display-flex-area drop-item"
                    //   onClick={() => {
                    //     sectionSelectAction(val);
                    //     setSelectedSections([...selectedSections, val]);
                    //   }}
                    //   role="button"
                    // >
                    //   <div className="li-icon" />
                    //   <label className="li-label">{val}</label>
                    // </div>
                  );
                })
                : null}
            </>
          );
        })}
      </div>
    </div>
  );
}

function ContentItemSection({
  addNewAction,
  index,
  levels,
  segment,
  createErrorDict,
  tempSegmentConditionGroup,
}) {
  const addNewItemAction = addNewAction || (() => {});
  const itemIndex = index || 0;
  const conditionIndex = itemIndex + 1;
  const conditionGroup = tempSegmentConditionGroup?.[conditionIndex] || {};
  // const segment = getObjectFromSessionStorage('tempSegment')
  //   ? getObjectFromSessionStorage('tempSegment')[conditionIndex] || {}
  //   : {};

  // console.log('@@209: ', segment);

  const [isReset, setIsReset] = useState(false);
  const [showResetPrompt, setShowResetPrompt] = useState(false);
  const [isShowDropdown, setIsShowDropdown] = useState(false);
  // const [conditionGroup, setConditionGroup] = useState(() => {
  //   console.log("@@@init@@");
  //   return {};
  // })

  const isCreate = !segment || Object.keys(segment).length <= 0 || !segment.pk;

  const dispatch = useDispatch();
  const [lastPurchase, setLastPurchase] = useState();
  // const [products, setProducts] = useState();
  const [numberOfLogin, setNumberOfLogin] = useState();
  const [isSignupReferral, setIsSignupReferral] = useState();

  const vals = { lastPurchase, numberOfLogin, isSignupReferral };
  const hasValsChanged = useCompare(vals);

  useEffect(() => {
    if (hasValsChanged) {
      dispatch({
        type: 'segments/changeVals',
        payload: {
          vals,
          conditionIndex: conditionIndex,
        },
      });
    }
  });

  const getDropdownToMutipleSelector = (groupName, title, options) => {
    return (
      <DropdownToMutipleSelector
        groupName={groupName}
        conditionIndex={conditionIndex}
        title={title}
        dropDown={{
          defaultValue: conditionGroup[groupName]?.lookup,
        }}
        mutipleSelector={{
          defaultValue: conditionGroup[groupName]?.selected,
          options,
        }}
        isReset={isReset}
        changeReset={setIsReset}
        isCreate={isCreate}
      />
    );
  };

  const getLookupWithMutipleNumber = (groupName, title) => {
    // console.log('@@273: ', sessionSegment[conditionIndex]);
    return (
      <LookupWithMutipleNumber
        conditionIndex={conditionIndex}
        groupName={groupName}
        title={title}
        defaultLookup={conditionGroup[groupName]?.lookup}
        defaultMin={conditionGroup[groupName]?.minNum}
        defaultMax={conditionGroup[groupName]?.maxNum}
        isReset={isReset}
        changeReset={setIsReset}
        isCreate={isCreate}
      />
    );
  };

  const getTimePeriodInput = (groupName, title, hasNumber, rangeOptions) => {
    return (
      <InputWithMutipleDropDown
        conditionIndex={conditionIndex}
        groupName={groupName}
        title={title}
        hasNumber={hasNumber}
        rangeOptions={rangeOptions}
        defaultNumber={conditionGroup[groupName]?.number}
        defaultRange={conditionGroup[groupName]?.periodRange}
        defaultPeriod={conditionGroup[groupName]?.periodDays}
        isReset={isReset}
        changeReset={setIsReset}
        isCreate={isCreate}
        hasPeriodDropdown
      />
    );
  };

  const getCustomTitleWithSwitch = (
    groupName,
    title,
    defaultValue,
    setValue,
    tips,
  ) => {
    return (
      <div className="d-flex flex-column">
        <CustomTitleLabel title={title} />
        <CustomSwitchButton
          defaultChecked={defaultValue}
          onChange={(isChecked) => {
            setValue(isChecked);
          }}
        />
        {tips ? <label className="tips-message">{tips}</label> : null}
      </div>
    );
  };

  const getCampaignView = (groupName, title) => {
    return (
      <CampaignBehavioralSection
        conditionIndex={conditionIndex}
        groupName={groupName}
        title={title}
        defaultValue={{
          viewType: conditionGroup[groupName]?.viewType,
          viewValue: conditionGroup[groupName]?.viewValue,
          viewedTimeType: conditionGroup[groupName]?.viewedTimeType,
          viewTimes: conditionGroup[groupName]?.viewTimes,
          number: conditionGroup[groupName]?.number,
          periodRange: conditionGroup[groupName]?.periodRange,
          periodDays: conditionGroup[groupName]?.periodDays,
        }}
      />
    );
  };

  const getTimePeriodLookupWithMultipleNumber = (groupName, title, rangeOptions) => {
    console.log('@376',conditionGroup[groupName]);
    return (
      <LookupWithMultipleNumberAndMutipleDropDown
        conditionIndex={conditionIndex}
        groupName={groupName}
        title={title}
        rangeOptions={rangeOptions}
        defaultLookup={conditionGroup[groupName]?.lookup}
        defaultMin={conditionGroup[groupName]?.minNum}
        defaultMax={conditionGroup[groupName]?.maxNum}
        defaultRange={conditionGroup[groupName]?.periodRange}
        defaultPeriod={conditionGroup[groupName]?.periodDays}
      />

    );
  };

  const contentSectionComponment = {
    [contentEnum.gender]: getDropdownToMutipleSelector(
      contentGroupEnum[contentEnum.gender],
      contentEnum.gender,
      GenderOptions,
    ),
    [contentEnum.birthday]: getDropdownToMutipleSelector(
      contentGroupEnum[contentEnum.birthday],
      contentEnum.birthday,
      MonthOptions,
    ),
    [contentEnum.age]: getLookupWithMutipleNumber(
      contentGroupEnum[contentEnum.age],
      contentEnum.age,
    ),
    [contentEnum.countryCode]: getDropdownToMutipleSelector(
      contentGroupEnum[contentEnum.countryCode],
      contentEnum.countryCode,
      CountryCodeOptions,
    ),
    [contentEnum.signupAnniversary]: getLookupWithMutipleNumber(
      contentGroupEnum[contentEnum.signupAnniversary],
      contentEnum.signupAnniversary,
    ),

    [contentEnum.level]: getDropdownToMutipleSelector(
      contentGroupEnum[contentEnum.level],
      contentEnum.level,
      levels,
    ),
    [contentEnum.lastPurchase]: (
      <CustomNumberInput
        title="Last purchase within X number of days"
        setValue={setLastPurchase}
        defaultValue={conditionGroup.lastPurchase}
      />
    ),
    [contentEnum.numberOfTransaction]: getTimePeriodInput(
      contentGroupEnum[contentEnum.numberOfTransaction],
      contentEnum.numberOfTransaction,
      true,
      null,
    ),
    [contentEnum.totalSpending]: getLookupWithMutipleNumber(
      contentGroupEnum[contentEnum.totalSpending],
      contentEnum.totalSpending,
    ),
    [contentEnum.averageTransaction]: getLookupWithMutipleNumber(
      contentGroupEnum[contentEnum.averageTransaction],
      contentEnum.averageTransaction,
    ),
    [contentEnum.newMember]: getTimePeriodInput(
      contentGroupEnum[contentEnum.newMember],
      contentEnum.newMember,
      false,
      ['Within'],
    ),
    [contentEnum.newSignupWithReferral]: getCustomTitleWithSwitch(
      contentGroupEnum[contentEnum.newSignupWithReferral],
      contentEnum.newSignupWithReferral,
      conditionGroup.isSignupReferral,
      setIsSignupReferral,
    ),
    [contentEnum.numberOfLogin]: (
      <CustomNumberInputWithUnit
        title={'Number of login'}
        unit={'times'}
        setValue={setNumberOfLogin}
        defaultValue={conditionGroup.numberOfLogin}
      />
    ),
    [contentEnum.lastLogin]: getTimePeriodInput(
      contentGroupEnum[contentEnum.lastLogin],
      contentEnum.lastLogin,
      false,
      null,
    ),
    [contentEnum.campaignViewed]: getCampaignView(
      contentGroupEnum[contentEnum.campaignViewed],
      contentEnum.campaignViewed,
    ),
    [contentEnum.buyCoupon]: getCampaignView(
      contentGroupEnum[contentEnum.buyCoupon],
      contentEnum.buyCoupon,
    ),
    [contentEnum.earnReward]: getCampaignView(
      contentGroupEnum[contentEnum.earnReward],
      contentEnum.earnReward,
    ),
    // [contentEnum.productSku]: (
    //   <CustomNumberInput
    //     title={contentEnum.productSku}
    //     setValue={setProductSku}
    //     defaultValue={conditionGroup.productSku}
    //   />
    // ),
    [contentEnum.products]: (
      <ProductSelector
        groupName={contentGroupEnum[contentEnum.products]}
        title={contentEnum.products}
        conditionIndex={conditionIndex}
        defaultValue={
          conditionGroup[contentGroupEnum[contentEnum.products]]?.selected
        }
        isReset={isReset}
        changeReset={setIsReset}
        isCreate={isCreate}
      />
    ),
    [contentEnum.numberOfAccumulatedPoints]: getTimePeriodLookupWithMultipleNumber(
      contentGroupEnum[contentEnum.numberOfAccumulatedPoints],
      contentEnum.numberOfAccumulatedPoints,
    ),
  };

  const [content, setContent] = useState([]);
  const setContentWithoutNoComponment = () => {
    setContent(() => {
      const contentList = Object.keys(conditionGroup).map((key) => {
        if (!contentGroupTitleEnum[key] || conditionGroup[key] === undefined) {
          return null;
        }

        if (conditionGroup.lastPurchase) {
          setLastPurchase(conditionGroup.lastPurchase);
        }
        // if (conditionGroup.productSku) {
        //   setProductSku(conditionGroup.productSku);
        // }
        if (conditionGroup.numberOfLogin) {
          setNumberOfLogin(conditionGroup.numberOfLogin);
        }
        if (key === isSignupReferral) {
          setIsSignupReferral(conditionGroup.isSignupReferral);
        }
        console.log('@@494: ', key);
        return {
          title: contentGroupTitleEnum[key],
          componment: contentSectionComponment[contentGroupTitleEnum[key]],
          groupName: key,
        };
      });

      return contentList.filter((val) => val !== null);
    });
  };

  useEffect(() => {
    const conditionGroup = (segment.conditionGroup || {})[conditionIndex] || {};
    console.log('@@489: ', Object.keys(conditionGroup));
    if (conditionGroup && Object.keys(conditionGroup).length > 0) {
      // console.log("@@512: ", conditionGroup);
      // setConditionGroup(conditionGroup);
      setContentWithoutNoComponment();
      console.log('@@488: ', segment.conditionGroup, conditionIndex);
    }
  }, [segment]);

  const reset = () => {
    setIsReset(true);

    setLastPurchase();
    setNumberOfLogin();
    setIsSignupReferral();

    if (isCreate) {
      setContent([]);

      dispatch({
        type: 'segments/removeVals',
        payload: {
          conditionIndex: conditionIndex,
        },
      });
    } else {
      setContent([]);

      dispatch({
        type: 'segments/resetVals',
        payload: {
          conditionIndex: conditionIndex,
        },
      });
    }
  };

  return (
    <>
      <div>
        <label className="create-section-title">
          Condition group {conditionIndex}
        </label>
        {content.length > 0 ? (
          <button
            type="button"
            className="btn-link reset-button"
            onClick={() => setShowResetPrompt(true)}
          >
            Reset
          </button>
        ) : null}
        <ErrorFieldMessage
          id={`condition-group-${conditionIndex}`}
          error={createErrorDict?.[conditionIndex] !== null}
          message={createErrorDict?.[conditionIndex]}
        />
      </div>

      <div className="content-area">
        {content.map((item, index) => {
          return (
            <>
              {index > 0 ? (
                <div className="content-condition-area">
                  <div className="content-condition-dash"></div>
                  <label>And</label>
                </div>
              ) : null}
              <div className="display-flex-area">
                {item.componment}
                <button
                  className="reset-button content-delete-button-area"
                  onClick={() => {
                    const afterRemoveContent = content.filter(
                      (val) => val.title !== item.title,
                    );
                    setContent(afterRemoveContent);

                    if (item.title === contentEnum.newSignupWithReferral)
                      setIsSignupReferral();
                    else if (item.title === contentEnum.lastPurchase)
                      setLastPurchase();
                    else if (item.title === contentEnum.numberOfLogin)
                      setNumberOfLogin();

                    dispatch({
                      type: 'segments/removeVals',
                      payload: {
                        conditionIndex: conditionIndex,
                        groupName: item.groupName,
                      },
                    });
                  }}
                >
                  <Image src={DeleteIcon} className="content-delete-button" />
                </button>
              </div>
            </>
          );
        })}
        <Dropdown
          onToggle={(isOpen) => {
            console.log('@@587: ', isOpen);
            setIsShowDropdown(isOpen);
          }}
        >
          <Dropdown.Toggle
            className="btn-back-button-common content-add-button"
            variant="none"
            id={`dropdown-${conditionIndex}`}
          >
            + Add condition
          </Dropdown.Toggle>

          <Dropdown.Menu style={{ zIndex: 2 }}>
            <ContentFilterDropDown
              show={isShowDropdown}
              addContent={(section) => {
                setContent([
                  ...content,
                  {
                    title: section,
                    componment: contentSectionComponment[section],
                    groupName: contentGroupEnum[section],
                  },
                ]);

                if (content.length === 0) {
                  addNewItemAction(itemIndex);
                }

                if (section === contentEnum.newSignupWithReferral)
                  setIsSignupReferral(false);
                else if (section === contentEnum.lastPurchase)
                  setLastPurchase(null);
                else if (section === contentEnum.numberOfLogin)
                  setNumberOfLogin(null);
                else
                  dispatch({
                    type: 'segments/changeVals',
                    payload: {
                      conditionIndex: conditionIndex,
                      groupName: contentGroupEnum[section],
                    },
                  });
              }}
              settedParams={content?.map((item) => item.title)}
            />
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <BasePrompt
        show={showResetPrompt}
        title={`Reset condition group ${conditionIndex}`}
        description={'Did you confirm to reset this content section?'}
        closeAction={() => setShowResetPrompt(false)}
        leftButton={{
          text: 'Cancel',
          action: () => {
            setShowResetPrompt(false);
          },
        }}
        rightButton={{
          text: 'Delete',
          action: () => {
            reset();
            setShowResetPrompt(false);
          },
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  levels: state.levels.levelList,
  segment: state.segments.tempSegment,
  createErrorDict: state.segments.createErrorDict,
  tempSegmentConditionGroup: state.segments.tempSegment?.conditionGroup,
});

export default connect(mapPropsToState)(ContentItemSection);
