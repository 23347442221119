import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import ContentSections from '../base/ContentSections';
import { validate } from '../../containers/record/records/CreateTransactionValidate';
import TransactionGeneralSection from './TransactionGeneralSection';
import TransactionAmountSection from './TransactionAmountSection';
import {
  SaveAndBackWithOutTempButtons,
  OnlySaveButton,
} from '../base/BottomStepComponent';

function CreateTransactionStepTwo() {
  const dispatch = useDispatch();
  const location = useLocation();

  const { watch, getValues, setValue, formState, clearErrors, setError } =
    useFormContext();
  const { errors } = formState;
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    dispatch({
      type: 'admin/getAllAdmins',
      payload: {},
    });
  }, [dispatch]);

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 1,
      isBack: isBack,
    });
    if (!isBack && isValid) {
      if (location.pathname.includes('edit')) {
        dispatch({
          type: 'createTransaction/updateTransaction',
          payload: {
            values: getValues(),
          },
        });
      } else {
        dispatch({
          type: 'createTransaction/createTransaction',
          payload: {
            values: getValues(),
          },
        });
      }
    } else {
      dispatch({
        type: 'createTransaction/stepChange',
        payload: {
          isBack: isBack,
          step: 1,
          isValid,
        },
      });
    }
  };

  const sections = [
    <TransactionGeneralSection />,
    <TransactionAmountSection />,
  ];

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <SaveAndBackWithOutTempButtons
        backAction={id ? () => history.goBack() : () => stepChangeAction(true)}
        saveText={'Save'}
        saveAction={() => {
          stepChangeAction(false);
        }}
      />
    </>
  );
}

export default CreateTransactionStepTwo;
