import React, { useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ContentSections from '../../../components/base/ContentSections';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
import { listItem, listImage } from '../../../components/banners/DisplayItems';
import { createAction } from '../../../utils';
import { formatDate, TimeFormater } from '../../../utils/TimeFormatUtil';
import { OnlyBackButton } from '../../../components/base/BottomStepComponent';
import MessageOverviewTableSection from '../../../components/message/MessageOverviewTableSection';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import {
  APIStatus,
  LanguageConfig,
  MessageTag
} from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';


function MessageOverview({
  selectedMessage,
  status,
  languages,
}) {
  const queryString = require('query-string');
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const message = {
    ...selectedMessage,
    displayChannels: selectedMessage.channels?.toString(),
    relatedCampaignName: selectedMessage.relatedCampaign?.name,
    displayTargetCustomer: (selectedMessage.targetCustomerGroup || []).map((item) => item.name).toString(),
    displayTargetedSegments: (selectedMessage.targetedSegments || []).map((item) => item.name).toString(),
    deliveryDate: formatDate(selectedMessage.scheduledDate, TimeFormater.dayMonthYearWeekTimeA)
  };

  useEffect(() => {
    dispatch({
      type: 'messageList/getMessage',
      payload: {
        messagePK: params.id,
      }
    });
    dispatch({
      type: 'language/getList',
      payload: {
        isSelectorLoad: true,
      }
    });
  }, [dispatch, params.id]);

  useEffect(() => {
    return () => {
      dispatch({
        type: 'createMessage/clearData',
        payload: {}
      });
      dispatch({
        type: 'messageList/clearData',
        payload: {}
      });
    };
  }, [dispatch]);

  const renderTabForLanguage = (language) => {
    return [
      <MessageOverviewTableSection
        message={message}
        language={language}
      />,
      <MembershipInfoCard
        title={'PROPERTIES'}
        data={message}
        fields={[
          [{ title: 'Message channel', field: 'displayChannels', isRichText: true }],
          [
            {
              title: 'Related campaign',
              field: 'relatedCampaignName',
            },
          ],
          [
            {
              title: 'Target customers',
              field: 'displayTargetCustomer',
            },
          ],
          [
            {
              title: 'Target segments',
              field: 'displayTargetedSegments',
            },
          ],
          [{ title: 'Delivery Time', field: 'deliveryDate' }],
        ]}
      />,
    ]
  };

  const tabs = [
    {
      name: '',
      content:
        status === APIStatus.calling ? (
          <Loading />
        ) : (
          <div className="scroll-container-common">
            <ContentSections
              languageTabContent={{
                containers: languages?.map((item) => ({
                  container: renderTabForLanguage(item.code),
                  key: item.code,
                  title: item.sourceName,
                })),
              }}
              hidePreview={true}
            />
          </div>
        ),
    },
  ];

  const buttons = [
    selectedMessage.status === MessageTag.sent ? null : (
      <AuthButton
        title="Edit"
        action={() => history.push('edit/')}
        requires={PermissionCodes.changeMessage}
      />
    ),
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '', //view message
          title: 'View Message',
        }}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
        buttons={buttons}
      />
      <OnlyBackButton
        backAction={() => {
          dispatch(createAction('messageList/clearMessageState')());
          history.push({
            pathname: '/messages',
            search: queryString.stringify({
              page: 1,
              rank: true,
              search: '',
            }),
          });
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  selectedMessage: state.messageList.message,
  status: state.createMessage.createStatus,
  languages: state.language.allList,
});

export default connect(mapPropsToState)(MessageOverview);

