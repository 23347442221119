import React, { useState, useEffect } from 'react';
import './CreateCampaignStepTwo.scss';
import { useSelector, useDispatch, connect } from 'react-redux';
import { CampaignType } from '../../../config/CustomEnums';
import ContentSections from '../../base/ContentSections';
import ActivePeriodSection from './ActivePeriodSection';
import CouponCampaignSection from './CouponCampaignSection';
import PropertiesGeneralSection from './PropertiesGeneralSection';
import VisiblePeriodSection from './VisiblePeriodSection';
import { ContinueWithBackButtons } from '../../base/BottomStepComponent';
import { useFormContext } from 'react-hook-form';
import { validate } from '../../../containers/engagement/campaign/CreateCampaignValidate';

const dataArray = [];
function CreateCampaignStepThree({}) {
  // const { campaignType } = useSelector((state) => ({
  //   campaignType: state.createCampaign.campaign.campaignType,
  // }));
  const dispatch = useDispatch();
  const { getValues, watch, setError, clearErrors } = useFormContext();
  const campaignType = watch('campaignType');
  // const [onCheck, setOnCheck] = useState({
  //   value: false,
  //   isBack: false,
  // });
  // const isChecking = ({ isBack }) => {
  //   setOnCheck({
  //     value: true,
  //     isBack: isBack,
  //   });
  // };
  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 2,
      isBack,
    });
    dispatch({
      type: 'createCampaign/stepChange',
      payload: { isBack: isBack, step: 2, isValid },
    });
  };

  // const stopCheck = () => {
  //   setOnCheck({
  //     value: false,
  //     isBack: false,
  //   });
  // };

  // useEffect(() => {
  //   if (onCheck.value) {
  //     let formatedData = {};
  //     dataArray.forEach((item) => {
  //       formatedData = {
  //         ...formatedData,
  //         ...item,
  //       };
  //     });
  //     dispatch({
  //       type: 'createCampaign/stepChange',
  //       payload: {
  //         data: formatedData,
  //         isBack: onCheck.isBack,
  //         step: 2,
  //       },
  //     });
  //   }
  //   stopCheck();
  // }, [dispatch, onCheck.isBack, onCheck.value]);

  // const submitAction = (data) => {
  //   dataArray.push(data);
  // };

  const sections = [
    <PropertiesGeneralSection
    // onSubmit={onCheck.value}
    // onSubmitAction={(data) => {
    //   submitAction(data);
    // }}
    />,
    <ActivePeriodSection
    // onSubmit={onCheck.value}
    // onSubmitAction={(data) => {
    //   submitAction(data);
    // }}
    />,
    <VisiblePeriodSection
    // onSubmit={onCheck.value}
    // onSubmitAction={(data) => {
    //   submitAction(data);
    // }}
    />,
    campaignType === CampaignType.couponsCampaign ? (
      <CouponCampaignSection
      // onSubmit={onCheck.value}
      // onSubmitAction={(data) => {
      //   submitAction(data);
      // }}
      />
    ) : null,
  ];

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <ContinueWithBackButtons
        continueAction={() => {
          stepChangeAction(false);
        }}
        backAction={() => {
          stepChangeAction(true);
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({});

export default connect(mapPropsToState)(CreateCampaignStepThree);
