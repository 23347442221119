import React, { useEffect, useState, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { createAction } from '../../../utils';
import ContentSections from '../../../components/base/ContentSections';
import {
  APIStatus,
  LanguageConfig,
} from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
import QRCodeCollapse from './QRCodeCollapse';
import { CustomTitleLabel } from '../../../components/earning/CustomBaseComponments';
import i18n from 'i18n-js';


function StoreDetail({
  store,
  status,
  languages
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      dispatch(createAction('language/getList')({ isSelectorLoad: true }));
      dispatch(
        createAction('storeModel/getOneStore')({ id: params.id, view: true }),
      );
    }
    return () => {
      dispatch({ type: 'storeModel/clearData' });
    };
  }, [dispatch, params.id]);

  const getValueFromTranslations = (language, valueKeys) => {
    const data = {};
    // console.log("110", language, valueKeys)
    const datas = valueKeys?.forEach(valueKey => {
        data[valueKey] = store?.storeTranslations?.[language]?.[valueKey];
    });
    // console.log("@152",data)
    return data;
  }

  const renderTabForLanguage = (language) => {
    const data =getValueFromTranslations(
      language,
      [
        'name',
        'description',
        'address',
      ]
    )

    return [
      <MembershipInfoCard
        title={i18n.t('content', { locale: 'en' })}
        data={data}
        fields={[
          [{
              title: 'Name',
              field: 'name',
          }],
          [{
            title: 'Description',
            field: 'description',
            isRichText: true,
          }],
          [{
            title: 'Address',
            field: 'address',
          }],
        ]}
      />
    ]
  };

  const tabs = [
    {
      name: 'Detail',
      content: status === APIStatus.calling
      ? <Loading />
      : (
        <>
          <ContentSections
            hidePreview
            languageTabContent={{
              containers: languages?.map((item) => ({
                container: [renderTabForLanguage(item.code)],
                key: item.code,
                title: item.sourceName,
              })),
            }}
            activeSection={LanguageConfig.english}
          />
          <ContentSections
            sections={[
              <>
                <MembershipInfoCard
                  title="information"
                  data={store}
                  fields={[
                    [{ title: 'Display order', field: 'displayPriority' }],
                    [{ title: 'Cover image', field: 'photo' }],
                    [{ title: 'District', field: 'displayDistrict' }],
                    [{ title: 'Phone number', field: 'displayPhoneNumner' }],
                    [{ title: 'Email', field: 'emailAddress' }],
                    [{ title: 'Latitude', field: 'latitude' }],
                    [{ title: 'Longitude', field: 'longitude' }],
                    [{ title: 'Staff code', field: 'staffCode' }],
                    [{ title: 'Subcategories', field: 'displaySubcategory' }],
                    [{ title: 'Brand', field: 'displayBrand' }],
                  ]}
                />
                <CustomTitleLabel title={'QR code preview'} />
                <label>The store QR code is auto generate</label>
                <QRCodeCollapse
                  codeDisplayImage={store?.codeDisplayImage}
                  codeDownloadImage={store?.codeDownloadImage}
                />
              </>,
            ]}
            hidePreview
          />
        </>
      ),
    },
  ];

  const buttons = [
    <AuthButton
      title="Edit"
      action={() => {
        // dispatch({ type: 'customerList/clearData' });
        dispatch({ type: 'storeModel/clearData' });
        history.push({
          pathname: 'edit/',
        });
      }}
      requires={PermissionCodes.changeStore}
    />,
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].name);



  return (
    <div className="campaign-category-detail">
      <CustomListComponent
        caution={{
          detail: '',
          title: store.name,
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb name={store.name} />}
        buttons={buttons}
        tabs={tabs}
        onTabChange={(key) => {
          setActiveTab(key);
        }}
      />
    </div>
  );
}

const mapPropsToState = (state) => ({
  store: state.storeModel.oneStore || {},
  status: state.loading.status,
  languages: state.language.allList,
});

export default connect(mapPropsToState)(StoreDetail);
