import React, { useEffect, useState } from 'react';
import CustomEditor from './CustomEditor';
import './CustomMarkDownArea.scss';
import ErrorFieldMessage from './ErrorFieldMessage';
import { isLongScreen } from '../../utils/ScreenUtil';
import CustomTips from './CustomTips';
import { Controller, useFormContext } from 'react-hook-form';
import LabelWithSwitch from './LabelWithSwitch';
import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';
import SpecificCustomSwitchButton from '../../containers/merchants/stores/SpecificCustomSwitchButton';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import BasePrompt from '../base/prompt/BasePrompt';

const REMINDER_PROMPT_TYPE = {
  follow: {
    title: 'Confirm to follow the selected template?',
    description:
      'The content of the selected template has been modified. If “ON”, it will be replaced by the selected template again.',
  },
  change: {
    title: 'Confirm to change or clear the selected template?',
    description:
      'The content of the template has been modified. Upon confirmation, the modified content will be removed.',
  },
};

function CustomMarkDownArea(props) {
  const areaTitle = props.areaTitle;
  const resetInitValue = props.resetInitValue;
  const resetInit = props.resetInit;
  const resetInitValueByTC = props.resetInitValueByTC;
  const resetInitByTC = props.resetInitByTC;
  const titleLabel = props.title.label;
  const titleTips = props.title.tips;
  const titleValue = props.title.value;
  const titleValueChange = props.title.valueChange || (() => {});
  const titleFocus = props.title.focus || (() => {});
  const titleError = props.title.error;
  const titleErrorMessage = props.title.errorMessage;
  const titleErrorId = props.title.errorId;
  const titleMaxLength = props.title.maxLength || 200;

  const contentLabel = props.content.label;
  const contentTips = props.content.tips;
  const contentInitValue = props.content.value;
  const contentValueChange = props.content.valueChange || (() => {});
  const contentFocus = props.content.focus || (() => {});
  const contentError = props.content.error;
  const contentErrorMessage = props.content.errorMessage;
  const contentErrorId = props.content.errorId;
  const allChecked = props.checked;
  const allCheckChange = props.checkChange || (() => {});
  const showAllSwitch = props.showSwitch;
  const titleChecked = props.title.checked;
  const showNameSwitch = props.title.showSwitch;
  const titleCheckChange = props.title.checkChange || (() => {});
  const showContentSwitch = props.content.showSwitch;
  const contentCheckChange = props.content.checkChange || (() => {});
  const contentChecked = props.content.checked;

  const isLinkToTC = props.isLinkToTC || false;
  const titleChanged = props.titleChanged || false;
  const contentChanged = props.contentChanged || false;
  const formLinkToTCTemplate = props.formLinkToTCTemplate || {};
  const setFormLinkToTCTemplate = props.setFormLinkToTCTemplate || (() => {});
  const followTemplateValue = props.followTemplateValue;
  const setFollowTemplateValue = props.setFollowTemplateValue || (() => {});
  const TCChecked = formLinkToTCTemplate?.pk && followTemplateValue;
  const linkToTCList = props.linkToTCList || [];
  const showReminderPrompt = props.showReminderPrompt || false;
  const reminderPromptType = props.reminderPromptType;
  const onReminderPromptConfirm = props.onReminderPromptConfirm || (() => {});
  const onReminderPromptChangeConfirm =
    props.onReminderPromptChangeConfirm || (() => {});
  const onReminderPromptClose = props.onReminderPromptClose || (() => {});
  const onReminderPromptChangeCancel =
    props.onReminderPromptChangeCancel || (() => {});
  const onReminderPromptFollowCancel =
    props.onReminderPromptFollowCancel || (() => {});
  const defaultFilter = props.defaultFilter;
  const getListKey = props.getListKey;
  const [titleInput, setTitleInput] = useState();
  // console.log('@@77', titleChanged, contentChanged, formLinkToTCTemplate?.pk);
  // console.log('@@78', resetInitValue);

  useEffect(() => {
    setTitleInput(titleValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titleValue]);

  const getLinkToTCTemplate = () => {
    if (!isLinkToTC || allChecked) {
      return <></>;
    }
    return (
      <>
        <CustomTitleWithDropDown
          customClass="link-to-terms-and-conditions-template-dropdown"
          title="Link to Terms & Conditions Template（optional）"
          labelContainPk={true}
          defaultValue={
            formLinkToTCTemplate?.pk
              ? {
                  label: `[${formLinkToTCTemplate?.pk}] ${formLinkToTCTemplate?.name}`,
                  value: formLinkToTCTemplate,
                }
              : { label: '', value: '' }
          }
          setValue={setFormLinkToTCTemplate}
          source={linkToTCList}
          loadMoreAction={`termsAndConditions/${getListKey}`}
          filterAction={`termsAndConditions/${getListKey}`}
          placeholder="Search by T&C Template ID and name"
          defaultFilter={defaultFilter}
        />
        {formLinkToTCTemplate?.pk ? (
          <>
            <CustomTitleLabel title="Follow Selected Terms & Conditions Template" />
            <div>
              <SpecificCustomSwitchButton
                checked={followTemplateValue}
                onChange={(value) => {
                  setFollowTemplateValue(value);
                }}
              />
            </div>
            <label className="link-to-terms-and-conditions-template-tips">
              If Off, any further changes on the template will not be updated
              here.
            </label>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  const getLabel = ({ tips, label, checked, onChange, showSwitch }) => {
    if (!tips) {
      return (
        <LabelWithSwitch
          labelText={label}
          className="create-section-label-bottom-space"
          checked={checked}
          onChange={onChange}
          showSwitch={showSwitch}
        />
      );
    }
    return (
      <div className="create-section-label-bottom-space">
        <CustomTips detail={tips.text} innerImage={tips.image}>
          <LabelWithSwitch labelText={label} showSwitch={showSwitch} />
        </CustomTips>
      </div>
    );
  };
  return (
    <>
      <LabelWithSwitch
        labelText={areaTitle}
        className="create-section-title"
        notUseDefaultLabelStyle
        showSwitch={showAllSwitch}
        checked={allChecked}
        onChange={allCheckChange}
      />
      {getLinkToTCTemplate()}
      {getLabel({
        tips: titleTips,
        label: titleLabel,
        checked: titleChecked,
        onChange: titleCheckChange,
        showSwitch: showNameSwitch,
      })}
      <input
        type="text"
        maxLength={titleMaxLength || 200}
        onChange={({ target }) => {
          setTitleInput(target.value);
          titleValueChange(target.value);
        }}
        disabled={allChecked || titleChecked || TCChecked}
        value={titleInput || ''}
        className={`custom-markdown-area-title ${
          titleError ? 'error-field' : ''
        } ${isLongScreen ? '' : 'custom-markdown-area-title-short'}`}
        onFocus={() => titleFocus()}
      />
      {formLinkToTCTemplate?.pk && titleChanged ? (
        <label className="link-to-terms-and-conditions-template-tips">
          (The content of the selected template has been modified)
        </label>
      ) : (
        <></>
      )}
      <ErrorFieldMessage
        id={titleErrorId}
        error={titleError}
        message={titleErrorMessage}
      />
      {getLabel({
        tips: contentTips,
        label: contentLabel,
        checked: contentChecked,
        onChange: contentCheckChange,
        showSwitch: showContentSwitch,
      })}
      <CustomEditor
        disabled={allChecked || contentChecked || TCChecked}
        initialValue={contentInitValue}
        onValueChange={(data) => contentValueChange(data)}
        onFocus={() => contentFocus()}
        errorMessage={contentErrorMessage}
        error={contentError}
        resetInitValue={resetInitValue}
        resetInit={resetInit}
        resetInitValueByTC={resetInitValueByTC}
        resetInitByTC={resetInitByTC}
      />
      {formLinkToTCTemplate?.pk && contentChanged ? (
        <label className="link-to-terms-and-conditions-template-tips">
          (The content of the selected template has been modified)
        </label>
      ) : (
        <></>
      )}
      {contentErrorId ? <span id={contentErrorId} /> : null}
      <BasePrompt
        show={showReminderPrompt}
        closeAction={() => {
          reminderPromptType === 'change'
            ? onReminderPromptChangeCancel()
            : onReminderPromptFollowCancel();
          onReminderPromptClose();
        }}
        rightButton={{
          text: 'Confirm',
          action: () => {
            if (reminderPromptType === 'change') {
              onReminderPromptChangeConfirm();
            }
            onReminderPromptConfirm();
          },
        }}
        leftButton={{
          text: 'Cancel',
          action: () => {
            reminderPromptType === 'change'
              ? onReminderPromptChangeCancel()
              : onReminderPromptFollowCancel();
            onReminderPromptClose();
          },
        }}
        title={REMINDER_PROMPT_TYPE?.[reminderPromptType]?.title}
        description={REMINDER_PROMPT_TYPE?.[reminderPromptType]?.description}
      />
    </>
  );
}

export default CustomMarkDownArea;

export const ReactHookFormMarkDown = ({ areaTitle, title, customStyle, content }) => {
  // const {
  //   label: titleLabel,
  //   tips: titleTips,
  //   error: titleError,
  //   key: titleKey,
  //   required: titleRequired,
  //   focus: titleFocus,
  //   maxLength: titleMaxLength,
  // } = title;
  const {
    label: contentLabel,
    tips: contentTips,
    error: contentError,
    key: contentKey,
    focus: contentFocus,
    errorMessage: contentErrorMessage,
    required: contentRequired,
  } = content;
  console.log('@284',customStyle);
  const { register, watch, control, setValue } = useFormContext();
  const watchContent = watch(contentKey);

  const renderLabel = (tips, label) => {
    if (!tips) {
      return renderLabelText(label, 'create-section-label-bottom-space');
    }
    return (
      <div className="create-section-label-bottom-space">
        <CustomTips detail={tips.text} innerImage={tips.image}>
          {renderLabelText(label)}
        </CustomTips>
      </div>
    );
  };
  const renderLabelText = (labelText, className) => {
    return (
      <label className={`create-section-label ${className}`}>{labelText}</label>
    );
  };

  return (
    <>
      {/* <label className="create-section-title">{areaTitle}</label> */}
      {/* {renderLabel(titleTips, titleLabel)} */}
      {/* <input
        type="text"
        className={`custom-markdown-area-title ${
          titleError ? 'error-field' : ''
        } ${isLongScreen ? '' : 'custom-markdown-area-title-short'}`}
        onFocus={() => titleFocus()}
        {...register(
          titleKey,
          titleRequired
            ? {
                required: titleRequired,
              }
            : {},
        )}
        {...(titleMaxLength ? { maxLength: titleMaxLength } : {})}
      />
      <ErrorFieldMessage
        error={titleError && titleRequired}
        message={titleRequired}
      /> */}
      {renderLabel(contentTips, contentLabel)}
      <Controller
        name={contentKey}
        control={control}
        rules={contentRequired ? { required: contentRequired } : {}}
        render={() => (
          <CustomEditor
            initialValue={watchContent || ' '}
            onValueChange={(data) => {
              setValue(contentKey, data, {
                shouldDirty: data.trim() !== watchContent,
              });
            }}
            onFocus={() => contentFocus()}
            errorMessage={contentErrorMessage}
            error={contentError}
            customStyle={customStyle}
          />
        )}
      />

      {/* {contentErrorId ? <span id={contentErrorId} /> : null} */}
    </>
  );
};
