import React from 'react';
import { Route } from 'react-router-dom';
import { tabList } from './NavBarConfig';
import { pageRoutes } from './PageConfig';
import { useDispatch, useSelector } from 'react-redux';
import { rewritePermission } from '../utils';
import NoPermission from '../containers/base/NoPermission';
import { PermissionCodes } from './PermissionCodes';

function Authorization(route, user) {
  // const { user } = useSelector((state) => ({
  //   user: state.users,
  // }));
  const newPermission = rewritePermission(route?.codename);
  if (user.isSuperuser) return route.main;
  if (user.userPermissions?.includes(newPermission) || !newPermission) {
    return route.main;
  }
  if(route?.codenames && route.codenames.some((code)=>user.userPermissions.includes(code))){
    return route.main;
  }
  // console.log("@@22: ", newPermission, user)
  if (
    (newPermission.indexOf('_') < 0 &&
      [
        PermissionCodes.customer,
        PermissionCodes.campaign,
        PermissionCodes.coupontemplate,
      ].includes(newPermission)) ||
    user.userPermissions.filter((val) => val.split('_')[1] === newPermission)
      .length > 0
  ) {
    return route.main;
  }

  return <NoPermission />;
}

function getOneRouteElement(route, user) {
  return (
    <Route
      key={route.path}
      path={route.path}
      exact={route.exact}
      children={Authorization(route, user)}
    />
  );
}
function getNavBarRoutes(user) {
  const routeLists = [];
  tabList.forEach((tab, index) => {
    if (tab.list) {
      tab.list.forEach((route, index) => {
        if (route.children) {
          route.children.forEach((child, index) => {
            const routeElement = getOneRouteElement(child, user);
            routeLists.push(routeElement);
          });
        } else {
          const routeElement = getOneRouteElement(route, user);
          routeLists.push(routeElement);
        }
      });
    }
  });

  return routeLists;
}
function getPageRoutes(user) {
  return pageRoutes.map((route) => getOneRouteElement(route, user));
}
const routes = (user) => [...getNavBarRoutes(user), ...getPageRoutes(user)];
export default routes;
