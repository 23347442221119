import React from 'react';
const showdown = require('showdown');
const converter = new showdown.Converter();

function CustomViewer({ initValue, className, onClick = () => {} }) {
  return (
    <label
      className={className}
      onClick={onClick}
      dangerouslySetInnerHTML={{
        __html: initValue || '-'
      }}
    ></label>
  );
}

export default CustomViewer;
