import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form'
import BasePrompt from '../../base/prompt/BasePrompt';
import { BUTTON_TYPES } from '../../base/prompt/BaseButtons';
import CouponSelectDropdown from './CouponSelectDropdown';
import { ContinueCreate } from '../CampaignUrlConfig';
import EarningRulesSelectDropdown from './EarningRulesSelectDropdown';
import { CampaignType } from '../../../config/CustomEnums';
import { PermissionCodes } from '../../../config/PermissionCodes';

function CampaignLinkPrompt({
  closePrompt,
  handleContinue,
  campaignType,
  linkedEarningRules,
  linkedCoupon
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { setValue } = useFormContext();

  // const { campaignType, linkedCoupon, linkedEarningRules } = useSelector(
  //   (state) => {
  //     return {
  //       campaignType: state.createCampaign.campaign.campaignType,
  //       linkedEarningRules: state.createCampaign.campaign.linkedEarningRules,
  //       linkedCoupon: state.createCampaign.campaign.linkedCoupon,
  //     };
  //   },
  // );

  const earningConfig = {
    title: 'Link Existing Earning Rule Set',
    description:
      'Please link to an existing earning rule set. After you linked to an existing earning rule set, the system will auto-fill in the content of the earning rule set in the next step.',
    leftButton: {
      text: 'Create Earning Rule Set instead',
      action: () =>
        history.push({
          pathname: '/earns/create',
          state: { from:
            {...history.location,
              state: {
                ...history.location?.state,
                resume: true
              }}, ...ContinueCreate },
        }),
      requires: PermissionCodes.addEarningRule,
    },
    rightButton: {
      disabled: !linkedEarningRules?.pk,
    },
    other: () => (
      <EarningRulesSelectDropdown
        isPrompt
        title={'Link to earning rule set'}
        defaultValue={{
          value: linkedEarningRules,
          label: linkedEarningRules?.name,
        }}
        setValue={(item) => {
          console.log('couponChange:', item);
          const coupon = item || {};
          setValue('linkedEarningRules', coupon, { shouldDirty: true });
        }}
      />
    ),
  };

  const couponConfig = {
    title: 'Link Existing Coupon Set',
    description:
      'Please link to an existing coupon set. After you linked to an existing coupon set, the system will auto-fill in the content of the coupon set in the next step.',
    leftButton: {
      text: 'Create Coupon Set instead',
      action: () =>
        history.push({
          pathname: '/coupons/create',
          state: { from:
            {...history.location,
              state: {
                ...history.location?.state,
                resume: true
              }}, ...ContinueCreate },
        }),
      requires: PermissionCodes.addCouponTemplate,
    },
    rightButton: {
      disabled: !linkedCoupon?.pk,
    },
    other: () => (
      <CouponSelectDropdown
        title={'Link to coupon set'}
        isPrompt
        defaultValue={{
          value: linkedCoupon,
          label: linkedCoupon?.name,
        }}
        setValue={(item) => {
          console.log('couponChange:', item);
          const coupon = item || {};
          setValue('linkedCoupon', coupon, { shouldDirty: true });
        }}
      />
    ),
  };

  const setCoupon = (value) => {
    // dispatch(
    //   createAction('createCampaign/getAndLinkCouponDetail')({
    //     couponId: value?.pk,
    //     name: value?.name,
    //   }),
    // );

  };

  const getConfig = () => {
    return campaignType === CampaignType.couponsCampaign
      ? couponConfig
      : earningConfig;
  };

  return (
    <BasePrompt
      show={true}
      closeAction={closePrompt}
      leftButton={{
        ...getConfig().leftButton,
        type: BUTTON_TYPES.link,
      }}
      rightButton={{
        ...getConfig().rightButton,
        text: 'Continue',
        action: () => {
          handleContinue();
        },
      }}
      title={getConfig().title}
      description={getConfig().description}
      otherBody={() => getConfig().other()}
    />
  );
}

export default CampaignLinkPrompt;
