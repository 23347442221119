import React, { useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import {
  LanguageConfig,
  APIStatus,
  DELETE_RELATED_SECTIONS,
} from '../../../config/CustomEnums';
import {
  createAction,
  removeFromSessionStorage,
  saveToSessionStorage,
} from '../../../utils';
import { CAMPAIGN_CATEGORY_SESSION_KEY } from '../../../models/CreateCampaignCategoryModel';
import BaseForm from '../../../components/base/v2/BaseForm';
import CreateCampaignCategoryStepOne from './CreateCampaignCategoryStepOne';

const CreateCampaignCategory = ({
  category,
  createStatus,
  hasUpdatedDefaultValues,
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const caution = () => {
    if (params.id) {
      return {
        detail: '', //Need fill in the category name and display order
        title: category.name,
        model: 'createCampaignCategory',
      };
    } else {
      return {
        detail: '', //Need fill in the category name and display order
        title: 'Create campaign category',
        model: 'createCampaignCategory',
      };
    }
  };

  useEffect(() => {
    const categoryID = params.id;
    if (categoryID) {
      dispatch(
        createAction('createCampaignCategory/getCategory')({ id: categoryID }),
      );
    } else {
      dispatch({
        type: 'createCampaignCategory/loadCampaignCategoryFromSessionStorage',
      });
    }
    return () => {
      dispatch({ type: 'createCampaignCategory/reset' });
    };
  }, [dispatch, params.id]);

  useEffect(() => {
    if (
      createStatus === APIStatus.success &&
      history.location.pathname.includes('edit')
    ) {
      history.push('/campaign_category');
      return;
    }
  }, [createStatus, category]);

  const stepSet = [<CreateCampaignCategoryStepOne />]
  return (
    <div className="campaign-category">
      <BaseForm
        defaultValues={category}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={createStatus === APIStatus.success}
        tempSave={(save, getValues) => {
          if (save) {
            saveToSessionStorage(CAMPAIGN_CATEGORY_SESSION_KEY, getValues());
          } else {
            removeFromSessionStorage(CAMPAIGN_CATEGORY_SESSION_KEY);
          }
        }}
        showFinishPop={createStatus === APIStatus.success}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Campaign category is successfully created.',
          steps: null,
          buttons: [
            {
              text: 'Back to campaign category list',
              action: () => {
                history.push({
                  pathname: '/campaign_category',
                });
              },
            },
          ],
        }}
        content={stepSet}
        currentStep={0}
        breadcrumb={<CustomBreadcrumb name={category.name} />}
        caution={caution()}
      />
    </div>
  );
};

const mapPropsToState = (state) => ({
  category: state.createCampaignCategory.category,
  createStatus: state.createCampaignCategory.createStatus,
  hasUpdatedDefaultValues: state.createCampaignCategory.hasUpdatedDefaultValues,
})

export default connect(mapPropsToState)(CreateCampaignCategory);
