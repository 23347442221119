import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import BrandSelectDropdown from './BrandSelectDropdown';
import { LanguageConfig } from '../../../config/CustomEnums';
import i18n from '../../../I18n';
import {
  CampaignErrorHandleField,
  isShowError,
  firstError,
} from './CreateCampaignHandleError';
import CustomMarkDownArea, { ReactHookFormMarkDown } from '../../base/CustomMarkDownArea';
import AuthButton from '../../base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { useHistory, useParams } from 'react-router-dom';
import BasePrompt from '../../base/prompt/BasePrompt';
import { ContinueCreate } from '../CampaignUrlConfig';
import CustomAddRemoveMutipleSelector from '../../customer/CustomAddRemoveMutipleSelector';
import { createAction } from '../../../utils';
import { useFormContext, Controller } from 'react-hook-form';
import { SectionTopTitle } from '../../base/CommonComponent';
import InputFieldControl from '../../base/InputFieldControl';
import {
  hasError,
  ReactHookFormErrorMessage,
} from '../../base/ErrorFieldMessage';
import BaseMultipleSelectorV2 from '../../base/BaseMultipleSelectorV2';
import './GeneralSection.scss';

function GeneralSection({
  // brandList,
  storeList,
  language,
  onFocus = (() => {}),
}) {
  const params = useParams();
  const { id } = params;
  const dispatch = useDispatch();
  const history = useHistory();
  const { watch, setValue, formState, control, getValues } = useFormContext();
  const errors = formState.errors;

  const watchBrand = watch('linkedBrand') || {};
  const translations = watch('translations') || {};
  const watchName = watch(`translations.${language}.generalName`);
  const watchDescription = watch(`translations.${language}.shortDescription`);
  const watchStores = watch('linkedStores') || [];

  // const [campaignGeneralName, setCampaignGeneralName] = useState(generalName);
  // const [campaignShortDescription, setCampaignShortDescription] = useState(
  //   shortDescription,
  // );
  // const [campaignLinkedBrands, setCampaignLinkedBrands] = useState(
  //   campaign.linkedBrand,
  // );
  // const [campaignLinkedStores, setCampaignLinkedStores] = useState(
  //   campaign.linkedStores,
  // );
  const [showAddBrand, setShowAddBrand] = useState(false);
  // const isError = (name) => {
  //   return isShowError(
  //     CampaignErrorHandleField[name].name,
  //     errorFields,
  //     language,
  //   );
  // };

  // useEffect(() => {
  //   if(storeList) {
  //     return
  //   }
  //   if(language !== LanguageConfig.english) {
  //     return
  //   }
  //   dispatch(
  //     createAction('storeModel/getPagedStoreList')({
  //       brands: watchBrand ? [watchBrand.pk] : [],
  //       isSelectorLoad: true,
  //     }),
  //   );
  // }, [dispatch, watchBrand, language]);



  // useEffect(() => {
  //   firstError(
  //     `${CampaignErrorHandleField.generalName.name}-${language}`,
  //     errorFields,
  //     language,
  //   );
  //   firstError(
  //     `${CampaignErrorHandleField.shortDescription.name}-${language}`,
  //     errorFields,
  //     language,
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [errorFields]);

  return (
    <>
      <SectionTopTitle title={i18n.t('general', { locale: language })} />
      <InputFieldControl
        name={`translations.${language}.generalName`}
        title={i18n.t('name', { locale: language })}
        value={watchName}
        setValue={(value) => {
          setValue(`translations.${language}.generalName`, value, { shouldDirty: true });
        }}
        onFocus={() => {
          onFocus('name');
        }}
      />
      <ReactHookFormMarkDown
        content={{
          label: i18n.t('short_description', { locale: language }),
          error:
            language === LanguageConfig.english
              ? hasError(errors, `translations.${language}.shortDescription`)
              : false,
          key: `translations.${language}.shortDescription`,
          focus: () => {
            onFocus('shortDescription')
          }
        }}
      />
      <ReactHookFormErrorMessage errors={errors} id={`translations.${language}.shortDescription`} />
      <div
        className="create-campaign-brand-select"
        onFocus={() => onFocus('relatedBrand')}
      >
        <BrandSelectDropdown
          title={i18n.t('related_brand_optional', { locale: language })}
          defaultValue={{ label: watchBrand?.name, value: watchBrand }}
          onSelectChange={(value) => {
            setValue('linkedBrand', value?.value || {}, { shouldDirty: true });
          }}
          addButton={{
            action: () => setShowAddBrand(true),
            title: 'Create Now',
            requires: PermissionCodes.addBrand,
            customClass: 'general-section-add-new-brand btn-add-button-common',
          }}
          editable={language === LanguageConfig.english}
        />
      </div>
      {/* <Controller
        control={control}
        name="linkedStores"
        render={() => (
          <BaseMultipleSelectorV2
            title={i18n.t('applicable_stores', { locale: language })}
            namespace='storeModel'
            data={{
              sourceData: storeList,
              targetData: watchStores,
              targetChange: (value) => {
                setValue('linkedStores', value, {shouldDirty: true})
              },
            }}
            disabled={language !== LanguageConfig.english}
          />
        )}
      /> */}

      <BasePrompt
        show={showAddBrand}
        closeAction={() => setShowAddBrand(false)}
        rightButton={{
          text: 'Create Brand',
          action: () =>
            history.push({
              pathname: '/brands/create',
              state: { from:
                {...history.location,
                  state: {
                    ...history.location?.state,
                    resume: true
                  }}, ...ContinueCreate },
            }),
        }}
        title={'Go to create brand?'}
        description={`You will leave the campaign creation process. After you create a new brand, you can back to this page.`}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  // brandList: state.brand.brandList,
  storeList: state.storeModel.storeList,
})

export default connect(mapPropsToState)(GeneralSection);
