import { LanguageConfig } from '../../../config/CustomEnums';
import { CreateTransactionError } from '../../../models/CreateTransactionModel';
import { TRANSACTION_RECORD_TYPE } from '../../../containers/record/records/TransactionList';

function firstStepValidate({ getValues, setError }) {
  const transactionDisplayTypeField = 'transactionDisplayType';
  const [
    transactionDisplayType,
  ] =  getValues([
    transactionDisplayTypeField,
  ]);
  const errorList = [];
  if (!transactionDisplayType) {
    errorList.push({
      name: transactionDisplayTypeField,
      message: 'Please select a type',
    });
  }
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'require',
      message: error.message,
    });
  });
  console.log('errorListMessage:', errorList);
  return !errorList.length;
}

function secondStepValidate({ getValues, setError }) {
  const customerField = 'customer';
  const transactionDateField = 'transactionDate';
  const storeField = 'store';
  const posInvoiceIdField = 'posInvoiceId';
  const invoiceIdField = 'invoiceId';
  const offlineEventTypeField = 'offlineEventType';
  const staffNameField = 'staffName';
  const totalValueField = 'totalValue';
  const [
    transactionDisplayType,
    customer,
    transactionDate,
    store,
    posInvoiceId,
    invoiceId,
    offlineEventType,
    staffName,
    totalValue,
  ] = getValues([
    'transactionDisplayType',
    customerField,
    transactionDateField,
    storeField,
    posInvoiceIdField,
    invoiceIdField,
    offlineEventTypeField,
    staffNameField,
    totalValueField,
  ]);
  const errorList = [];
  if (!customer?.pk) {
    errorList.push({
      name: customerField,
      message: CreateTransactionError.customer.message,
    });
  }
  if (!transactionDate) {
    errorList.push({
      name: transactionDateField,
      message: CreateTransactionError.transactionDate.message,
    });
  }
  if (
    transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM
    && !invoiceId
  ) {
    errorList.push({
      name: invoiceIdField,
      message: CreateTransactionError.invoiceId.message,
    });
  }
  if (
    transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS
    && !offlineEventType
  ) {
    errorList.push({
      name: offlineEventTypeField,
      message: CreateTransactionError.offlineEventType.message,
    });
  }
  if (!totalValue || totalValue === '') {
    errorList.push({
      name: totalValueField,
      message: CreateTransactionError.totalValue.message,
    });
  } else if (!parseInt(totalValue)) {
    errorList.push({
      name: totalValueField,
      message: CreateTransactionError.totalValueFormat.message,
    });
  }
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'require',
      message: error.message,
    });
  });
  console.log('errorListMessage:', errorList);
  return !errorList.length;
}

function thirdStepValidate({ getValues, setError }) {
  const [
    transactionDisplayType,
  ] = getValues([
    'transactionDisplayType',
  ]);
  const errorList = [];
  if (transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS) {
    const purchasedItemsField = 'purchasedItems';
    const [
      purchasedItems,
    ] = getValues([
      purchasedItemsField,
    ]);
    purchasedItems.forEach((item, index) => {
      if (!item.productName) {
        errorList.push({
          name: `purchasedItems.${index}.productName`,
          message: CreateTransactionError.purchasedItemName.message,
        });
      }
      if (!item.quantity) {
        errorList.push({
          name: `purchasedItems.${index}.quantity`,
          message: CreateTransactionError.purchasedItemQuantity.message,
        });
      } else if (!parseInt(item.quantity)) {
        errorList.push({
          name: `purchasedItems.${index}.quantity`,
          message: CreateTransactionError.purchasedItemQuantityFormat.message,
        });
      }
      if (!item.value) {
        errorList.push({
          name: `purchasedItems.${index}.value`,
          message: CreateTransactionError.purchasedItemValue.message,
        });
      } else if (!parseInt(item.value)) {
        errorList.push({
          name: `purchasedItems.${index}.value`,
          message: CreateTransactionError.purchasedItemValueFormat.message,
        });
      }
    });
  } else if (transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM) {
    const receiptImageField = 'receiptImage';
    const creditCardSlipImageField = 'creditCardSlipImage';
    const [
      receiptImage,
      creditCardSlipImage,
    ] = getValues([
      receiptImageField,
      creditCardSlipImageField,
    ]);
    if (!receiptImage) {
      errorList.push({
        name: receiptImageField,
        message: CreateTransactionError.receiptImage.message,
      });
    }
    if (!creditCardSlipImage) {
      errorList.push({
        name: creditCardSlipImageField,
        message: CreateTransactionError.creditCardSlipImage.message,
      });
    }
  }
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'require',
      message: error.message,
    });
  });
  return !errorList.length;
}

export function validate({ getValues, setError, clearErrors, step, isBack }) {
  if (isBack) {
    return true;
  }
  clearErrors();
  switch (step) {
    case 0:
      const validStepOne = firstStepValidate({ getValues, setError });
      return validStepOne;
    case 1:
      const validStepTwo = secondStepValidate({ getValues, setError });
      return validStepTwo;
    case 2:
      const validStepThree = thirdStepValidate({ getValues, setError });
      return validStepThree;
    default:
      const validOne = firstStepValidate({ getValues, setError });
      const validTwo = secondStepValidate({ getValues, setError });
      return validOne && validTwo;
  }
}
