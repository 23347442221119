import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { CampaignType } from '../../../config/CustomEnums';
import { LanguageConfig } from '../../../config/CustomEnums';
import { PermissionCodes } from '../../../config/PermissionCodes';
import BaseForm from '../../../components/base/v2/BaseForm';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CreateMessageStepOne from '../../../components/message/CreateMessageStepOne';
import CreateMessageStepTwo from '../../../components/message/CreateMessageStepTwo';
import CreateMessageStepThree from '../../../components/message/CreateMessageStepThree';
import CreateMessageStepFour from '../../../components/message/CreateMessageStepFour';
import {
  getCmapaignCreateSucessStepBar,
  NOTIFY_YOUR_CUSTOMER,
} from '../../../components/base/prompt/NextStepConfig';
import { URLFragments } from '../../../components/campaign/CampaignUrlConfig';
import { sessionDataKey } from '../../../models/CreateMessageModel';
import { TAB_LIST_OF_COUPON_SETS } from '../coupon/CouponList';


function CreateMessage({
  stepConfig,
  currentStep,
  scheduledMessage,
  messagePK,
  campaign,
  message,
  hasUpdatedDefaultValues,
  formHasSubmitted,
}) {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const search = queryString.parse(history.location.search);
  useEffect(() => {
    if (search) {
      const campaignId = search.campaign;
      if (campaignId) {
        dispatch({
          type: 'createCampaign/getOneCampaign',
          payload: { id: campaignId },
        });
      }

      dispatch({
        type: 'language/getList',
        payload: { isSelectorLoad: true }
      });
    }
    if (params.id) {
      dispatch({
        type: 'messageList/getMessage',
        payload: {
          messagePK: params.id,
          afterAction: () => {
            dispatch({
              type: 'createMessage/stepChange',
              payload: {
                step: 0,
              }
            });
          },
        }
      });
    } else {
      dispatch({
        type: 'createMessage/loadMessageFromCookie',
        payload: {}
      });
    }

    return () => {
      dispatch({
        type: 'createMessage/clearData',
        payload: {}
      });
      dispatch({
        type: 'campaignList/clearData',
        payload: {}
      });
      dispatch({
        type: 'createCampaign/clearData',
        payload: {
          deleteSession: search.campaign,
        }
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (search && campaign?.id) {
      const relatedCampaign = campaign?.translations[LanguageConfig.english];
      if (!relatedCampaign) {
        return
      }
      relatedCampaign.id = campaign?.id;
      relatedCampaign.pk = campaign?.pk;
      relatedCampaign.name = relatedCampaign?.generalName;
      dispatch({
        type: 'createMessage/updateMessageState',
        payload: {
          relatedCampaign: relatedCampaign,
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign]);

  const stepContent = [
    <CreateMessageStepOne />,
    <CreateMessageStepTwo />,
    <CreateMessageStepThree />,
    <CreateMessageStepFour />,
  ];

  const getButtonsWithType = () => {
    const backGoMessageList = {
      text: 'Go to message section',
      action: () => history.push('/messages'),
      requires: PermissionCodes.changeMessage,
    };
    const buttons = [backGoMessageList];
    if (!search?.type && !search?.campaign) {
      buttons.push({
        text: 'View details',
        action: () => history.push(`/messages/${messagePK}/`),
      });
      return buttons;
    }
    let text = 'Back to campaign listing page';
    let locationParm = {
      pathname: '/campaigns',
      hash: URLFragments.list,
    };

    if (search?.type && search.id) {
      switch (search.type) {
        case CampaignType.couponsCampaign:
          text = 'Back to coupon set listing page';
          locationParm = {
            pathname: '/coupons',
            hash: TAB_LIST_OF_COUPON_SETS,
          };
          break;
        case CampaignType.earningRulesCampaign:
          text = 'Back to earning rule listing page';
          locationParm = {
            pathname: '/earns',
          };
          break;
        default:
          break;
      }
    }
    buttons.unshift({ text: text, action: () => history.push(locationParm) });
    return buttons;
  };

  return (
    <BaseForm
      defaultValues={message}
      hasUpdatedDefaultValues={hasUpdatedDefaultValues}
      formHasSubmitted={formHasSubmitted}
      tempSave={(save, getValues) => {
        dispatch({
          type: 'createMessage/saveOrRemoveMessageFromCookie',
          payload: {
            save: save,
            values: getValues(),
          },
        });
      }}
      showFinishPop={scheduledMessage}
      nextStepConfig={{
        title: 'Message Created!',
        description:
          'Campaign and message are ready. Come back later to check the campaign result.',
        steps: {
          stepNames:
            !search?.type || campaign?.pk + '' !== search.campaign + ''
              ? null
              : getCmapaignCreateSucessStepBar(search.type, search?.id),
          current: NOTIFY_YOUR_CUSTOMER,
        },
        buttons: getButtonsWithType(),
      }}
      sessionDataKey={sessionDataKey}
      content={stepContent}
      currentStep={currentStep}
      stepsConfig={stepConfig}
      breadcrumb={
        <CustomBreadcrumb
          name={
            params.id
              ? message.name && message.name !== ''
                ? message.name
                : 'Edit Message'
              : null
          }
        />
      }
      caution={{
        detail: '', //Create a message to get in touch with  your customers. You can send a message through different channels.
        title: `${
          params.id
            ? message.name && message.name !== ''
              ? message.name
              : 'Edit Message'
            : 'Create Message'
        }`,
      }}
    />
  );
}

const mapPropsToState = (state) => ({
  stepConfig: state.createMessage.stepConfig,
  currentStep: state.createMessage.currentStep,
  scheduledMessage: state.createMessage.scheduledMessage,
  messagePK: state.createMessage.message.pk,
  campaign: state.createCampaign.campaign,
  message: state.createMessage.message,
  hasUpdatedDefaultValues: state.createMessage.hasUpdatedDefaultValues,
  formHasSubmitted: state.createMessage.formHasSubmitted,
});

export default connect(mapPropsToState)(CreateMessage);
