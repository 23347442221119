import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import Loading from '../base/Loading';
import ContentSections from '../base/ContentSections';
import { OnlyContinueButton } from '../base/BottomStepComponent';
import ItemTypeSet, { MIN_WIDTH, SPACE_BETWEEN } from '../base/ItemTypeSet';
import { validate } from '../../containers/record/records/CreateTransactionValidate';
import { TRANSACTION_RECORD_TYPE } from '../../containers/record/records/TransactionList';
import { transactionQueryKey } from '../../components/pointRecord/RecordsUrlConfig';
import OnlineImage from '../../assets/images/transaction/online.svg';
import OfflinePOSImage from '../../assets/images/transaction/offlinePOS.svg';
import offlineRewardClaimImage from '../../assets/images/transaction/offlineRewardClaim.svg';
import RedirectToShopifyPrompt from './RedirectToShopifyPrompt';
import { APIStatus } from '../../config/CustomEnums';
import {
  getScrollbarWidth,
  useMainContentWidth
} from '../../utils/ScreenUtil';


function CreateTransactionStepOne({
  status
}) {
  const queryString = require('query-string');
  const dispatch = useDispatch();
  const history = useHistory();

  const { watch, getValues, setValue, formState, clearErrors, setError } = useFormContext();
  const { errors } = formState;

  const selectedTypeField = 'transactionDisplayType';
  const selectedType = watch(selectedTypeField);
  const [showRedirectPrompt, setShowRedirectPrompt] = useState(false);

  const stepChangeAction = () => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 0,
      isBack: false,
    });
    dispatch({
      type: 'createTransaction/stepChange',
      payload: {
        isBack: false,
        step: 0,
        isValid
      },
    });
  };

  const transactionTypeList = [
    // {
    //   id: TRANSACTION_RECORD_TYPE.TYPE_ONLINE,
    //   title: 'Online',
    //   description: 'Online shopify transaction',
    //   image: OnlineImage,
    // },
    {
      id: TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS,
      title: 'Offline POS',
      description: 'Physical store transaction',
      image: OfflinePOSImage,
    },
    // {
    //   id: TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM,
    //   title: 'Offline Reward Claim',
    //   description: 'Physical store transaction',
    //   image: offlineRewardClaimImage,
    // },
  ];
  const mainContentWidth = useMainContentWidth();
  const fullContainerWith = mainContentWidth - 80 - 60 - getScrollbarWidth();
  const sections = [
    status === APIStatus.calling ? (
      <Loading />
    ) : (
      <div className="create-point-step-one-title">
        <label className="step-one-title">Transaction Type</label>
        <div
          className="point-record-step-type-area transaction-step-type-area"
          style={{
            justifyContent:
              fullContainerWith < (MIN_WIDTH + SPACE_BETWEEN) * 2
                ? 'center'
                : 'flex-start',
          }}
        >
          {transactionTypeList.map((item, index) => {
            return (
              <ItemTypeSet
                key={index}
                item={item}
                selected={selectedType}
                onClick={(id) => {
                  setValue(selectedTypeField, id, { shouldDirty: true });
                }}
              />
            );
          })}
        </div>
      </div>
    ),
  ];

  return (
    <>
      <ContentSections
        sections={sections}
        hidePreview={true}
      />
      <OnlyContinueButton
        continueAction={() => {
          if (selectedType === TRANSACTION_RECORD_TYPE.TYPE_ONLINE) {
            setShowRedirectPrompt(true);
          } else {
            stepChangeAction();
          }
        }}
        disabledContinue={!selectedType}
      />
      <RedirectToShopifyPrompt
        show={showRedirectPrompt}
        onHide={() => {
          setShowRedirectPrompt(false);
        }}
        onConfirm={() => {
          setShowRedirectPrompt(false);
          history.push({
            pathname: '/transactions',
            search: queryString.stringify({
              [transactionQueryKey.page]: 1,
              [transactionQueryKey.rank]: true,
              [transactionQueryKey.searchKey]: '',
              [transactionQueryKey.tab]: 'all',
            }),
          });
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  status: state.loading.status,
});

export default connect(mapPropsToState)(CreateTransactionStepOne);
