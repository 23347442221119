import { getInFilterValue } from '../utils';
import { BaseHelper } from './BaseGraphQLAPIHelper';

const CUSTOMER_CONTENT = `
id
pk
ssoUid
firstName
lastName
nickname
membershipId
pointAccount {
  id
  pk
  totalPointsEarned
  balance
  totalPointsUsed
  totalPointsExpired
  currentLevel {
    pk
    id
    levelName
  }
  currentLevelRenewDatetime
  currentLevelExpiredInXDays
}
mobilePhoneNumberCountryCode
mobilePhoneNumberSubscriberNumber
emailAddress
isAssignedAsTestingCustomer
gender {
  label
  value
}
dateOfBirth
address
company
signUpChannel
referrer {
  id
}
referredByCampaign {
  id
}
isForcedInactive
preferredMessageLanguage
creationDate
lastModifiedDate
hasAgreedDirectMarketing
groups {
  edges {
    node {
      id
      name
    }
  }
}
segments {
  edges {
    node {
      id
      name
      conditionGroups {
        edges {
            node {
                levelCharacteristic {
                  id
                  levels(first: 2) {
                      edges {
                          node {
                              id
                              levelName
                              translations(first: 2) {
                                  edges {
                                      node {
                                          levelName
                                          levelBenefitUrl
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
            }
        }
      }
    }
  }
}
`;

export const getCustomerGroups = () => {
  const query = `{
    customerGroups(first: 100) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          name
        }
      }
    }
  }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getAllCustomers = (afterCursor, search = {}, moreSearch) => {
  let searchValue = '';
  if (search && Object.keys(search).length > 0) {
    searchValue =
      ', ' +
      Object.keys(search)
        .map((key) => `${key}: ${search[key]}`)
        .join(', ');
  }
  let orderBy = "pk"
  if(moreSearch?.sort) {
    orderBy = moreSearch.sort;
  }
  let pageItemCount = 100;
  if (moreSearch?.pageItemCount) {
    pageItemCount = moreSearch.pageItemCount;
  }
  const query = `{
    customers(first: ${pageItemCount}, after:"${afterCursor}", orderBy:"${orderBy}", ${searchValue}) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          ssoUid
          firstName
          lastName
          nickname
          membershipId
          pointAccount {
            id
            pk
            totalPointsEarned
            balance
            totalPointsUsed
            totalPointsExpired
            currentLevel {
              pk
              id
              levelName
            }
            currentLevelRenewDatetime
            currentLevelExpiredInXDays
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCustomersByPage = (
  afterCursor,
  rank = 'true',
  search,
  groups,
  segments,
  levels,
  age,
  genders,
  startDate,
  endDate,
  moreSearch,
  sort
) => {
  // firstName_Icontains: "", groups_Name: "", gender_In: "", dateOfBirth_Month: "", mobilePhoneNumberCountryCode_In: "852,853,86", ageBetween:"0,2",signUpAnniversaryBetween: "0,2", level: "Level 2"

  let order = rank ? '-pk' : 'pk';

  if (sort) {
    order = sort;
  }

  let searches = [];
  if (search) {
    searches.push(`customerName: "${search}"`);
  }
  if (groups) {
    searches.push(`groupsIn: "${groups}"`);
  }
  if (segments) {
    searches.push(`segmentsIn: "${segments}"`);
  }
  if (levels) {
    searches.push(`levelIn: "${levels}"`);
  }
  if (age) {
    searches.push(`ageRange: "${age}"`);
  }
  if (genders) {
    searches.push(`genderIn: "${genders}"`);
  }
  // if (startDate && endDate) {
  //   searches.push(`dateOfBirthRange: "${startDate},${endDate}"`);
  // } else if (startDate) {
  //   searches.push(`dateOfBirth: "${startDate}"`);
  // } else if (endDate) {
  //   searches.push(`dateOfBirth: "${endDate}"`);
  // }
  if (startDate) {
    searches.push(`dateOfBirthGte: "${startDate}"`);
  }
  if (endDate) {
    searches.push(`dateOfBirthLte: "${endDate}"`);
  }

  // console.log('@@127: ', searches);
  if (moreSearch) {
    searches = [...searches, ...moreSearch];
  }

  // console.log('@@104: ', searches.join(', '));
  const query = `{
    customers(first: 20, after:"${afterCursor}", orderBy:"${order}"${
    searches.length > 0 ? ',' + searches.join(', ') : ''
  }) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ${CUSTOMER_CONTENT}
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteCustomers = (ids) => {
  const query = `
    mutation DeleteCustomers($input: DeleteCustomersInput!) {
      deleteCustomers(input: $input) {
        success
        deleteFail
      }
    }
  `;
  const variables = {
    input: {
      ids: ids,
    },
  };

  // console.log('@@338: ', query);

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateCustomer = (values) => {
  const query = `
  mutation UpdateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      node {
        id
        pk
        firstName
        lastName
        nickname
        membershipId
        ssoUid
        mobilePhoneNumberCountryCode
        mobilePhoneNumberSubscriberNumber
        emailAddress
        gender {
          label
          value
        }
        dateOfBirth
        address
        company
        signUpChannel
        referrer {
          id
        }
        referredByCampaign {
          id
        }
        isForcedInactive
        preferredMessageLanguage
        creationDate
        lastModifiedDate
      }
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: values,
  };

  // console.log('@@338: ', query);

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOneCustomer = (id) => {
  const query = `{
    customer(id: "${id}") {
      id
      pk
      firstName
      lastName
      nickname
      ssoUid
      signUpMethod
      membershipId
      mobilePhoneNumberCountryCode
      mobilePhoneNumberSubscriberNumber
      emailAddress
      isAssignedAsTestingCustomer
      gender {
        label
        value
      }
      dateOfBirth
      address
      company
      signUpChannel
      referrer {
        id
        pk
        firstName
        lastName
      }
      referredByCampaign {
        id
        pk
        name
      }
      isForcedInactive
      preferredMessageLanguage
      creationDate
      lastModifiedDate
      pointAccount {
        id
        pk
        totalPointsEarned
        balance
        totalPointsUsed
        totalPointsExpired
        currentLevel {
          pk
          id
          levelName
        }
        currentLevelRenewDatetime
        currentLevelExpiredInXDays
      }
      totalSpending
      averageOrderValue
      totalNumberOfOrders
      daysFromLastOrder
      hasAgreedDirectMarketing
      groups {
        edges {
          node {
            id
            pk
            name
          }
        }
      }
      segments {
        edges {
          node {
            id
            pk
            name
            conditionGroups {
              edges {
                node {
                  levelCharacteristic {
                    id
                    levels(first: 2) {
                      edges {
                        node {
                          id
                          levelName
                          translations(first: 2) {
                            edges {
                              node {
                                levelName
                                levelBenefitUrl
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      coupons(first: 20, isExpired: false, isUsed: false orderBy: "-pk") {
        edges {
          node {
            id
            pk
            isForcedInactive
            store {
              name
            }
            dateOfPurchase
            dateOfEarning
            dateOfGrant
            serialNumber
            isUsed
            isExpired
            isActive
            effectiveDate
            expiryDate
            template {
              id
              pk
              name
            }
          }
        }
      }
    }
  }
  `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCustomerActivityLog = (ssoUid) => {
  // activityTypeIn: "CUSTOMER_POINTS_EARNED,CUSTOMER_POINTS_USED,CUSTOMER_POINTS_EXPIRED,CUSTOMER_LEVEL_CHANGED,CUSTOMER_LEVEL_RETAINED"
  const query = `{
    customerActivityLogs(first: 50, customerSsoUid: "${ssoUid}", orderBy: "-pk") {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          activityType
          customerSsoUid
          customerPointAndLevelLogTitle
          logContentId
          logContentType {
            appLabel
            model
          }
          creationDate
        }
      }
    }
  }
  `;

  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const activeCustomer = (values) => {
  const query = `
  mutation ActivateCustomer($input: ActivateCustomerInput!) {
    activateCustomer(input: $input) {
      node {
        id
        pk
        isForcedInactive
      }
    }
  }
  `;
  const variables = {
    input: values,
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deactiveCustomer = (values) => {
  console.log(values);
  const query = `
  mutation DeactivateCustomer($input: DeactivateCustomerInput!) {
    deactivateCustomer(input: $input) {
      node {
        id
        pk
        isForcedInactive
      }
    }
  }
  `;
  const variables = {
    input: values,
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};
