import {
  getCampaignList,
  getCampaignCategoryList,
  deleteCampaign,
  updateCampaign,
} from '../services/CampaignAPIHelper';
import { sessionDataKey } from './CreateCampaignModel';
import {
  convertCursorToNumber,
  convertNumberToCursor,
  getObjectFromSessionStorage,
  saveToSessionStorage,
} from '../utils';
import {
  checkCampaignIsExpired,
  getCampignListDisplayTime,
} from '../utils/TimeFormatUtil';
import { loading, apiWithResponseHandle } from './LoadingUtil';
import { MessageChannel, PublishTagType } from '../config/CustomEnums';
import { CampaignType, SESSION_KEYS } from '../config/CustomEnums';
import { createModel } from './BaseModel';

export const FIRST_TIME_ENTER_CAMPAIGN = 'FIRST_TIME_ENTER_CAMPAIGN';

const SHOW_RESUME_POP = 'SHOW_RESUME_POP';

const getCampaignMessageChannel = (channels) => {
  if (!channels || channels.length === 0) {
    return '-';
  }
  const newChannels = channels.map((channel) => {
    const channelLowercase = channel.toLowerCase();
    if (channelLowercase.includes('email')) {
      return MessageChannel.email;
    }
    if (channelLowercase.includes('push')) {
      return MessageChannel.push;
    }
    if (channelLowercase.includes('sms')) {
      return MessageChannel.sms;
    }
    if (channelLowercase.includes('web')) {
      return MessageChannel.web;
    }
    return channel;
  });
  const channelString = newChannels.toString();
  return channelString || '-';
};

const getFormatedStatus = (campaign) => {
  if (!campaign.isPublished) {
    return PublishTagType.unPublished;
  }

  const isExpired = checkCampaignIsExpired(campaign.endDate);
  if (!isExpired) {
    return PublishTagType.published;
  }
  return PublishTagType.expired;
};

const getInitialState = () => ({
  listFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'Name', fieldName: 'name', orderField: 'name' },
    { displayName: 'Message Channel', fieldName: 'displayMessageChannels' },
    { displayName: 'Brand', fieldName: 'brand', orderField: 'brand' },
    {
      displayName: 'Target customers',
      fieldName: 'numberOfCustomersVisible',
      orderField: 'targetCustomers',
    },
    {
      displayName: 'Active Period',
      fieldName: 'displayActivePeriod',
      orderField: 'startDate',
    },
    {
      displayName: 'Visible Period',
      fieldName: 'displayVisiblePeriod',
      orderField: 'displayStartDate',
    },
    { displayName: 'Display order', fieldName: 'order', orderField: 'displayPriority' },
    { displayName: 'Status', fieldName: 'displayStatus' },
  ],
  campaignList: [],
  totalCount: 0,
  totalPage: 0,
  pageInfo: {
    startCursor: 0,
    endCursor: 0,
  },
  checkedList: [],
  categories: [],
  segments: [],
  avaliableCampaignNamesList: [],
  allCampaignList: [],
  resume: {
    showResumeButton: false,
    showResumePop: false,
  },
});

export const getTypeDisplay = (type) => {
  if (type === CampaignType.couponsCampaign) {
    return 'Coupon';
  }
  if (type === CampaignType.earningRulesCampaign) {
    return 'Earning Rule';
  }
  return 'General';
};

const parseCampaignList = (list) => {
  return list.map((campaign) => {
    const node = campaign.node;
    const translationsEdges = node.translations.edges;
    let translations = {};
    if (translationsEdges.length > 0) {
      translationsEdges.forEach((item) => {
        const node = item.node;
        translations[node.language] = node.language;
      });
    }
    return {
      id: node.id,
      pk: node.pk,
      name: node.name,
      displayEndDate: node.displayEndDate,
      displayStartDate: node.displayStartDate,
      endDate: node.endDate,
      isPublished: node.isPublished,
      startDate: node.startDate,
      status: node.status,
      type: node.type,
      typeDisplay: getTypeDisplay(node.type),
      brand: node.brand?.name,
      numberOfCustomersVisible: node.numberOfCustomersVisible,
      lastModifiedDate: node.lastModifiedDate,
      publicationDate: node.publicationDate,
      messageChannels: node.messageChannels,
      translations,
      displayMessageChannels: getCampaignMessageChannel(node.messageChannels),
      displayActivePeriod: getCampignListDisplayTime(
        node.startDate,
        node.endDate,
      ),
      order: node.displayPriority,
      coverPhoto: node.coverPhoto,
      displayVisiblePeriod: getCampignListDisplayTime(
        node.displayStartDate,
        node.displayEndDate,
      ),
      displayStatus: getFormatedStatus(node),
    };
  });
};

export default createModel({
  namespace: 'campaignList',
  params: {
    parse: (data) => parseCampaignList(data.campaigns.edges),
    dataKey: SESSION_KEYS.CAMPAIGN_LIST_SESSION_KEY,
    deleteAPI: deleteCampaign,
    listAPI: getCampaignList,
    parseDetail: (data) => {},
    objectKey: 'campaigns',
  },
  states: getInitialState(),
  reducers: {},

  effects: {
    *campaignResume({ payload }, { select, put }) {
      const campaign = getObjectFromSessionStorage(sessionDataKey.objectKey);
      if (!campaign?.campaignType) {
        yield put({
          type: 'updateState',
          payload: {
            resume: { showResumeButton: false, showResumePop: false },
          },
        });
        saveToSessionStorage(SHOW_RESUME_POP, null);
        return;
      }
      const showResumePop = getObjectFromSessionStorage(SHOW_RESUME_POP);
      const firstTimeEnterCampaign = getObjectFromSessionStorage(
        FIRST_TIME_ENTER_CAMPAIGN,
      );
      if (!firstTimeEnterCampaign) {
        saveToSessionStorage(FIRST_TIME_ENTER_CAMPAIGN, true);
      }
      yield put({
        type: 'updateState',
        payload: {
          resume: {
            showResumeButton: true,
            showResumePop:
              showResumePop !== false && firstTimeEnterCampaign === false,
          },
        },
      });
    },

    *resumeAction({ payload }, { select, put }) {
      const hideResumePop = payload.hideResumePop;
      saveToSessionStorage(SHOW_RESUME_POP, !hideResumePop);
      const showResumeButton = yield select(
        (state) => state.campaignList.resume.showResumeButton,
      );
      yield put({
        type: 'updateState',
        payload: {
          resume: {
            showResumeButton: showResumeButton,
            showResumePop: false,
          },
        },
      });
    },

    getCampaignList: [
      function* ({ payload }, { put }) {
        const page = payload.page || 1;
        const reverse = payload.reverse;
        const type = payload.type || '';
        const searchKey = payload.searchKey;
        const pageCursor = page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const ssoUid = payload.ssoUid || '';
        console.log('@@251: ', type);
        const serviceArgs = [
          getCampaignList,
          pageCursor,
          {
            type: type === CampaignType.allTypes ? '' : type,
            reverse,
            filterName: searchKey || '',
            ssoUid,
            isAvailable: payload.isSelectorLoad,
            others: payload,
          }
        ];

        function* onSuccess(data) {
          const campaigns = data.campaigns;
          yield put({
            type: 'updateListData',
            payload: {
              ...payload,
              data: parseCampaignList(campaigns.edges),
              totalCount: campaigns.totalCount,
              pageInfo: campaigns.pageInfo,
            },
          });
          // if (payload.isSelectorLoad) {
          //   yield put({
          //     type: 'assembleAllCampaignList',
          //     payload: {
          //       list: campaigns.edges,
          //       page,
          //     },
          //   });
          // } else {
          //   yield put({
          //     type: 'assembleCampaignList',
          //     payload: {
          //       list: campaigns.edges,
          //       totalCount: campaigns.totalCount,
          //       pageInfo: campaigns.pageInfo,
          //     },
          //   });
          // }
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    getCamapigncategories: [
      function* ({ payload }, { put }) {
        const serviceArgs = [getCampaignCategoryList, payload];
        function* onSuccess(data) {
          yield put({
            type: 'assembleCategory',
            payload: { list: data.campaignCategories.edges },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    duplicate: [
      function* ({ payload }, { put }) {
        console.log('duplicate campaign', payload);
        yield put({
          type: 'createCampaign/duplicateCampaign',
          payload: {
            campaignId: payload.data.pk,
            afterAction: payload.afterAction,
          },
        });
      },
      { type: 'takeLatest' },
    ],

    delete: [
      function* ({ payload }, { put }) {
        yield put({ type: 'createCampaign/deleteCampaigns', payload });
      },
      { type: 'takeLatest' },
    ],

    updateOrder: [
      function* ({ payload }, { put }) {
        const { data, afterAction } = payload;
        const serviceArgs = [updateCampaign, data];
        function* onSuccess(data) {
          yield afterAction();
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

  },
});
