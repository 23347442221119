import { uploadFileHelper } from '../services/UploadFileHelper';
import { delay, createAction } from '../utils';

export const IMAGE_TYPES = {
  TYPE_URL: 'TYPE_URL',
  TYPE_UPLOADING: 'TYPE_UPLOADING',
  TYPE_UPLOADED: 'TYPE_UPLOADED',
  TYPE_FAILED: 'TYPE_FAILED',
};

export const ERROR_TYPE = {
  photoSizeIsTooSmall:
    'Image dimensions could not be smaller than 600 x 600 px.',
  photoSizeIsTooBig: 'Image dimensions could not be bigger than 2MB.',
  wrongPhotoType:
    'Please upload a valid picture. The file you uploaded is not a picture or the picture is corrupted',
  photoUploadFailed: 'Unable to upload image. Please try again.',
};

const handleImageList = (imageList) => {
  let handledImageList = [];
  imageList.map((img) => {
    handledImageList.push(img.split('/').slice(-1)[0]);
  });
  return handledImageList;
};

export default {
  namespace: 'uploadFiles',
  state: {
    imageList: [],
    coverImage: '',
    otherImageList: [],
    uploadedImages: {},
    failedImage: {},
    importedFile: '',
    importFileOriginName: '',
  },

  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    updateCoverImage(state, { payload }) {
      const { coverImage } = payload;
      return { ...state, coverImage: coverImage.split('/').slice(-1)[0] };
    },
    addOtherImageToList(state, { payload }) {
      const { otherImage } = payload;
      console.log(otherImage);
      return {
        ...state,
        otherImageList: [...state.otherImageList, otherImage],
      };
    },
    uploadOtherImageList(state, { payload }) {
      const { otherImageList } = payload;
      return { ...state, otherImageList: handleImageList(otherImageList) };
    },
    updateUploadedImages(state, { payload }) {
      const { uuid, url } = payload;
      return {
        ...state,
        uploadedImages: {
          ...state.uploadedImages,
          [uuid]: url,
        },
      };
    },
    finishUploadImages(state, { payload }) {
      const { uuid } = payload;
      const uploadedImages = {};
      const oldUploadedImages = state.uploadedImages;
      Object.keys(state.uploadedImages).forEach((key, index) => {
        if (key !== uuid) {
          uploadedImages[key] = oldUploadedImages[key];
        }
      });
      return {
        ...state,
        uploadedImages: { ...uploadedImages },
      };
    },
  },
  effects: {
    *uploadImages({ payload }, { call, put }) {
      const { imageList } = payload;
      const response = yield call(uploadFileHelper.uploadImages, imageList);
      if (response.status < 300) {
        yield put({
          type: 'updateState',
          payload: { imageList: response.data },
        });
      }
    },
    *uploadCroppedImage({ payload }, { call, put, select }) {
      const { croppedImage } = payload;
      const { imageName } = croppedImage;
      const response = yield call(
        uploadFileHelper.uploadImage,
        croppedImage,
      );
      // yield call(delay, 3000);
      // yield put({
      //   type: 'updateUploadedImages',
      //   payload: {
      //     uuid: imageName,
      //     url: 'https://nginx.aillia.hkdev.motherapp.com/media/b2f8795f-e6c1-4550-a7da-206f68d3fc3f.png',
      //   },
      // });
      if (response.status < 300) {
        const imageData = response.data;
        yield put(
          createAction('updateUploadedImages')({
            uuid: imageName,
            url: Object.values(imageData)[0],
          }),
        );
      } else {
        console.log(response);
        yield put(
          createAction('updateState')({
            failedImage: {
              [imageName]: croppedImage,
            },
          }),
        );
        yield put(
          createAction('updateUploadedImages')({
            uuid: imageName,
            url: croppedImage.imageDataUrl,
          }),
        );
      }
    },
    *uploadFile({ payload }, { call, put, select }) {
      const { file } = payload;
      const response = yield call(
        uploadFileHelper.uploadFile,
        file,
      );
      if (response.status < 300) {
        console.log('@@146: ', response);
        yield put({
          type: 'updateState',
          payload: { importedFile: response.data },
        });
      }
    },
  },
};
