import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { POINT_RECORD_TYPE } from './CreatePointRecordStepOne';
import CustomEditor from '../base/CustomEditor';
import { hasError, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import { CustomTitleLabel } from '../../components/earning/CustomBaseComponments';
import { LanguageConfig } from '../../config/CustomEnums';


function CreatePointRecordDetail({
}) {
  const dispatch = useDispatch();

  const { watch, getValues, setValue, formState, clearErrors, setError } = useFormContext();
  const { errors } = formState;
  const pointTypeField = 'pointType';
  const pointsField = 'points';
  const remarksField = 'remarks';
  const type = watch(pointTypeField);
  const points = watch(pointsField);
  const remarks = watch(remarksField);

  const sectionTitle = (type) => {
    if (type === POINT_RECORD_TYPE.TYPE_ADD) {
      return 'ADD POINT';
    }
    if (type === POINT_RECORD_TYPE.TYPE_REMOVE) {
      return 'REMOVE POINT';
    }
  };

  return (
    <>
      <div className="first-section-title">{sectionTitle(type)}</div>
      <div>
        <CustomTitleLabel title="Value" />
        <div className="d-flex">
          <input
            type="text"
            className={`day-input-fields ${hasError(errors, pointsField) ? 'error-field' : ''}`}
            value={points}
            onInput={(e) => {
              let data = e.target.value;
              data = data.replace(/[^0-9.]/g, '');
              setValue(pointsField, data, { shouldDirty: true });
            }}
          />
          <label className="days-label">points</label>
        </div>
        <ReactHookFormErrorMessage errors={errors} id={pointsField} />
      </div>
      {/* {isEdit ? editDetailExtraSection(type) : null} */}
      <div>
        <CustomTitleLabel title="Remarks (optional)" />
        <CustomEditor
          initialValue={remarks}
          onValueChange={(data) => {
            setValue(remarksField, data, { shouldDirty: true });
          }}
        />
      </div>
    </>
  );
}

const mapPropsToState = (state) => ({
  expiryDate: state.createPointRecord.pointRecord.expiryDate,
  usedDate: state.createPointRecord.pointRecord.usedDate,
});

export default connect(mapPropsToState)(CreatePointRecordDetail);
