export const CampaignType = {
  allTypes: 'all',
  generalMessageCampaign: 'GENERAL_MESSAGE_CAMPAIGN',
  earningRulesCampaign: 'EARNING_CAMPAIGN',
  couponsCampaign: 'COUPON_CAMPAIGN',
};

export const COUPON_SET_TYPES = {
  SYSTEM_GENERATED: 'SYSTEM_GENERATED',
  IMPORT_EXISTING_COUPON: 'IMPORT_EXISTING_COUPON',
};
export const COUPON_REDEMPTION_METHOD = {
  CUSTOMER_DIRECT_USE: 'CUSTOMER_DIRECT_USE',
  MERCHANT_APP_SCAN_COUPON_QR_CODE: 'MERCHANT_APP_SCAN_COUPON_QR_CODE',
  USERS_SCAN_STORE_QR_CODE: 'USERS_SCAN_STORE_QR_CODE',
  USERS_COPY_THE_DISCOUNT_CODE: 'USERS_COPY_THE_DISCOUNT_CODE',
  INPUT_STORE_STAFF_CODE: 'INPUT_STORE_STAFF_CODE', 
};

export const COUPON_FORMATS = {
  COUPON_QR_CODE: 'COUPON_QR_CODE',
  COUPON_BAR_CODE: 'COUPON_BAR_CODE',
};

export const COUPON_CODE_TYPE = {
  GENERIC: 'GENERIC',
  UNIQUE: 'UNIQUE',
};

export const StepStatus = {
  error: 'error',
  processing: 'processing',
  finished: 'finished',
  waiting: 'waiting',
};

export const LanguageConfig = {
  english: 'en',
  simplifiedChinese: 'zh-Hans',
  traditionalChinese: 'zh-Hant',
};

export const LanguageUpperConfig = {
  english: 'EN',
  simplifiedChinese: 'ZH_HANS',
  traditionalChinese: 'ZH_HANT',
};

export const EarningRuleType = {
  allTypes: 'all',
  generalPurchase: 'GENERAL_PURCHASE',
  memberReferral: 'MEMBER_REFERRAL',
  birthday: 'BIRTHDAY',
  qrCodeScanning: 'QR_CODE_SCANNING',
  gpsCheckIn: 'GPS_CHECK_IN',
  newMember: 'NEW_MEMBER',
  fillingForm: 'FILLING_FORM',
};

export const EarningRuleTypeKey = {
  All: 'allTypes',
  GENERAL_PURCHASE: 'generalPurchase',
  MEMBER_REFERRAL: 'memberReferral',
  BIRTHDAY: 'birthday',
  QR_CODE_SCANNING: 'qrCodeScanning',
  GPS_CHECK_IN: 'gpsCheckIn',
  NEW_MEMBER: 'newMember',
  FILLING_FORM: 'fillingForm'
};

export const LanguageTabTransLation = {
  english: 'English',
  simplifiedChinese: '简体',
  traditionalChinese: '繁体',
};

export const LanguageTransLation = {
  en: 'English',
  'zh-Hans': '简体',
  'zh-Hant': '繁体',
};

export const AmOrPm = {
  am: 'am',
  pm: 'pm',
};

export const SavingStatus = {
  onSaving: 'onSaving',
  onFinished: 'onFinished',
};

export const APIStatus = {
  success: 'success',
  calling: 'calling',
  failed: 'failed',
  none: 'none',
};

export const MessageChannel = {
  push: 'Push',
  email: 'Email',
  sms: 'SMS',
  web: 'Web',
  inbox: 'Inbox',
};

export const CheckStatus = {
  initOrNotChecked: -1,
  checkedWithFail: 0,
  checkedWithSuccess: 1,
};

export const SavedStatus = {
  init: -1,
  savedWithFail: 0,
  savedWithSuccess: 1,
};

export const EarningRuleRewardType = {
  coupons: 'COUPON',
  points: 'POINTS',
};

export const EarningRuleDefinitionType = {
  allFields: 'ALLFIELD',
  mandatoryFields: 'MANDATORYFIELD',
  oneField: 'ONEFIELD',
};

export const StatusTag = {
  active: 'Active',
  activeRequired: 'Active(Acquired)',
  deactive: 'Deactive',
  inactive: 'Inactive',
};

export const PublishTagType = {
  published: 'Published',
  unPublished: 'Unpublished',
  expired: 'Expired',
};

export const DownloadTag = {
  completed: 'Exported',
  cancelled: 'Cancelled',
  downloading: 'Exporting',
  importing: 'Importing',
  importCompleted: 'Imported',
  error: 'Error',
  generating: 'Generating',
  generated: 'Generated',
  pendingForImport: 'Pending',
  pendingForExport: 'Pending',
  pendingForGenerate: 'Pending',
};

export const MessageTag = {
  scheduled: 'Scheduled',
  saved: 'Saved',
  sent: 'Sent',
  sending: 'Sending',
  error: 'Failed',
};

export const ConditionTag = {
  EQUAL_TO: 'Equal to',
  NOT_EQUAL_TO: 'Not equal to',
  IS_EMPTY: 'Is empty',
  IS_NOT_EMPTY: 'Is not empty',
  BETWEEN: 'Between',
  LESS_THAN: 'Less than',
  GREATER_THAN: 'greater than',
  IN: 'In',
};

export const DaysTag = {
  '1_DAY': '1 day',
  '7_DAYS': '7 days',
  '1_MONTH': '1 month',
  '3_MONTHS': '3 months',
  '6_MONTHS': '6 months',
  '1_YEAR': '1 year',
};

const domain =
  process.env.REACT_APP_IMAGE || 'https://nginx.aillia.hkdev.motherapp.com';

export const ImageDomain = `${domain}/media/`;

export const GenderOptions = [
  { pk: 'MALE', name: 'Male', value: 'MALE' },
  { pk: 'FEMALE', name: 'Female', value: 'FEMALE' },
  { pk: 'NOT_DISCLOSED', name: 'Prefer not to say', value: 'NOT_DISCLOSED' },
];

export const MonthOptions = [
  { pk: '1', name: 'January', value: '1' },
  { pk: '2', name: 'February', value: '2' },
  { pk: '3', name: 'March', value: '3' },
  { pk: '4', name: 'April', value: '4' },
  { pk: '5', name: 'May', value: '5' },
  { pk: '6', name: 'June', value: '6' },
  { pk: '7', name: 'July', value: '7' },
  { pk: '8', name: 'August', value: '8' },
  { pk: '9', name: 'September', value: '9' },
  { pk: '10', name: 'October', value: '10' },
  { pk: '11', name: 'November', value: '11' },
  { pk: '12', name: 'December', value: '12' },
];

export const CountryCodeOptions = [
  { pk: 852, name: '+852', value: 852 },
  { pk: 853, name: '+853', value: 853 },
  { pk: 86, name: '+86', value: 86 },
];

export const BANNER_CONTENT_TYPE = {
  ALL_TYPE: 'all',
  CAMPAIGN: 'CAMPAIGN',
  PRODUCT: 'PRODUCT',
};

export const POINT_RECORD_TYPES = [
  { key: 'TYPE_POINT_ADD', name: 'Add Points' },
  { key: 'TYPE_POINT_REMOVE', name: 'Remove Points' },
];

export const COUPON_TEMPLATE_TYPE = {
  storeCode: 'STORE_CODE',
  couponCode: 'COUPON_CODE',
  couponQRCode: 'COUPON_QR_CODE',
  couponCodeImage: 'COUPON_CODE_IMAGE',
};

export const COUPON_TEMPLATE_TYPE_NAME = {
  STORE_CODE: 'Store code',
  COUPON_CODE: 'Coupon code',
  COUPON_QR_CODE: 'Coupon QR code',
  COUPON_CODE_IMAGE: 'Coupon code image',
};

export const DELETE_RELATED_SECTIONS = {
  CUSTOMER: [
    'AppInstallation',
    'CampaignParticipationRecord',
    'Coupon',
    'CouponTransaction',
    'PointTransaction',
    'Transaction',
  ],
  SEGMENT: ['Campaign'],
  CUSTOMER_GROUP: ['Campaign', 'ParticipationCustomer'],
  CAMPAIGN: [
    'Message',
    'CampaignParticipationRecord',
    'CouponTransaction',
    'PointsTransaction',
    'Segment Campaign Characteristic',
    'Banner',
  ],
  CAMPAIGN_CATEGORY: ['Campaign'],
  MESSAGE: ['InboxMessage'],
  EARNING_RULE: ['Campaign'],
  COUPON_TEMPLATE: ['Campaign', 'Coupon', 'EarningRule'],
  COUPON: ['CouponTransaction'],
  BRAND: ['Campaign', 'CouponTemplate', 'Store'],
  STORE: ['CampaignParticipationRecord', 'Coupon', 'Transaction'],
  STORE_CATEGORY: ['StoreSubcategory'],
  TRANSACTION: [
    'CampaignParticipationRecord',
    'PurchasedItem',
    'Coupon transaction',
    'Point transaction',
  ],
  POINT_RECORD: [],
  COUPON_RECORD: [],
  ADMIN_GROUP: ['ParticipationAdmin'],
  ADMIN: ['CouponTransaction', 'ImportJob', 'ExportJob'],
  BANNER: [],
};

export const EMAIL_REG =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const SESSION_KEYS = {
  ADMIN_GROUP_SESSION_KEY: 'tempAdminGroup',
  ADMIN_SESSION_KEY: 'tempAdmin',
  STORE_SESSION_KEY: 'tempStore',
  LANGUAGE_SESSION_KEY: 'language',
  LANGUAGE_DATA_SESSION_KEY: 'languages',
  CREATE_CAMPAIGN_SESSION_KEY: 'createCampaign',
  CREATE_CAMPAIGN_END_SESSION_KEY: 'createCampaignStepEnd',
  CREATE_CAMPAIGN_ORIGIN_DATA_SESSION_KEY: 'createCampaignOriginalData',
  CAMPAIGN_LIST_SESSION_KEY: 'campaigns',
  CAMPAIGN_CATEGORY_SESSION_KEY: 'campaignCategoryKey',
  CREATE_CAMPAIGN_CATEGORY_SUCCESS: 'createCampaignCategorySuccess',
  CREATE_CAMPAIGN_CATEGORY_FAILD: 'createCampaignCategoryFailed',
  CAMPAIGN_CATEGORY_ORDER_LAST: 'capaignCategoryLast',
  EARNING_RULES_SESSION_KEY: 'tempEarningRule',
  FILLING_FORM_SESSION_KEY: 'fillingForm',
  FILLING_FORM_DATA_SESSION_KEY: 'fillingForms',
  CREATE_MESSAGE_SESSION_KEY: 'createMessage',
  CREATE_MESSAGE_STEP_END_SESSION_KEY: 'createMessageStepEnd',
  CREATE_MESSAGE_ORIGINAL_DATA_SESSION_KEY: 'createMessageOriginalData',
  CREATE_COUPON_SESSION_KEY: 'createCoupon',
  CREATE_COUPON_END_SESSION_KEY: 'createCouponStepEnd',
  CREATE_COUPON_ORIGIN_DATA_SESSION_KEY: 'createCouponOriginalData',
  CREATE_BRAND_SESSION_KEY: 'createBrand',
  CREATE_BRAND_STEP_END_SESSION_KEY: 'createBrandStepEnd',
  CREATE_BRAND_ORIGINAL_DATA_SESSION_KEY: 'createBrandOriginalData',
  CREATE_POINT_RECORD_STEP_END_SESSION_KEY: 'createPointRecordStepEnd',
  CREATE_POINT_RECORD_ORIGINAL_DATA_SESSION_KEY: 'createPointRecordOriginalData',
};

export const TokenErrors = {
  Expiry: 'Signature has expired',
  DecodingError: 'Error decoding signature',
  RefreshTokenInvalid: 'Invalid refresh token',
  RefreshTokenExpired: 'Refresh token is expired',
  NoPermission: 'You do not have permission to perform this action',
};

export const STORE_LOADING_TYPE = {
  BRAND: 'brand',
  CAMPAIGN: 'campaignIn',
  COUPON: 'couponTemplateIn',
};

export const SIGN_UP_METHOD = {
  MOBILE_PHONE_NUMBER: 'Mobile phone number',
  'GOOGLE-OAUTH2': 'Google OAuth2',
  FACEBOOK: 'Facebook',
  'APPLE-ID': 'Apple ID',
  EMAIL_ADDRESS: 'Email address',
}
