import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ContentSections from '../../../components/base/ContentSections';
import { listItem, listImage, titleForItem } from '../../../components/banners/DisplayItems';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import {
  APIStatus,
  LanguageConfig,
} from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';
import i18n from '../../../I18n';
import CustomRichTextViewer from '../../../components/base/CustomRichTextViewer';


function BrandDetail({
  selectedBrand,
  status,
  languages,
}) {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch({
      type: 'brand/cleanSelectedBrand',
    });
    dispatch({
      type: 'brand/getBrand',
      payload: {
        brandPK: params.id,
      }
    });
    dispatch({
      type: 'language/getList',
      payload: {
        isSelectorLoad: true,
      }
    });
  }, [dispatch, params.id]);

  const renderTabForLanguage = (language) => {
    const isEnglish = language === LanguageConfig.english;
    const brandStoreNames = selectedBrand?.brandStores?.map((store) => {
      let storeTranslations = store.storeTranslations;
      const name = storeTranslations?.[language]?.storeName;
      if (name) {
        return name;
      }
      return store.name;
    }) || [];
    return [
      <>
        <div className="first-section-title">
          {i18n.t('general', { locale: language })}
        </div>
        {listItem('ID', selectedBrand.pk)}
        {listItem(
          i18n.t('name', { locale: language }),
          selectedBrand.translations?.[language]?.name
        )}
        {titleForItem(i18n.t('description_optional', { locale: language }))}
        <CustomRichTextViewer initialValue={selectedBrand.translations?.[language]?.description || '-'} />
        {isEnglish
          ? selectedBrand.coverPhoto
            ? listImage('Logo image', selectedBrand.coverPhoto)
            : listItem('Logo image', '')
          : null}
        {listItem(
          'Related store',
          brandStoreNames.length > 0
            ? brandStoreNames.toString()
            : '-'
        )}
      </>
    ]
  };

  const tabs = [
    {
      name: '',
      content:
        status === APIStatus.calling ? (
          <Loading />
        ) : (
          <div className="scroll-container-common">
            <ContentSections
              languageTabContent={{
                containers: languages?.map((item) => ({
                  container: renderTabForLanguage(item.code),
                  key: item.code,
                  title: item.sourceName,
                })),
              }}
              hidePreview={true}
            />
          </div>
        ),
    },
  ];

  const buttons = [
    <AuthButton
      title="Edit"
      action={() => history.push('edit/')}
      requires={PermissionCodes.changeBrand}
    />
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '', //view message
          title: selectedBrand?.name ? selectedBrand.name : '',
        }}
        breadcrumb={
          <CustomBreadcrumb name={selectedBrand?.name ? `${selectedBrand.name}` : ''} />
        }
        hideTab={true}
        tabs={tabs}
        buttons={buttons}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  selectedBrand: state.brand.selectedBrand,
  status: state.createMessage.createStatus,
  languages: state.language.allList,
});

export default connect(mapPropsToState)(BrandDetail);

