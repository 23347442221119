import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import './TransactionAmountSection.scss';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { hasError, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import { CreateTransactionError } from '../../models/CreateTransactionModel';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import { LanguageConfig } from '../../config/CustomEnums';


function TransactionAmountSection(props) {
  const dispatch = useDispatch();
  const setTotalValue = props.setTotalValue || (() => {});

  const { watch, getValues, setValue, formState, clearErrors, setError } = useFormContext();
  const { errors } = formState;

  const totalValueField = 'totalValue';
  const totalValueFormatField = 'totalValueFormat';
  const totalValue = watch(totalValueField);
  const totalValueFormat = watch(totalValueFormatField);


  return (
    <>
      <div className="first-section-title">AMOUNT</div>
      <CustomTitleLabel title="Total value" />
      <div className="d-flex">
        <input
          type="text"
          className={`day-input-fields ${
            hasError(errors, totalValueField) ||
            hasError(errors, totalValueFormatField)
              ? 'error-field'
              : ''
          }`}
          defaultValue={totalValue}
          onInput={(e) => {
            const value = e.target.value;
            const data = value.replace(/[^0-9.]/g, '');
            setValue(totalValueField, data, { shouldDirty: true });
          }}
        />
        <label className="transaction-amount-total-value-label">hours</label>
      </div>
      <ReactHookFormErrorMessage errors={errors} id={totalValueField} />
      <ReactHookFormErrorMessage errors={errors} id={totalValueFormatField} />
    </>
  );
}

export default TransactionAmountSection;
